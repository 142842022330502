import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import Collapsible from 'react-collapsible';

import { useHookstate } from '@hookstate/core';
import { itemConfig } from '~/store/States/dataconfig';
// components
import Cross from '../../components/Cross/Cross';
import ProductMain from './ProductMain';
import ProductSpecs from './ProductSpecs.ui';
import ProductInfo from './ProductInfo.ui';

import SearchBox from '../../components/searchBox/SearchBox.ui';


const WrapMenuLi = ({ item, keys, toggleChildCategories, productListFunction, toggleShowMacro, showMacro, closeMenu }) => {
  console.debug('wrapMenuLi');
  return (
    <li><Link to={{ pathname: '/', state: { product: { isWrapMenuLi: true, keys, syncProductList: [item.external_referrer, item.locales[process.env.LOCALE].description, item.external_referer] } } }} onMouseEnter={() => toggleChildCategories(item.external_referrer, productListFunction, toggleShowMacro, showMacro)} onClick={() => closeMenu()} >{item.locales[process.env.LOCALE].name}<i className="fas fa-chevron-right" /></Link></li>
  );
};

const WrapMenu = ({ categories, closeMenu, parentCategories, toggleChildCategories, productListFunction, toggleShowMacro, showMacro, childCategories }) => {
  if (categories && parentCategories) {
    const parents = [];
    parentCategories.forEach((element) => {
      categories.data.filter(category => category.external_referrer === element).map(filteredName => (
        parents.push(filteredName)
      ));
    });
    const items = (parents || []).map((v, i) => {
      const keys = `category-box-${i}`;
      return <WrapMenuLi item={v} keys={keys} toggleChildCategories={toggleChildCategories} productListFunction={productListFunction} toggleShowMacro={toggleShowMacro} showMacro={showMacro} closeMenu={closeMenu} />;
    });
    return (
      <div className="wrapMenu" onMouseLeave={() => closeMenu()}>
        <ul>
          {items}
        </ul>
        <div className="wrapMenuPrincipal" id="wrapMenuPrincipal">
          {childCategories}
          <img src="/cdn/espots/media/banner-menu.png" alt="" />
        </div>
      </div>
    );
  }
  return false;
};
const PlanFeatures = ({ product }) => {
  console.log(product.data[0].attributes);
  const tituloLlamadas = _.filter(product.data[0].attributes, { identifier: 'attr-chars-titulo-llamadas' });
  const descLlamadas = _.filter(product.data[0].attributes, { identifier: 'attr-chars-desc-llamadas' });
  const tituloMensajes = _.filter(product.data[0].attributes, { identifier: 'attr-chars-titulo-mensajes' });
  const descMensajes = _.filter(product.data[0].attributes, { identifier: 'attr-chars-desc-mensajes' });
  const tituloInternet = _.filter(product.data[0].attributes, { identifier: 'attr-chars-titulo-internet' });
  const descInternet = _.filter(product.data[0].attributes, { identifier: 'attr-chars-desc-internet' });
  const descriptionInternet = descInternet[0] ? descInternet[0].value : 'Uso de bono aplica en CENAM';
  return (
    <section style={{ textAlign: 'center', paddingBottom: '2em' }} >
      {tituloLlamadas[0] && tituloLlamadas[0].value !== '' && descLlamadas[0] && descLlamadas[0].value !== '' ?
        <article className="product--features-box" >
          <img src={`${process.env.CDN_PATH}/espots/media/iPhone.png`} alt="phone" style={{ maxWidth: '70px', paddingLeft: '40%', paddingTop: '1em', paddingBottom: '0.5em' }} />
          <p className="block"><h3 style={{ color: '#0097a9' }}>{tituloLlamadas[0].value.toUpperCase()}</h3></p>
          <p className="block" style={{ color: 'grey', paddingTop: '0.5em' }}>{descLlamadas[0].value}</p>
        </article>
      : ''}
      {tituloMensajes[0] && tituloMensajes[0] !== '' && descMensajes[0] && descMensajes[0] !== '' ?
        <article className="product--features-box" >
          <img src={`${process.env.CDN_PATH}/espots/media/iMessage.png`} alt="message" style={{ maxWidth: '70px', paddingLeft: '40%', paddingTop: '1em', paddingBottom: '0.5em' }} />
          <p className="block"><h3 style={{ color: '#0097a9' }}>{tituloMensajes[0].value.toUpperCase()}</h3></p>
          <p className="block" style={{ color: 'grey', paddingTop: '0.5em' }}>{descMensajes[0].value}</p>
        </article>
     : ''}
      {tituloInternet[0] && tituloInternet[0] !== '' ?
        <article className="product--features-box" >
          <img src={`${process.env.CDN_PATH}/espots/media/iInternet.png`} alt="message" style={{ maxWidth: '70px', paddingLeft: '40%', paddingTop: '1em', paddingBottom: '0.5em' }} />
          <p className="block"><h3 style={{ color: '#0097a9' }}>{tituloInternet[0].value.toUpperCase()}</h3></p>
          <p className="block" style={{ color: 'grey', paddingTop: '0.5em' }}>{descriptionInternet}</p>
        </article> : ''}
    </section>
  );
};

const MobileMenu = ({ closeNav, parentCategories, thirdLevel, syncProductList }) => {
  console.log('');
  console.log(syncProductList);
  if (parentCategories) {
    const items = (parentCategories || []).map((v) => {
      const items2 = (thirdLevel || []).map((v2) => {
        console.log(v2);
        if (v2.locales[process.env.LOCALE].parent_code !== v) return false;
        return <Link className="mobileli" to={{ pathname: '/', state: { product: { syncProductList: [v2.external_referrer, v2.locales[process.env.LOCALE].description, v2.external_referer] } } }} >{v2.locales[process.env.LOCALE].name}</Link>;
      });
      console.log(items2);
      let title = '';
      if (v === 'postpago/planes') {
        title = 'PLANES POSTPAGO';
      } else if (v === 'prepago/celulares') {
        title = 'TELÉFONOS PREPAGOS';
      }
      return (
        <Collapsible trigger={title}>
          {items2}
        </Collapsible>
      );
    });
    console.log(items);
    return (
      <div id="mySidenav" className="sidenav">
        <a href="#" className="closebtn" onClick={closeNav}>&times;</a>
        <div className="content" />
        {items}
      </div>
    );
  }
  return false;
};

const UI = (props) => {
  const itemGlobalState = useHookstate(itemConfig);

  return (
    <main>
      <section className="product">
        <div className="fastlinks">
          <section>
            <span />
            {/* <p><span>Para visualizar todo con respecto a tus órdenes de tienda ingresa a</span> <Link to="/account">Mi cuenta</Link></p> */}
          </section>
        </div>
        <header className="catalog--header">
          {/* eslint-disable-line no-use-before-define */}
          <h1>{itemGlobalState.value && itemGlobalState.value.product_title_catalog}</h1>
        </header>
        <div className="catalog--module">
          {!isMobile ?
            <div className="nav--categories" onClick={() => props.openMenu()} onMouseEnter={() => props.openMenu()}>
              <span />
              <span>&#9776; Todas las categorías</span>
            </div> :
            <div className="menuContainer">
              <MobileMenu closeNav={props.closeNav} parentCategories={props.parentCategories} thirdLevel={props.thirdLevel} showMacro={props.showMacro} syncProductList={props.syncProductList} />
              <div className="nav--categories">
                <span style={{ cursor: 'pointer' }} onClick={props.openNav}>&#9776; Todas las categorias</span>
              </div>
            </div>
          }
          <div className="nav--search" onFocus={props.handleClicks}>
            <SearchBox />
          </div>
          <WrapMenu categories={props.categories} closeMenu={props.closeMenu} parentCategories={props.parentCategories} toggleChildCategories={props.toggleChildCategories} productListFunction={props.productListFunction} toggleShowMacro={props.toggleShowMacro} showMacro={props.showMacro} childCategories={props.childCategories} />
        </div>
        <ProductMain allProps={props} product={props.product} color={props.color} size={props.size} images={props.images} item={props.item} />
        {props.productType === 'PREPAGO' ? <ProductSpecs product={props.product.data} color={props.color} size={props.size} /> : ''}
        {props.productType === 'PREPAGO' ? <ProductInfo
          product={props.product.data}
          handleTabs={props.handleTabs}
          handleBlocks={props.handleBlocks}
          handleSwitch={props.handleSwitch}
          pathName={props.pathname}
          color={props.color}
          size={props.size}
          images={props.images}
          item={props.item}
        /> : ''}
        {props.productType === 'PLAN' ? <PlanFeatures product={props.product} /> : '' }
      </section>
      <Cross slug={props.slug} productType={props.productType} />
    </main>
  );
};

UI.propTypes = {
  product: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  color: PropTypes.string,
  size: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  handleTabs: PropTypes.func.isRequired,
  handleBlocks: PropTypes.func.isRequired,
  handleSwitch: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  slug: PropTypes.string,
  openMenu: PropTypes.shape({}).isRequired,
  closeMenu: PropTypes.shape({}).isRequired,
  categories: PropTypes.shape({}).isRequired,
  parentCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  productType: PropTypes.string,
  openNav: PropTypes.shape({}).isRequired,
  closeNav: PropTypes.shape({}).isRequired,
};

UI.defaultProps = {
  product: {
    data: {},
  },
  color: '',
  size: '',
  images: [],
  slug: '',
  productType: 'PREPAGO',
};

export default UI;
