import { currencyFormatGTM } from './formatter';
import TagManager from 'react-gtm-module';
import jsSHA from 'jssha';
import ReactGA from 'react-ga';
import productHelpers from './product';
import ReactPixel from 'react-facebook-pixel';


let sendDataLayer = true;
let dataLog = true;

if (process.env.PROD) { dataLog = false }

const userId = () => {
  const id = sessionStorage.getItem('userId');
  if (id) {
    ReactGA.set({ userId: id });
  }
  return id;
};

const analytics = {

  // Product Impressions
  productImpressions: (data) => {
    if (sendDataLayer) {
      const products = data.productList;
      const impressions = [];
      const items = [];
      if (products && products.length) {
        products.forEach(prods => {
          const productArray = Array.isArray(prods) ? prods : [prods];
          productArray.forEach((product, idx) => {
            if (product.locales) {
              const name = product.locales[process.env.LOCALE].name;
              const id = product.part_number;
              const price = currencyFormatGTM(product.items[0].pricing[process.env.CURRENCY].offer_price);
              const getBrand = product.attributes.filter(attr => attr.identifier.match(/(filt-marca)/));
              const brand = getBrand[0] ? getBrand[0].value : '';
              const category = product.locales[process.env.LOCALE].categories[0].slug;
              const attrMap = productHelpers.convertAttributes(product.attributes);
              const tipo = attrMap['attr-conf-tipoproducto'] || '';



              impressions.push({
                'id': id,
                'name': name,
                'price': price,
                'brand': brand,
                'category': category,
                'variant': ''
              });

              items.push({
                'item_id': id,
                'item_name': name,
                'price': price,
                'item_brand': brand,
                'item_category': category,
                'item_variant': '',
                'item_list_id': tipo,
                'index': idx,
                'quantity': '1'
              });
            }
          });
        })
      }
      const dataLayerInfo = {
        'event': 'productImpressions',
        'ecommerce': {
          'currencyCode': `${process.env.CURRENCY.toUpperCase()}`,
          'impressions': impressions,
        }
      }
      const dataLayerInfoGA4 = {
        'event': 'view_item_list',
        'ecommerce': {
          'items': items,
        }
      }
      
      dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfo);
      dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfoGA4);
      dataLog ? console.log("GTM productImpressions: ", dataLayerInfo) : null;
    }
  },

  // Product Click
  productClick: (product) => {
    if (sendDataLayer) {
      let clicks = [];
      const items = [];
      if (product) {
        const attrMap = productHelpers.convertAttributes(product.items[0].attributes);
        const id = product.part_number;
        const brand = attrMap['filt-marca'] || '';
        const variant = `${attrMap['def-color'] || ''} ${attrMap['def-capacidad'] || ''} ${attrMap['def-pantalla'] || ''}`.trim();
        const name = product.items[0].locales ? product.items[0].locales[process.env.LOCALE].name : null;
        const price = product.items[0] ? currencyFormatGTM(product.items[0].pricing[process.env.CURRENCY].offer_price) : null;
        const category = product.items[0].locales[process.env.LOCALE].categories[0].slug;
        const tipo = attrMap['attr-conf-tipoproducto'] || '';



        clicks.push({
          'id': id,
          'name': name,
          'price': price,
          'brand': brand,
          'category': category,
          'variant': variant,
        });

        items.push({
          'item_id': id,
          'item_name': name,
          'price': price,
          'item_brand': brand,
          'item_category': category,
          'item_variant': variant,
          'item_list_id': tipo,
          'index': 1,
          'quantity': '1'


        });



      }
      const dataLayerInfo = {
        'event': 'productClick',
        'ecommerce': {
          'actionField': { 'list': 'Catalog' },
          'click': {
            'products': clicks
          }
        },
      }

      const dataLayerInfoGA4 = {
        'event': 'select_item',
        'ecommerce': {
          'items': items,
        }
      }
         dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfo);
         dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfoGA4);
      dataLog ? console.log("GTM productClick: ", dataLayerInfo) : null;
      dataLog ? console.log("GTM select_item: ", dataLayerInfoGA4) : null;
    }
  },

  // Product Detail
  productDetail: (product) => {
    if (sendDataLayer) {
      let clicks = [];
      const items = [];
      const productInfo = product.productInfo.data && product.productInfo.data[0] ? product.productInfo.data[0] : null;
      const productColor = product.initColor;
      const productSize = product.initSize;
      const productPricing = product.pricing ? product.pricing[process.env.CURRENCY] : null;
      if (product) {
        const id = productInfo ? productInfo.part_number : null;
        const name = product.name;
        const color = productColor ? productColor : null;
        const size = productSize ? productSize : null;
        const variant = `${color} ${size}`;
        const price = productPricing ? currencyFormatGTM(productPricing.offer_price) : null;
        const attrMap = productHelpers.convertAttributes(product.attributes);
        const tipo = attrMap['attr-conf-tipoproducto'] || '';
        const category = productInfo ? productInfo.locales[process.env.LOCALE].categories[0].slug : null;
        const getBrand = productInfo ? productInfo.attributes.filter(attr => attr.identifier.match(/(filt-marca)/)) : null;
        const brand = getBrand && getBrand[0] ? getBrand[0].value : '';
        clicks.push({
          'id': id,
          'name': name,
          'price': price,
          'brand': brand,
          'category': category,
          'variant': variant,
        });

        items.push({
          'item_id': id,
          'item_name': name,
          'price': price,
          'item_brand': brand,
          'item_category': category,
          'item_variant': variant,
          'index': 1,
          'quantity': '1'


        });


        const viewcontentfb = {
          content_ids: id,
          content_type: tipo,
          value: price,
          currency: `${process.env.CURRENCY.toUpperCase()}`,
          product_catalog_id: tipo,
          product_group: tipo
        };

        ReactPixel.track('ViewContent', viewcontentfb);
      }
      const dataLayerInfo = {
        'event': 'productDetail',
        'ecommerce': {
          'actionField': { 'list': 'Product Detail' },
          'detail': {
            'products': clicks
          }
        }
      }
      const dataLayerInfoGA4 = {
        'event': 'view_item',
        'ecommerce': {
          'items': items,
        }
      }
      TagManager.dataLayer({
        dataLayer: dataLayerInfo
      });
      TagManager.dataLayer({
        dataLayer: dataLayerInfoGA4
      });
      dataLog ? console.log("GTM productDetail: ", dataLayerInfo) : null;
      dataLog ? console.log("GTM view_item: ", dataLayerInfoGA4) : null;
    }
  },

  // Add to Cart
  addToCart: (data, partNumber, qty = 1) => {
    if (sendDataLayer) {
      let toCart = [];
      const items = [];
      const item = data.allProps;
      const product = item.product.data[0] ? item.product.data[0] : null;
      if (product.locales !== undefined) {
        console.log('entro al if');
        if (data) {
          const id = partNumber;
          const name = product.locales[process.env.LOCALE].name;
          const variant = `${item.color} ${item.size}`;
          const getBrand = product.attributes.filter(attr => attr.identifier.match(/(filt-marca)/));
          const brand = getBrand[0] && getBrand[0].value;
          const price = currencyFormatGTM(item.pricing[process.env.CURRENCY].offer_price);
          const category = product ? product.locales[process.env.LOCALE].categories[0].slug : null;
          const attrMap = productHelpers.convertAttributes(product.attributes);
          const tipo = attrMap['attr-conf-tipoproducto'] || '';

          const AddToCartfb = {
            content_ids: id,
            content_type: tipo,
            value: price,
            currency: `${process.env.CURRENCY.toUpperCase()}`,
            product_catalog_id: tipo,
            product_group: tipo
          };


          ReactPixel.track('AddToCart', AddToCartfb);

          toCart.push({
            'id': id,
            'name': name,
            'price': price,
            'brand': brand,
            'category': category,
            'variant': variant,
            'quantity': qty
          });

          items.push({
            'item_id': id,
            'item_name': name,
            'price': price,
            'item_brand': brand,
            'item_category': category,
            'item_variant': variant,
            'item_list_id': tipo,
            'index': 1,
            'quantity': qty


          });
        }
      } else {
        console.log('entro al else');
        if (data) {
          const id = partNumber;
          const name = product.data[0].locales[process.env.LOCALE].name;
          const variant = `${item.color} ${item.size}`;
          const getBrand = product.data[0].attributes.filter(attr => attr.identifier.match(/(filt-marca)/));
          const brand = getBrand[0] && getBrand[0].value;
          const price = currencyFormatGTM(item.pricing[process.env.CURRENCY].offer_price);
          const category = product.data[0] ? product.data[0].locales[process.env.LOCALE].categories[0].slug : null;
          const attrMap = productHelpers.convertAttributes(product.data[0].attributes);
          const tipo = attrMap['attr-conf-tipoproducto'] || '';

          const AddToCartfb = {
            content_ids: id,
            content_type: tipo,
            value: price,
            currency: `${process.env.CURRENCY.toUpperCase()}`,
            product_catalog_id: tipo,
            product_group: tipo
          };


          ReactPixel.track('AddToCart', AddToCartfb);

          toCart.push({
            'id': id,
            'name': name,
            'price': price,
            'brand': brand,
            'category': category,
            'variant': variant,
            'quantity': qty
          });

          items.push({
            'item_id': id,
            'item_name': name,
            'price': price,
            'item_brand': brand,
            'item_category': category,
            'item_variant': variant,
            'item_list_id': tipo,
            'index': 1,
            'quantity': qty


          });
        }
      }
      const dataLayerInfo = {
        'event': 'addToCart',
        'ecommerce': {
          'add': {
            'products': toCart
          }
        },
      }

      const dataLayerInfoGA4 = {
        'event': 'add_to_cart',
        'ecommerce': {
          'items': items,
        }
      }
      dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfo);
      dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfoGA4);
      dataLog ? console.log("GTM addToCart: ", dataLayerInfo) : null;
      dataLog ? console.log("GTM add_to_cart: ", dataLayerInfoGA4) : null;
    }
  },

  // Remove from Cart
  removeFromCart: (data, partNumber) => {
    if (sendDataLayer) {
      let removed = [];
      const items = [];

      if (data) {
        const id = partNumber;
        const name = data.locales[process.env.LOCALE].name;
        const category = data.locales[process.env.LOCALE].categories[0].slug;
        const attrs = data.attributes;
        const attrMap = productHelpers.convertAttributes(attrs);
        const getBrand = attrs.filter(att => att.identifier.match(/(filt-marca)/));
        const getColor = attrs.filter(att => att.identifier.match(/(filt-color|def-color)/));
        const getSize = attrs.filter(att => att.identifier.match(/(filt-capacidad|def-capacidad)/));
        const getPrice = data.pricing ? data.pricing[process.env.CURRENCY].offer_price : data.items[0].pricing[process.env.CURRENCY].offer_price;
        const brand = getBrand[0] && getBrand[0].value ? getBrand[0].value : data.locales[process.env.LOCALE].name;
        const color = getColor[0] && getColor[0].value || '';
        const size = getSize[0] && getSize[0].value ? getSize[0].value : '';
        const variant = `${color} ${size}`;
        const tipo = attrMap['attr-conf-tipoproducto'] || '';

        const price = currencyFormatGTM(getPrice);
        removed.push({
          'id': id,
          'name': name,
          'price': price,
          'brand': brand,
          'variant': variant,
          'category': category,
          'quantity': 1
        });

        items.push({
          'item_id': id,
          'item_name': name,
          'price': price,
          'item_brand': brand,
          'item_category': category,
          'item_variant': variant,
          'item_list_id': tipo,
          'index': 1,
          'quantity': 1


        });

      }
      const dataLayerInfo = {
        'event': 'removeFromCart',
        'ecommerce': {
          'remove': {
            'products': removed
          }
        },
      }

      const dataLayerInfoGA4 = {
        'event': 'add_to_cart',
        'ecommerce': {
          'items': items,
        }
      }
      dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfo);
      dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfoGA4);
      dataLog ? console.log("GTM removeFromCart: ", dataLayerInfo) : null;
      dataLog ? console.log("GTM removeFromCart: ", dataLayerInfoGA4) : null;


    }
  },

  // Promotion Impression
  promotionImpression: () => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'promotionImpression',
        'ecommerce': {
          'promoView': {
            'promotions': [{
              'id': 'id',
              'name': 'name',
              'creative': 'creative',
              'position': 'position',
            }]
          }
        },
      }
      dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfo);
      dataLog ? console.log("GTM promotionImpression: ", dataLayerInfo) : null;
    }
  },

  // Promotion Clicks
  promotionClick: () => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'promotionClick',
        'ecommerce': {
          'promoClick': {
            'promotions': [{
              'id': 'id',
              'name': 'name',
              'creative': 'creative',
              'position': 'position',
            }]
          }
        },
      }
      dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfo);
      dataLog ? console.log("GTM promotionClick: ", dataLayerInfo) : null;
    }
  },

  // Cart
  cart: (step, cart) => {
    if (sendDataLayer) {
      let items = [];
      let itemsG4A = [];
      if (cart || cart !== undefined) {
        // console.log('analytics cart: ', cart);
        const id = cart.part_number;
        const name = cart.product.locales[process.env.LOCALE].name;
        const price = currencyFormatGTM(cart.amount[process.env.CURRENCY].unit_price);
        const getItems = cart.product && cart.product.items ? cart.product.items.filter(item => item.part_number === id) : [];
        const getBrand = getItems[0].attributes.filter(attr => attr.identifier.match(/(filt-marca)/));
        const getColor = getItems[0].attributes.filter(attr => attr.identifier.match(/(def-color)/));
        const getSize = getItems[0].attributes.filter(attr => attr.identifier.match(/(def-capacidad)/));
        const brand = getBrand[0] ? getBrand[0].value : '';
        const color = getColor[0] ? getColor[0].value : '';
        const size = getSize[0] ? getSize[0].value : '';
        const variant = `${color} ${size}`;
        const category = cart.product.locales[process.env.LOCALE].categories[0].slug;
        const quantity = 1;
        items.push({
          'name': name,
          'id': id,
          'price': price,
          'brand': brand,
          'category': category,
          'variant': variant,
          'quantity': quantity
        });
        itemsG4A.push({
          'item_name': name,
          'item_id': id,
          'price': price,
          'item_brand': brand,
          'item_category': category,
          'item_variant': variant,
          'quantity': quantity
        });
      }
      const dataLayerInfo = {
        'event': 'checkout',
        'ecommerce': {
          'checkout': {
            'actionField': {
              'step': step,
              'option': ''
            },
            products: items,
          }
        },
      }

      let nameEvent;

      switch (step) {
        case 1:
          nameEvent = 'begin_checkout';
          break;
        case 2:
          nameEvent = 'add_payment_info';
          break;
        case 3:
          nameEvent = 'add_shipping_info';
          break;

        // code block
      }

      const dataLayerInfoGA4 = {
        'event': nameEvent,
        'ecommerce': {
          'items': itemsG4A,
        }
      }
      dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfo);
      dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfoGA4);
      dataLog ? console.log("GTM cart: ", dataLayerInfo) : null;
      dataLog ? console.log("GTM cart: ", dataLayerInfoGA4) : null;
    }
  },

  // Checkout
  checkout: (step, option, data) => {
    if (sendDataLayer) {
      let itemsG4A = [];
      const products = (data || []).map(parent => {
        const id = parent.part_number;
        const product = parent && parent.product || {};
        const name = product.locales && product.locales[process.env.LOCALE] && product.locales[process.env.LOCALE].name || '';
        const items = product.items || [];
        const getItem = items.find(i => i.part_number === id);
        const price = getItem && getItem.pricing && getItem.pricing[process.env.CURRENCY] ? currencyFormatGTM(getItem.pricing[process.env.CURRENCY].offer_price) : '';
        const attrs = getItem && getItem.attributes || [];
        const attrMap = productHelpers.convertAttributes(attrs);
        const brand = attrMap['filt-marca'] || '';
        const color = attrMap['def-color'] || '';
        const size = attrMap['def-capacidad'] || '';
        const variant = `${color} ${size}`
        const category = product.locales && product.locales[process.env.LOCALE] && product.locales[process.env.LOCALE].categories ? product.locales[process.env.LOCALE].categories[0].slug : '';

        itemsG4A.push({
          'item_name': name,
          'item_id': id,
          'price': price,
          'item_brand': brand,
          'item_category': category,
          'item_variant': variant,
          'quantity': parent.quantity
        });

        return ({
          'name': name,
          'id': id,
          'price': price,
          'brand': brand,
          'category': category,
          'variant': variant,
          'quantity': parent.quantity,
        });
      });
      const dataLayerInfo = {
        'event': 'checkout',
        'ecommerce': {
          'checkout': {
            'actionField': {
              'step': step,
              'option': option
            },
            'products': products
          }
        },
      }

      let nameEvent;

      switch (step) {
        case 1:
          nameEvent = 'begin_checkout';
          break;
        case 2:
          nameEvent = 'add_shipping_info';
          break;
        case 3:
          nameEvent = 'add_payment_info';
          break;
      }


      const dataLayerInfoGA4 = {
        'event': nameEvent,
        'ecommerce': {
          'items': itemsG4A,
        }
      }
      dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfo);
      dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfoGA4);
      dataLog ? console.log("GTM checkout: ", dataLayerInfo) : null;
      dataLog ? console.log("GTM checkout: ", dataLayerInfoGA4) : null;

    }
  },

  // Purchase
  purchase: (orderInfo, orderItems) => {
    if (sendDataLayer) {
      const transactionID = orderInfo.external_referer;
      const revenue = currencyFormatGTM(orderInfo.amount[process.env.CURRENCY].grand_total);
      const tax = orderInfo.amount[process.env.CURRENCY].total_sales_tax;
      const shipping = orderInfo.amount[process.env.CURRENCY].total_shipping_charge;
      const coupon = orderInfo.promo_codes && orderInfo.promo_codes.length > 0 ? orderInfo.promo_codes : '';
      let summary = orderItems.map(item => {
        const orderItem = item.product.items.find(it => it.part_number === item.part_number);
        const attrMap = productHelpers.convertAttributes(orderItem.attributes);
        const name = orderItem.locales[process.env.LOCALE].name;
        const id = orderItem.part_number;
        const price = currencyFormatGTM(orderItem.pricing[process.env.CURRENCY].offer_price);
        const category = orderItem.locales[process.env.LOCALE].categories[0].slug;
        let color = attrMap['def-color'] || '';
        let size = attrMap['def-capacidad'] || '';
        const variant = `${color} ${size}`;
        let brand = attrMap['filt-marca'] || '';
        const quantity = item.quantity;
        return ({
          'name': name,
          'id': id,
          'price': price.replace(',', ''),
          'brand': brand,
          'category': category,
          'variant': variant,
          'quantity': quantity,
        });
      });


      let summaryG4A = orderItems.map(item => {
        const orderItem = item.product.items.find(it => it.part_number === item.part_number);
        const attrMap = productHelpers.convertAttributes(orderItem.attributes);
        const name = orderItem.locales[process.env.LOCALE].name;
        const id = orderItem.part_number;
        const price = currencyFormatGTM(orderItem.pricing[process.env.CURRENCY].offer_price);
        const category = orderItem.locales[process.env.LOCALE].categories[0].slug;
        let color = attrMap['def-color'] || '';
        let size = attrMap['def-capacidad'] || '';
        const variant = `${color} ${size}`;
        let brand = attrMap['filt-marca'] || '';
        const quantity = item.quantity;

        const tipo = attrMap['attr-conf-tipoproducto'] || '';


        return ({
          'item_name': name,
          'item_id': id,
          'price': price.replace(',', ''),
          'item_brand': brand,
          'item_category': category,
          'item_variant': variant,
          'quantity': quantity,
        });
      });

      
      let purchasefbIte = orderItems.map(item => {
        const orderItem = item.product.items.find(it => it.part_number === item.part_number);
        const id = orderItem.part_number;
        const quantity = item.quantity;



        return ({
          'id': id,
          'quantity': quantity,
        });
      });



      const purchasefb = {
        value: revenue,
        currency: `${process.env.CURRENCY.toUpperCase()}`,
        contents: purchasefbIte,
        content_type: 'product'
      };

      ReactPixel.track('Purchase', purchasefb);




      const dataLayerInfo = {
        'event': 'purchase',
        'ecommerce': {
          'purchase': {
            'actionField': {
              'id': transactionID, // Transaction ID. Required for purchases and refunds.
              'affiliation': 'User',
              'revenue': revenue.replace(',', ''), // Total transaction value (incl. tax and shipping)
              'tax': tax,
              'shipping': shipping,
              'coupon': coupon
            },
            'products': summary
          }
        }
      }


      const dataLayerInfoGA4 = {
        'event': 'purchase',
        'ecommerce': {

          'transaction_id': transactionID, // Transaction ID. Required for purchases and refunds.
          'affiliation': 'User',
          'value': revenue.replace(',', ''), // Total transaction value (incl. tax and shipping)
          'tax': tax,
          'shipping': shipping,
          'currency': process.env.CURRENCY,
          'coupon': coupon,
          'items': summaryG4A

        },


      }




      dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfo);
      dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfoGA4);
      dataLog ? console.log('GTM purchase: ', dataLayerInfo) : null;
      dataLog ? console.log('GTM purchase: ', dataLayerInfoGA4) : null;
    }
  },

  // Refund
  refund: () => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'ecommerce': {
          'refund': {
            'actionField': { 'id': 'T12345' } // Transaction ID. Required for purchases and refunds.
          }
        }
      }
      dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfo);
      dataLog ? console.log('GTM refund: ', dataLayerInfo) : null;
    }
  },

  // Partial Refund
  refundPartial: () => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'ecommerce': {
          'refund': {
            'actionField': { 'id': 'T12345' }, // Transaction ID. Required for purchases and refunds.
            'products': [
              { 'id': 'P4567', 'quantity': 1 }, // Product ID and quantity. Required for partial refunds.
              { 'id': 'P8901', 'quantity': 2 }
            ]
          }
        }
      }
      dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfo);
      dataLog ? console.log('GTM refundPartial: ', dataLayerInfo) : null;
    }
  },

  errorPago: (paymentResponse) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'error_pago',
        'resultCode': paymentResponse.resultCode,
        'bankResponseCode': paymentResponse.bankResponseCode,
        'bankResponseText': paymentResponse.bankResponseText,
      }
      dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfo);
      dataLog ? console.log('GTM errorPago: ', dataLayerInfo) : null;
    }
  },

  usuarioCreado: (firstName, lastName) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'usuario_creado',
        'firstName': firstName,
        'lastName': lastName,
      }
      dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfo);
      dataLog ? console.log('GTM usuarioCreado: ', dataLayerInfo) : null;
    }
  },

  hashUserId: (email) => {
    email = email.toLowerCase();
    const sha256 = new jsSHA('SHA-256', 'TEXT');
    sha256.update(email);
    const hash = sha256.getHash("HEX");
    sessionStorage.setItem('userId', hash);
  },

  userId: userId,

  login: () => {
    const id = userId();
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'loginSuccess',
        'user_id': id,
      }
      dataLayer.push({ ecommerce: null });
      dataLayer.push(dataLayerInfo);
      dataLog ? console.log('GTM login: ', dataLayerInfo) : null;
    }
  },

  logout: () => {
    ReactGA.set({ userId: null });
    const id = sessionStorage.getItem('userId');
    if (id) {
      sessionStorage.removeItem('userId');
    }
  },
};

export default analytics;
