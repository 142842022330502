import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import $ from 'jquery';

// Helpers
import validate from '../../../helpers/validate_form';

// UI
import UI from './Number.ui';

class Number extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(...props) {
    super(...props);

    this.state = {
      error: '',
    };

    this.internalData = {
      s_phone_1: '',
    };
    // Binds
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.switchModal = this.switchModal.bind(this);
  }

  componentDidMount() {
    validate();
    this.clearError();
  }

  clearError() {
    this.setState({ error: '' });
  }

  clearPhone() {
    this.setState({ s_phone_1: '' });
  }

  handleChange(e, data) {
    const keys = data.split('.');
    const numberForm = this;
    numberForm[keys[0]][keys[1]] = e.target.value;
    this.setState(numberForm);
    if (!this.state.error || this.state.error !== '') {
      this.clearError();
    }
  }
  handleData() {
    this.validateNumber();
  }

  switchModal() {
    this.clearPhone();
    this.clearError();
    $('.modal--validate-number').removeClass('active');
    $('.modal--tipo-compra').addClass('active');
  }

  validateNumber() {
    this.clearError();
    const queryValidateNumber = {
      validateNumber: {
        claroNumber: this.internalData.s_phone_1,
        Type: 'N',
      },
    };

    this.props.orderStore.validateNumber(queryValidateNumber).then((resultVN) => {
      if (resultVN.success) {
        const responseVN = resultVN.data.data.validateNumberResponse;
        if (responseVN && responseVN.AcknowledgementIndicator === 'SUCCESS'
          && responseVN.IsValidFlag && !responseVN.hasProblem) {
          if (responseVN.LineOfBusiness === '4') {
            const queryGenerarToken = {
              generarToken: {
                UserProfileID: '',
                MobileNumber: this.internalData.s_phone_1,
                LineOfBusiness: responseVN.LineOfBusiness,
              },
            };
            this.props.orderStore.generarToken(queryGenerarToken).then((resultGT) => {
              const responseGT = resultGT.data.data.generarTokenResponse;
              if (responseGT && responseGT.AcknowledgementIndicator === 'SUCCESS') {
                $('.modal--validate-number').removeClass('active');
                $('.modal--validate-token').addClass('active');
              } else {
                this.setState({ error: responseVN.AcknowledgementDescription });
              }
            });
          } else {
            this.setState({ error: 'El número ingresado debe ser un Claro Prepago' });
          }
        } else {
          this.setState({ error: responseVN.AcknowledgementDescription });
        }
      } else {
        this.setState({ error: 'Ha ocurrido un error, intente más tarde.' });
      }
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const isIE =
      navigator.userAgent.indexOf('MSIE') !== -1 ||
      navigator.appVersion.indexOf('Trident/') > -1;
    if (isIE) this.handleData();
    else if (!isIE && $("form[name='validateNumber']").valid()) this.handleData();
  }

  render() {
    return (
      <UI
        numberForm={this.internalData}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        error={this.state.error}
        switchModal={this.switchModal}
      />
    );
  }
}

Number.propTypes = {
  orderStore: PropTypes.shape({
    validateNumber: PropTypes.func,
  }).isRequired,
};

export default inject('orderStore')(observer(Number));
