import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import _ from 'lodash';
import { Helmet } from 'react-helmet';
import he from 'he';

// components
import Loading from '../ui/Loading';
import LogoutBtn from '../../containers/Account/Login/LogoutBtn';
import LoginBtn from '../../containers/Account/Login/LoginBtn';
import IEnotification from '../ui/IENotification';

// utilities
// iconPlus, iconMinus
import { logo, iconMusica, iconVideo, panama, iconMovil, iconHogar, iconEntretenimiento, iconPromociones, iconSitios } from '../../helpers/svg';
import { isDesktop } from '../../helpers/ui';

const isIE = (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1);

const CartQuantity = ({ cartInfo/* , addOnsList */ }) => {
  // console.log(addOnsList);
  let total = 0;
  if (cartInfo && cartInfo.data && cartInfo.data.items && cartInfo.data.items.length >= 1) {
    total = 1;
    // const cartQty = _.map((cartInfo.data.items).slice(), qty => qty.quantity);
    // for (let i = 0, len = cartQty.length; i < len; i += 1) {
    // if (!addOnsList.some(x => x.item_part_number === cartInfo.data.items[i].part_number)) total += window.parseInt(cartQty[i]);
    // }
  }

  return (
    <span className="nav--qty">{total}</span>
  );
};

const NavTopMobile = (props) => {
  const getData = props && props.layoutData && props.layoutData.Secciones;
  const mobileTopSites = [];
  if (getData) {
    const data = props.layoutData.Secciones;
    for (let i = 0; i < data.length; i += 1) {
      const item = data[i];
      const element = (<option value={he.decode(item.URL)} key={`mobile-${item.Unico}`}>{he.decode(item.Descripcion)}</option>);
      mobileTopSites.push(element);
    }
  }
  return (
    <div className="nav--top-mobile">
      <label>
        <select id="nav-top-mobile-select" onChange={props.handleChange}>
          {mobileTopSites}
        </select>
      </label>
    </div>
  );
};

const NavTop = (props) => {
  const getTopSites = props && props.layoutData && props.layoutData.Secciones;
  const topSites = [];
  if (getTopSites) {
    const data = props.layoutData.Secciones;
    for (let i = 0; i < data.length; i += 1) {
      const item = data[i];
      const element = (<a href={he.decode(item.URL)} key={item.Unico}>{he.decode(item.Descripcion)}</a>);
      topSites.push(element);
    }
  }
  const navTopSites = (
    <nav className="nav--top-first">
      {topSites}
    </nav>
  );
  const getTopMenu = props && props.layoutData && props.layoutData.Menus && props.layoutData.Menus[0] && props.layoutData.Menus[0].Hijos;
  let navTopMenu = '';
  if (getTopMenu) {
    const data = props.layoutData.Menus[0].Hijos;
    const dataLength = data.length;
    const getDropdown = data && dataLength && data[dataLength - 1].Hijos;
    const dropdown = [];
    if (getDropdown) {
      const list = data[dataLength - 1].Hijos;
      for (let i = 0; i < list.length; i += 1) {
        const item = list[i];
        const element = <li key={item.Unico}><a href={he.decode(item.URL)}>{he.decode(item.Descripcion)}</a></li>;
        dropdown.push(element);
      }
    }
    if (dataLength > 1) {
      const menus = [];
      for (let i = 2; i < dataLength - 1; i += 1) {
        menus.push(<a href={he.decode(data[i].URL)} onMouseOver={props.handleLeave} onFocus={() => {}}><span>{he.decode(data[i].Descripcion)}</span></a>);
      }
      navTopMenu = (
        <nav className="nav--top-second">
          <a href={he.decode(data[0].URL)}><span>{iconMusica}</span><span>{he.decode(data[0].Descripcion)}</span></a>
          <a href={he.decode(data[1].URL)}><span>{iconVideo}</span><span>{he.decode(data[1].Descripcion)}</span></a>
          {menus}
          <a onMouseOver={props.handleHover} onFocus={() => {}}>{he.decode(data[dataLength - 1].Descripcion)}</a>
          <ul className="nav--top-second-dropdown" onMouseLeave={props.handleLeave}>
            {dropdown}
          </ul>
        </nav>
      );
    }
  }
  const navTopCountry = (
    <nav className="nav--top-third" onMouseOver={props.handleLeave} onFocus={() => { }}>
      <a className="nav--top-third-trigger" onClick={props.handleCountry}>{panama}</a>
      <ul className="nav--top-third-dropdown" onMouseLeave={isDesktop ? props.handleCountry : null}>
        <li><a href="/">Panamá</a></li>
        <li><a href="https://www.claro.com.ar/personas/">Argentina</a></li>
        <li><a href="https://www.claro.com.br/">Brasil</a></li>
        <li><a href="https://www.clarochile.cl/personas/">Chile</a></li>
        <li><a href="http://www.claro.com.co/personas/">Colombia</a></li>
        <li><a href="https://www.claro.cr/personas/">Costa Rica</a></li>
        <li><a href="https://www.claro.com.ec/personas/">Ecuador</a></li>
        <li><a href="http://www.claro.com.gt/personas/">Guatemala</a></li>
        <li><a href="https://www.claro.com.hn/personas/">Honduras</a></li>
        <li><a href="http://www.claro.com.ni/personas/">Nicaragua</a></li>
        <li><a href="https://www.claro.com.sv/personas/">El Salvador</a></li>
        <li><a href="http://www.claro.com.py/personas/">Paraguay</a></li>
        <li><a href="http://www.claro.com.pe/personas/">Perú</a></li>
        <li><a href="https://www.claropr.com/personas/">Puerto Rico</a></li>
        <li><a href="https://www.claro.com.do/personas/">República Dominicana</a></li>
        <li><a href="https://www.claro.com.uy/personas/">Uruguay</a></li>
      </ul>
    </nav>
  );
  return (
    <div className="nav--top" onMouseLeave={props.handleLeave} onFocus={() => { }}>
      <NavTopMobile handleChange={props.handleChange} layoutData={props.layoutData} />
      <div className="nav--top-desk">
        <section>
          {navTopSites}
          {navTopMenu}
        </section>
        {navTopCountry}
      </div>
    </div>
  );
};

const NavMain = (props) => {
  const username = props.userInfo.data && props.userInfo.data.data ? props.userInfo.data.data.first_name : null;
  const isUser = !props.validateUser || (Object.keys(props.userInfo).length && !props.userInfo.userGuest);
  const doLogin = (<LoginBtn login={props.showLoginModal} register={props.showRegisterModal} />);
  const doLogout = (<LogoutBtn username={username} logout={props.logout} />);
  // eslint-disable-next-line no-unused-vars
  const sessionBtn = isUser ? doLogout : doLogin;
  // console.log(sessionBtn);

  const getMiClaro = props && props.layoutData && props.layoutData.MiClaro;
  let miClaro = '';
  if (getMiClaro) { miClaro = he.decode(props.layoutData.MiClaro); }

  const getSeccionesHeader = props && props.layoutData && props.layoutData.SeccionesHeader && props.layoutData.SeccionesHeader[0] && props.layoutData.SeccionesHeader[0].Hijos ? props.layoutData.SeccionesHeader[0].Hijos : [];
  const seccionesHeader = getSeccionesHeader.map((i) => {
    let output;
    if (i.URL === 'https://www.claro.com.pa/personas/servicios' || i.Descripcion.toLowerCase().trim() === 'servicios') {
      output = '';
    } else if (i.Descripcion.trim() === 'Tienda' || i.URL === 'https://tiendaenlinea.claro.com.pa' || i.URL === 'https://tiendaenlinea.claro.com.pa/') {
      output = '';
    } else {
      output = (<a key={i.Descripcion} href={he.decode(i.URL)}>{he.decode(i.Descripcion)}</a>);
    }
    return output;
  });

  return (
    <div className="nav--main">
      <div className="nav--main-content">
        <nav className="nav--links">
          <a href="https://www.claro.com.pa/personas/" className="nav--logo">{logo}</a>
          <a className="nav--links-trigger" onClick={props.handleMenuLinks}>Servicios</a>
          <TiendaLink getSeccionesHeader={getSeccionesHeader} handleClicks={props.handleClicks} handleMenuLinksTienda={props.handleMenuLinksTienda} />
          {seccionesHeader}
        </nav>
        <section className="nav--actions">
          {/* <Link to="/cart" className="nav--cart" onClick={props.handleClicks}>
            {iconCart}
            <CartQuantity cartInfo={props.cartInfo}/>
          </Link> */}
          <a className="nav--miclaro" href={miClaro}>
            <span>Mi Claro</span>
          </a>
          {/* {sessionBtn} */}
        </section>
        <div className="nav--trigger">
          <a onClick={props.handleMenuToggle}><span className="nav--trigger-menu fa-bars" /></a>
        </div>
      </div>
    </div>
  );
};

const TiendaLink = (props) => {
  let index = -1;
  const seccionesHeader = props.getSeccionesHeader;
  for (let i = 0; i < seccionesHeader.length; i += 1) {
    if (seccionesHeader[i].Descripcion.toLowerCase().trim() === 'tienda' && seccionesHeader[i].Hijos.length > 0) {
      index = i;
      break;
    }
  }
  if (index > -1) {
    return (<a className="nav--links-trigger" onClick={props.handleMenuLinksTienda}>Catálogo</a>);
  }
  return (<Link to="/" className="nav--links-trigger active" onClick={props.handleClicks}>Catálogo</Link>);
};

const NavMenuLinks = (props) => {
  const headers = props.layoutData && props.layoutData.SeccionesHeader && props.layoutData.SeccionesHeader[0] ? props.layoutData.SeccionesHeader[0].Hijos : [];
  const getData = props.layoutData && props.layoutData.SeccionesHeader && props.layoutData.SeccionesHeader[0] && props.layoutData.SeccionesHeader[0].Hijos && props.layoutData.SeccionesHeader[0].Hijos[0];
  const mobileMiClaro = props.layoutData && props.layoutData.MiClaro ? <a href={he.decode(props.layoutData.MiClaro)} className="nav--menu-title wine miclaro"><span>Mi Claro</span></a> : null;

  const slogan = props.layoutData && props.layoutData.Eslogan ? <p>{he.decode(he.decode(props.layoutData.Eslogan))}</p> : null;

  const addChildren = (parent) => {
    const children = [];
    const getChildren = parent && parent.Hijos && parent.Hijos.length;
    const theChildren = parent.Hijos;
    if (getChildren) {
      for (let c = 0; c < theChildren.length; c += 1) {
        const child = theChildren[c];
        const link = he.decode(child.URL);
        const label = he.decode(child.Descripcion);
        const key = child.Unico;
        const subchildren = addChildren(child);
        if (subchildren.length) {
          children.push(<li key={`menu-${key}`}><a href={link}>{label}</a><ul>{subchildren}</ul></li>);
        } else {
          children.push(<li key={`menu-${key}`}><a href={link}>{label}</a></li>);
        }
      }
    }
    return children;
  };

  let servicesNav;
  const output = [];
  let tiendaNav;
  const tiendaOutput = [];
  if (getData) {
    const data = props.layoutData.SeccionesHeader[0].Hijos[0].Hijos;
    for (let i = 0; i < data.length; i += 1) {
      const parent = data[i];
      const children = addChildren(parent);
      const iconList = [iconMovil, iconHogar, iconEntretenimiento, iconPromociones, iconSitios];
      const block = (
        <ul className="nav--menu-links" key={parent.Unico}>
          <li className="desk icon">{iconList[i]}</li>
          <li><a href={he.decode(parent.URL)}><b>{he.decode(parent.Descripcion)}</b></a></li>
          {children}
        </ul>
      );
      output.push(block);
      servicesNav = (
        <nav className="nav--menu-list">
          {output}
        </nav>
      );
    }

    let dataTienda = [];
    for (let i = 0; i < headers.length; i += 1) {
      if (headers[i].Descripcion.toLowerCase().trim() === 'tienda') {
        dataTienda = headers[i].Hijos;
        break;
      }
    }

    for (let i = 0; i < dataTienda.length; i += 1) {
      const parent = dataTienda[i];
      const children = [];
      const getChildren = parent && parent.Hijos && parent.Hijos.length;
      const theChildren = parent.Hijos;
      if (getChildren) {
        for (let c = 0; c < theChildren.length; c += 1) {
          const child = theChildren[c];
          const link = he.decode(child.URL);
          const label = he.decode(child.Descripcion);
          const key = child.Unico;
          children.push(<li key={`menu-${key}`}><a href={link}>{label}</a></li>);
        }
      }
      const block = (
        <ul className="nav--menu-links-tienda" key={parent.Unico}>
          <li><a href={he.decode(parent.URL)}><b>{he.decode(parent.Descripcion)}</b></a></li>
          {children}
        </ul>
      );
      tiendaOutput.push(block);
      tiendaNav = (
        <nav className="nav--menu-list-tienda">
          {tiendaOutput}
        </nav>
      );
      console.debug(tiendaNav);
    }
  }

  const getSeccionesHeader = props && props.layoutData && props.layoutData.SeccionesHeader && props.layoutData.SeccionesHeader[0] && props.layoutData.SeccionesHeader[0].Hijos ? props.layoutData.SeccionesHeader[0].Hijos : [];
  // console.log('getSeccionesHeader: ', getSeccionesHeader);
  const seccionesHeaderMobile = getSeccionesHeader.map((i) => {
    let outputMobile;
    if (i.URL === 'https://www.claro.com.pa/personas/servicios' || i.Descripcion.toLowerCase().trim() === 'servicios') {
      outputMobile = '';
      // (
      //   <React.Fragment key={`servicios-${i.Unico}`}>
      //     <a className="nav--menu-title" onClick={props.handleSubmenuMobile}>
      //       <span>Servicios</span>
      //       <span className="mobile-icon icon-list fa-plus" />
      //     </a>
      //     { servicesNav }
      //   </React.Fragment>
      // );
    } else if (i.Descripcion.toLowerCase().trim() === 'tienda' || i.URL === 'https://www.claro.com.pa/personas/tiendaonline') {
      outputMobile = ''; // (<Link to="/" key={`tienda-${i.Unico}`} className="nav--menu-title"><span>{he.decode(i.Descripcion)}</span><span className="mobile-icon fa-angle-right" /></Link>);
    } else {
      outputMobile = (<a key={`${i.Descripcion}-${i.Unico}`} href={he.decode(i.URL)} className="nav--menu-title"><span>{he.decode(i.Descripcion)}</span><span className="mobile-icon fa-angle-right" /></a>);
    }
    return outputMobile;
  });

  return (
    <div className="nav--menu" onMouseLeave={isDesktop ? props.handleMenuLinks : null}>
      <section className="nav--menu-content">
        <a className="nav--menu-title" onClick={props.handleSubmenuMobile}>
          <span>Servicios</span>
          <span className="mobile-icon icon-list fa-plus" />
        </a>
        {servicesNav}
        <a className="nav--menu-title" onClick={props.handleSubmenuMobileTienda}>
          <span>Catálogo</span>
          <span className="mobile-icon icon-list-tienda fa-plus" />
        </a>
        {tiendaNav}
        {seccionesHeaderMobile}
        {/* <Link to="/cart" className="nav--menu-title wine" onClick={props.handleMenuToggle}>
          <span>Carrito de compras</span>
          <CartQuantity cartInfo={props.cartInfo}
          />
        </Link> */}
        {mobileMiClaro}
      </section>
      <aside className="nav--menu-slogan">
        {slogan}
      </aside>
    </div>
  );
};

const UI = ({ handleCountry, handleHover, handleLeave, handleScroll, handleChange, handleSearchToggle, handleMenuToggle, handleMenuLinks, handleMenuLinksTienda, handleMenuLogin, handleClicks, showLoginModal, showRegisterModal, cartInfo, userInfo, validateUser, handleSubmenuMobile, handleLoginMobile, handleSubmenuMobileTienda, logout, layoutData/* , addOnsList */ }) => (
  <div className="nav">
    <Helmet title={`Claro | Catálogo Claro ${process.env.COUNTRY}`} />
    <NavTop
      handleCountry={handleCountry}
      handleHover={handleHover}
      handleLeave={handleLeave}
      handleScroll={handleScroll}
      handleChange={handleChange}
      layoutData={layoutData}
    />
    <NavMain
      handleSearchToggle={handleSearchToggle}
      handleMenuToggle={handleMenuToggle}
      handleMenuLinks={handleMenuLinks}
      handleMenuLinksTienda={handleMenuLinksTienda}
      handleMenuLogin={handleMenuLogin}
      handleClicks={handleClicks}
      showLoginModal={showLoginModal}
      showRegisterModal={showRegisterModal}
      cartInfo={cartInfo}
      // addOnsList={addOnsList}
      userInfo={userInfo}
      validateUser={validateUser}
      logout={logout}
      layoutData={layoutData}
    />
    {isIE ? <IEnotification /> : null}
    <NavMenuLinks
      handleSubmenuMobile={handleSubmenuMobile}
      handleSubmenuMobileTienda={handleSubmenuMobileTienda}
      handleLoginMobile={handleLoginMobile}
      handleMenuLinks={handleMenuLinks}
      handleMenuLinksTienda={handleMenuLinksTienda}
      handleMenuToggle={handleMenuToggle}
      userInfo={userInfo}
      cartInfo={cartInfo}
      // addOnsList={addOnsList}
      showLoginModal={showLoginModal}
      showRegisterModal={showRegisterModal}
      logout={logout}
      layoutData={layoutData}
    />
    <Loading />
  </div>
);

UI.propTypes = {
  handleCountry: PropTypes.func.isRequired,
  handleHover: PropTypes.func.isRequired,
  handleLeave: PropTypes.func.isRequired,
  handleScroll: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSearchToggle: PropTypes.func.isRequired,
  handleMenuToggle: PropTypes.func.isRequired,
  handleMenuLinks: PropTypes.func.isRequired,
  handleMenuLogin: PropTypes.func.isRequired,
  handleClicks: PropTypes.func.isRequired,
  showLoginModal: PropTypes.func.isRequired,
  showRegisterModal: PropTypes.func.isRequired,
  cartInfo: PropTypes.shape({}),
  userInfo: PropTypes.shape({}),
  validateUser: PropTypes.bool,
  handleSubmenuMobile: PropTypes.func.isRequired,
  handleLoginMobile: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  layoutData: PropTypes.shape({}).isRequired,
  // addOnsList: PropTypes.shape({}),
};

UI.defaultProps = {
  cartInfo: {},
  userInfo: {},
  validateUser: true,
  // addOnsList: {},
};

NavTop.propTypes = {
  handleLeave: PropTypes.func.isRequired,
  handleHover: PropTypes.func.isRequired,
  handleCountry: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  layoutData: PropTypes.shape({
    Menus: PropTypes.array,
    Secciones: PropTypes.array,
  }),
};

NavTop.defaultProps = {
  layoutData: {
    Menus: [],
    Secciones: [],
  },
};

NavTopMobile.propTypes = {
  handleChange: PropTypes.func.isRequired,
  layoutData: PropTypes.shape({
    Menus: PropTypes.array,
    Secciones: PropTypes.array,
  }),
};

NavTopMobile.defaultProps = {
  layoutData: {
    Menus: [],
    Secciones: [],
  },
};

NavMain.propTypes = {
  handleMenuToggle: PropTypes.func.isRequired,
  handleClicks: PropTypes.func.isRequired,
  showLoginModal: PropTypes.func.isRequired,
  showRegisterModal: PropTypes.func.isRequired,
  cartInfo: PropTypes.shape({}),
  // addOnsList: PropTypes.shape({}),
  userInfo: PropTypes.shape({
    userGuest: PropTypes.bool,
    data: PropTypes.shape({
      logon_id: PropTypes.string,
      data: PropTypes.shape({
        first_name: PropTypes.string,
      }),
    }),
  }),
  validateUser: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  layoutData: PropTypes.shape({
    MiClaro: PropTypes.string,
    SeccionesHeader: PropTypes.array,
  }),
};

NavMain.defaultProps = {
  cartInfo: {},
  // addOnsList: {},
  userInfo: {},
  validateUser: true,
  layoutData: {
    MiClaro: '',
    SeccionesHeader: [],
  },
};

NavMenuLinks.propTypes = {
  handleSubmenuMobile: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  handleMenuToggle: PropTypes.func.isRequired,
  handleMenuLinks: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    userGuest: PropTypes.bool,
    data: PropTypes.shape({
      logon_id: PropTypes.string,
    }),
  }),
  cartInfo: PropTypes.shape({}),
  // addOnsList: PropTypes.shape({}),
  layoutData: PropTypes.shape({
    Eslogan: PropTypes.string,
    MiClaro: PropTypes.string,
    SeccionesHeader: PropTypes.array,
  }),
};

NavMenuLinks.defaultProps = {
  userInfo: {},
  cartInfo: {},
  // addOnsList: {},
  layoutData: {
    Eslogan: '',
    MiClaro: '',
    SeccionesHeader: {},
  },
};

CartQuantity.propTypes = {
  cartInfo: PropTypes.shape({}),
  // addOnsList: PropTypes.shape({}),
};

CartQuantity.defaultProps = {
  cartInfo: {},
  // addOnsList: {},
};


export default UI;
