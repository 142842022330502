// component
import React from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';
// import $ from 'jquery';

// utilities
// import $ from 'jquery';
import Slider from 'react-slick'; // https://github.com/akiran/react-slick

import { useHookstate } from '@hookstate/core';
import { itemConfig } from '~/store/States/dataconfig';
// Components
import SearchBox from '../../components/searchBox/SearchBox.ui';
import Facets from './../../components/Facets/Facets';
import ProductBox from './../../components/productBox/ProductBox';
import { ArrowPrev, ArrowNext } from '../../helpers/svg';

// const isIE = (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1);

const Pagination = ({ paginationClick, handleOnChangePagination, handleOnChangePaginationByPage, pageNumbers, indexPage, itemsPerPageValue }) => {
  const paginationNumeration = [];
  if (pageNumbers.length > 5) {
    for (let index = indexPage.initPage; index < indexPage.endPage; index += 1) {
      paginationNumeration.push(<a key={`pagination-page-number-${index}`} id={pageNumbers[index]} className="pagination--page" onClick={paginationClick}>{ index + 1 }</a>);
    }
  } else {
    for (let index = 0; index < pageNumbers.length; index += 1) {
      paginationNumeration.push(<a key={`pagination-page-number-${index}`} id={pageNumbers[index]} className="pagination--page" onClick={paginationClick}>{ index + 1 }</a>);
    }
  }

  return (
    <div className="pagination">
      <section>
        <a className="pagination-first" onClick={handleOnChangePaginationByPage}><span id="pagination-first">Primera pág</span></a>
        <a className="pagination-prev" id="pagination-prev" onClick={handleOnChangePaginationByPage}>...</a>
        { paginationNumeration }
        <a className="pagination-next" id="pagination-next" onClick={handleOnChangePaginationByPage}>...</a>
        <a className="pagination-last" onClick={handleOnChangePaginationByPage}><span id="pagination-last"> Última pág</span></a>
      </section>
      <aside>
        <p>Equipos por página</p>
        <label>
          <select value={itemsPerPageValue} onChange={handleOnChangePagination}>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
        </label>
      </aside>
    </div>
  );
};

const CatalogBannerBox = ({ eSpotInfo }) => {
  let link;
  let images;

  if (eSpotInfo) {
    images = (
      <picture>
        <source media="(min-width: 760px) and (max-width: 1024px)" srcSet={`${process.env.ESPOTS}/slider_banners/${eSpotInfo.hrefTablet}`} />
        <source media="(min-width: 0px) and (max-width: 760px)" srcSet={`${process.env.ESPOTS}/slider_banners/${eSpotInfo.hrefPhone}`} />
        <img src={`${process.env.ESPOTS}/slider_banners/${eSpotInfo.href}`} alt={eSpotInfo.title} />
      </picture>
    );

    if (eSpotInfo.link) {
      link = <Link to={eSpotInfo.link}>{images}</Link>;
    } else {
      link = <a href={eSpotInfo.externalSite}>{images}</a>;
    }
  }

  return (
    <div>
      { link }
    </div>
  );
};

const CatalogBanner = ({ eSpot }) => {
  const bannerSettings = {
    customPaging() {
      return <a><span className="fa fa-circle" /></a>;
    },
    dots: true,
    autoplay: true,
    autoplaySpeed: 4500,
    speed: 1000,
    infinite: true,
    pauseOnHover: false,
    initialSlide: 2,
    arrows: true,
    prevArrow: <ArrowPrev />,
    nextArrow: <ArrowNext />,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slider = ((eSpot.data && eSpot.data.items) || []).map((eSpotInfo, i) => {
    const index = i;
    return (<CatalogBannerBox eSpotInfo={eSpotInfo} key={index} />);
  });
  return (
    <section className="catalog--banner">
      <Slider {...bannerSettings}>
        {slider}
      </Slider>
    </section>
  );
};

const MacroCategoryDiv = ({ item, keys, toggleSelected, toggleShowMacro, showMacro, syncProductList }) => {
  console.debug(item, item.external_referrer, showMacro);
  return (<span key={`${keys}-span`} className="nav--category-box" id={keys} onClick={() => { toggleShowMacro(keys); toggleSelected(keys); syncProductList(item.external_referrer, item.locales[process.env.LOCALE].description, item.external_referer); }} >{item.locales[process.env.LOCALE].name.toUpperCase()}</span>);
};

const MacroCategoriesGrid = ({ macroCategories, toggleSelected, toggleShowMacro, syncProductList, showMacro, renovaciones }) => {
  if (macroCategories) {
    const items = (macroCategories.data || []).map((v, i) => {
      // console.log('');
      const keys = `category-box-${i}`;
      return <MacroCategoryDiv item={v} key={keys} keys={keys} toggleSelected={toggleSelected} toggleShowMacro={toggleShowMacro} syncProductList={syncProductList} showMacro={showMacro} />;
    });
    const recom = renovaciones ? (<Link className="nav--category-box" to="/renewals">{renovaciones}</Link>) : null;
    return (
      <div className="grid nav--grid">
        {items}
        {recom}
      </div>
    );
  }
  return false;
};

const CatalogGrid = ({ productInfo, eSpot, addonsList, macroCategories, showMacro, toggleShowMacro, syncProductList, toggleSelected, productsFilter, resetCatalog, selectedMacro, categoryId, categorySlug, categoryName }) => {
  if (!showMacro) {
    const items = (productInfo || []).map((v, i) => {
      const keys = `catalog--${i}--${v.part_number}`;
      if (addonsList.some(x => x.part_number === v.part_number)) return false;
      if (!v.available || !v.buyable) return false;
      const availableItems = v.items.filter(x => x.available && x.buyable);
      if (availableItems.length === 0) return false;
      return <ProductBox items={v} key={keys} showMacro={showMacro} />;
    });
    // console.log(selectedMacro);
    const catDesc = selectedMacro;
    const hastTwoDesc = catDesc.includes('|');
    let listCatDes = [];
    if (hastTwoDesc) {
      listCatDes = catDesc.split('|');
    }
    return (
      <section className="catalog--grid">
        <Facets productsFilter={productsFilter} resetCatalog={resetCatalog} categoryId={categoryId} categorySlug={categorySlug} categoryName={categoryName} />
        <section className="catalog--grid-detalle">
          {listCatDes.length > 1 ?
            <div id="description" className="category-description"><h1>{listCatDes[0].trim()} | <span className="category-description-second">{listCatDes[1].trim()}</span></h1></div> :
            <div id="description" className="category-description"><h1>{selectedMacro}</h1></div>
          }
          <br />
          <div className="catalog--grid-productos-detalle">
            { items }
          </div>
        </section>
      </section>
    );
  } else if (macroCategories && productInfo && productInfo.length) {
    const catalogSettings = {
      infinite: true,
      autoplay: false,
      autoplaySpeed: 4000,
      dots: false,
      speed: 500,
      // centerMode: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      pauseOnHover: true,
      // initialSlide: 1,
      arrows: false,
    };
    const items = (macroCategories.data || []).map((v1, i1) => {
      // console.log(i1);
      // console.log(v1);
      let index;
      productInfo.forEach((element, i) => {
        if (element[0] === v1.locales[process.env.LOCALE].name) {
          index = i;
        }
      });
      if (productInfo[index] && productInfo[index].length > 1) {
        const categoryKey = `category-box-${i1}`;
        let printed = 0;
        const items2 = (productInfo[index] || []).map((v, i) => {
          if (printed > 3) return false;
          const keys = `catalog--${i}`;
          if (addonsList.some(x => x.part_number === v.part_number)) return false;
          if (!v.available || !v.buyable) return false;
          const availableItems = v.items.filter(x => x.available && x.buyable);
          if (availableItems.length === 0) return false;
          printed += 1;
          return <ProductBox items={v} key={keys} />;
        });
        return (
          <section key={`catalog--product-section-${i1}`} className="catalog--product-section">
            <h1>{v1.locales[process.env.LOCALE].name}</h1>
            {isMobile ?
              <Slider {...catalogSettings} style={{ marginTop: '1em' }}>
                {items2}
              </Slider> :
              <div className="catalog--grid-productos">
                { items2 }
              </div>
            }
            <div className="catalog--seemore-container"><button className="catalog--seemore" onClick={() => { toggleShowMacro(categoryKey); syncProductList(v1.external_referrer, v1.locales[process.env.LOCALE].description, v1.external_referer); toggleSelected(categoryKey); }}>Ver más</button></div>
          </section>
        );
      }
      return false;
    });
    return (
      <section className="catalog--grid">
        <CatalogBanner eSpot={eSpot} />
        { items }
      </section>
    );
  } else {
    return false;
  }
};

const WrapMenuLi = ({ item, keys, toggleChildCategories, productListFunction, toggleShowMacro, showMacro, syncProductList, toggleSelected, closeMenu }) => {
  console.debug('WrapMenuLi', item);
  return (
    <li key={`${keys}-li`}><a href="#" onMouseEnter={() => toggleChildCategories(item.external_referrer, productListFunction, toggleShowMacro, showMacro)} onClick={(e) => { e.preventDefault(); closeMenu(); toggleShowMacro(keys); toggleSelected(keys); syncProductList(item.external_referrer, item.locales[process.env.LOCALE].description, item.external_referer); }}>{item.locales[process.env.LOCALE].name}<i className="fas fa-chevron-right" /></a></li>
  );
};

const WrapMenu = ({ categories, closeMenu, parentCategories, toggleChildCategories, productListFunction, toggleShowMacro, showMacro, syncProductList, toggleSelected, childCategories }) => {
  if (categories) {
    const parents = [];
    parentCategories.forEach((element) => {
      categories.data.filter(category => category.external_referrer === element).map(filteredName => (
        parents.push(filteredName)
      ));
    });
    const items = (parents || []).map((v, i) => {
      const keys = `category-box-${i}`;
      return <WrapMenuLi item={v} key={keys} keys={keys} toggleChildCategories={toggleChildCategories} productListFunction={productListFunction} toggleShowMacro={toggleShowMacro} showMacro={showMacro} syncProductList={syncProductList} toggleSelected={toggleSelected} closeMenu={closeMenu} />;
    });
    return (
      <div className="wrapMenu" onMouseLeave={() => closeMenu()}>
        <ul>
          {items}
        </ul>
        <div className="wrapMenuPrincipal" id="wrapMenuPrincipal">
          {childCategories}
          <img src="/cdn/espots/media/banner-menu.png" alt="" />
        </div>
      </div>
    );
  }
  return false;
};
const MobileMenu = ({ closeNav, parentCategories, thirdLevel, syncProductList }) => {
  console.log('');
  console.log(syncProductList);
  if (parentCategories) {
    const items = (parentCategories.data || []).map((v) => {
      const items2 = (thirdLevel || []).map((v2) => {
        console.log(v2);
        if (v2.locales[process.env.LOCALE].parent_code !== v.external_referrer) return false;
        return <a href="#" className="mobileli" onClick={() => { closeNav(); syncProductList(v2.external_referrer, v2.locales[process.env.LOCALE].description, v2.external_referer, true); }} >{v2.locales[process.env.LOCALE].name}</a>;
      });
      console.log(items2);
      return (
        <Collapsible trigger={v.locales[process.env.LOCALE].name.toUpperCase()}>
          {items2}
        </Collapsible>
      );
    });
    console.log(items);
    return (
      <div id="mySidenav" className="sidenav">
        <a href="#" className="closebtn" onClick={closeNav}>&times;</a>
        <div className="content" />
        {items}
      </div>
    );
  }
  return false;
};

const UI = (props) => {
  const itemGlobalState = useHookstate(itemConfig);
  return (
    <main>
      <section className="catalog">
        <header className="catalog--header">
          {/* eslint-disable-line no-use-before-define */}
          <h1>{itemGlobalState.value && itemGlobalState.value.product_title_catalog}</h1>
        </header>
        <div className="catalog--module">
          {!isMobile ?
            <div className="nav--categories" onClick={() => props.openMenu()} onMouseEnter={() => props.openMenu()}>
              <span>&#9776; Todas las categorías</span>
            </div> :
            <div className="menuContainer">
              <MobileMenu closeNav={props.closeNav} parentCategories={props.macroCategories} thirdLevel={props.thirdLevel} showMacro={props.showMacro} syncProductList={props.syncProductList} />
              <div className="nav--categories">
                <span style={{ cursor: 'pointer' }} onClick={props.openNav}>&#9776; Todas las categorias</span>
              </div>
            </div>
          }
          <div className="nav--search" onFocus={props.handleClicks}>
            <SearchBox />
          </div>
          {!isMobile ? <WrapMenu categories={props.categories} closeMenu={props.closeMenu} parentCategories={props.parentCategories} toggleChildCategories={props.toggleChildCategories} productListFunction={props.productListFunction} toggleShowMacro={props.toggleShowMacro} showMacro={props.showMacro} toggleSelected={props.toggleSelected} syncProductList={props.syncProductList} childCategories={props.childCategories} /> : '' }
          <MacroCategoriesGrid macroCategories={props.macroCategories} toggleSelected={props.toggleSelected} toggleShowMacro={props.toggleShowMacro} syncProductList={props.syncProductList} showMacro={props.showMacro} renovaciones={props.renovaciones} />
          <CatalogGrid productInfo={props.productList} eSpot={props.eSpot} addonsList={props.addonsList} macroCategories={props.macroCategories} showMacro={props.showMacro} toggleShowMacro={props.toggleShowMacro} syncProductList={props.syncProductList} toggleSelected={props.toggleSelected} productsFilter={props.productsFilter} resetCatalog={props.resetCatalog} selectedMacro={props.selectedMacro} categoryId={props.categoryId} categorySlug={props.categorySlug} categoryName={props.categoryName} />
          {!props.showMacro ? <Pagination paginationClick={props.paginationClick} pageNumbers={props.pageNumbers} handleOnChangePagination={props.handleOnChangePagination} currentPage={props.currentPage} indexPage={props.indexPage}handleOnChangePaginationByPage={props.handleOnChangePaginationByPage} itemsPerPageValue={props.itemsPerPageValue} /> : ''}
        </div>
      </section>
    </main>
  );
};

UI.propTypes = {
  productList: PropTypes.arrayOf(PropTypes.array),
  eSpot: PropTypes.shape({}),
  paginationClick: PropTypes.func.isRequired,
  handleOnChangePagination: PropTypes.func.isRequired,
  handleOnChangePaginationByPage: PropTypes.func.isRequired,
  pageNumbers: PropTypes.arrayOf(PropTypes.number),
  currentPage: PropTypes.number,
  indexPage: PropTypes.shape({
    initPage: PropTypes.number,
    endPage: PropTypes.number,
  }),
  itemsPerPageValue: PropTypes.number.isRequired, // eslint-disable-line
  addonsList: PropTypes.arrayOf(PropTypes.shape({})),
  categories: PropTypes.shape({}),
  toggleSelected: PropTypes.func.isRequired,
  openMenu: PropTypes.func.isRequired,
  toggleChildCategories: PropTypes.func.isRequired,
  showMacro: PropTypes.bool,
  productListFunction: PropTypes.func.isRequired,
  toggleShowMacro: PropTypes.func.isRequired,
  syncProductList: PropTypes.func.isRequired,
  selectedMacro: PropTypes.string,
  openNav: PropTypes.func.isRequired,
  closeNav: PropTypes.func.isRequired,
  thirdLevel: PropTypes.arrayOf(PropTypes.shape({})),
};

UI.defaultProps = {
  productList: [],
  eSpot: {},
  pageNumbers: [],
  currentPage: 1,
  indexPage: {},
  addonsList: [],
  showMacro: true,
  selectedMacro: '',
  categories: null,
  thirdLevel: [],
};

CatalogGrid.propTypes = {
  productInfo: PropTypes.arrayOf(PropTypes.array),
  eSpot: PropTypes.shape({}),
};

CatalogGrid.defaultProps = {
  productInfo: [],
  eSpot: {},
};

CatalogBanner.propTypes = {
  eSpot: PropTypes.shape({}),
};

CatalogBanner.defaultProps = {
  eSpot: {},
};

CatalogBannerBox.propTypes = {
  eSpotInfo: PropTypes.shape({}),
};

CatalogBannerBox.defaultProps = {
  eSpotInfo: {},
};

Pagination.propTypes = {
  pageNumbers: PropTypes.arrayOf(PropTypes.number),
  handleOnChangePagination: PropTypes.func.isRequired,
  handleOnChangePaginationByPage: PropTypes.func.isRequired,
  indexPage: PropTypes.shape({
    initPage: PropTypes.number,
    endPage: PropTypes.number,
  }),
  itemsPerPageValue: PropTypes.number.isRequired, // eslint-disable-line
};

Pagination.defaultProps = {
  pageNumbers: [],
  indexPage: {},
};
export default UI;
