import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import $ from 'jquery';

// Helpers
import validate from '../../../helpers/validate_form';

// UI
import UI from './Token.ui';

class Token extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(...props) {
    super(...props);

    this.state = {
      error: '',
    };

    this.internalData = {
      token: '',
    };
    // Binds
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.switchModal = this.switchModal.bind(this);
  }

  componentDidMount() {
    validate();
    this.clearError();
  }

  clearError() {
    this.setState({ error: '' });
  }

  clearToken() {
    this.setState({ token: '' });
  }

  switchModal() {
    this.clearToken();
    this.clearError();
    $('.modal--validate-token').removeClass('active');
    $('.modal--validate-number').addClass('active');
  }

  handleChange(e, data) {
    const keys = data.split('.');
    const tokenForm = this;
    tokenForm[keys[0]][keys[1]] = e.target.value;
    this.setState(tokenForm);
    if (!this.state.error || this.state.error !== '') {
      this.clearError();
    }
  }
  handleData() {
    this.validateToken();
  }

  validateToken() {
    this.clearError();
    const resultVN = this.props.orderStore.validateNumberResponse;
    const responseVN = resultVN.data.data.validateNumberResponse;
    const queryValidarToken = {
      validarToken: {
        UserProfileID: '',
        MobileNumber: responseVN.RealNumber,
        LineOfBusiness: responseVN.LineOfBusiness,
        Token: this.internalData.token,
      },
    };
    this.props.orderStore.validarToken(queryValidarToken).then((resultVT) => {
      console.log(resultVT);
      if (resultVT.success) {
        const responseVT = resultVT.data.data.validarTokenResponse;
        if (responseVT && responseVT.AcknowledgementIndicator === 'SUCCESS') {
          this.props.orderStore.validateCartTrigger();
          $('.modal--validate-token').removeClass('active');
          $('.modal').fadeOut();
        } else {
          this.setState({ error: responseVT.AcknowledgementDescription });
        }
      } else {
        this.setState({ error: 'Ha ocurrido un error, intente más tarde.' });
      }
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const isIE =
      navigator.userAgent.indexOf('MSIE') !== -1 ||
      navigator.appVersion.indexOf('Trident/') > -1;
    if (isIE) this.handleData();
    else if (!isIE && $("form[name='validateToken']").valid()) this.handleData();
  }

  render() {
    return (
      <UI
        tokenForm={this.internalData}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        error={this.state.error}
        switchModal={this.switchModal}
      />
    );
  }
}

Token.propTypes = {
  orderStore: PropTypes.shape({
    validateNumber: PropTypes.func,
  }).isRequired,
};

export default inject('orderStore')(observer(Token));
