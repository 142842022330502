import React from 'react';
import { Link } from 'react-router-dom';
import he from 'he';
import PropTypes from 'prop-types';

const LogoutBtn = props => (
  <div className="ui--logout-btn ui-log-btn active ie-fix">
    <Link to="/account">
      { props.username && <span>Hola&nbsp;</span>}
      <b>{props.username ? he.decode(props.username) : 'Mi Cuenta'}</b>
    </Link>
    <a onClick={props.logout}>Cerrar Sesión</a>
  </div>
);

export default LogoutBtn;

LogoutBtn.propTypes = {
  logout: PropTypes.func.isRequired,
  username: PropTypes.string,
};

LogoutBtn.defaultProps = {
  username: '',
};
