import _ from 'lodash';

const productHelpers = {
  /**
   * Generate color dictionary
   * @param {obj} props
  */
  colorProduct(props, size) {
    const productList = {};
    _.remove(props.items, item => !(item.available === true && item.buyable === true));
    _.each(props.items, (data) => {
      const attrMap = this.convertAttributes(data.attributes);
      const colorFilter = attrMap['def-color'];
      const sizeFilter = attrMap['def-capacidad'];
      const images = [];
      if (colorFilter) {
        for (let i = 0; i < 8; i += 1) {
          if (data && data.images && data.images.length && data.images[i]) {
            images.push(data.images[i]);
          }
        }

        if (!productList[colorFilter] || sizeFilter === size) {
          productList[colorFilter] = {
            color: colorFilter,
            size: sizeFilter,
            part_number: data.part_number,
            locales: data.locales,
            pricing: data.pricing,
            available: data.available,
            attributes: data.attributes,
            item: data,
            images,
          };
        }
      }
    });
    return productList;
  },

  /**
   * Generate size dictionary
   * @param {obj} props
  */
  sizeProduct(props) {
    const sizeList = {};
    _.each(props.items, (data) => {
      const sizeFilter = _.filter(data.attributes, { name: 'def-capacidad' });
      const colorFilter = _.filter(data.attributes, { name: 'def-color' });
      if (colorFilter.length || sizeFilter.length) {
        if (_.isUndefined(sizeList[colorFilter[0].value])) sizeList[colorFilter[0].value] = [];
        sizeList[colorFilter[0].value].push({
          size: sizeFilter[0].value,
          part_number: data.part_number,
          pricing: data.pricing,
        });
      }
    });
    return sizeList;
  },

  itemByColorAndSize(props) {
    const item = {};
    _.each(props.items, (data) => {
      const sizeFilter = _.filter(data.attributes, { name: 'def-capacidad' });
      const colorFilter = _.filter(data.attributes, { name: 'def-color' });
      if (colorFilter.length || sizeFilter.length) {
        if (_.isUndefined(item[colorFilter[0].value])) item[colorFilter[0].value] = {};
        if (_.isUndefined(item[colorFilter[0].value][sizeFilter[0].value])) {
          item[colorFilter[0].value][sizeFilter[0].value] = {};
        }
        item[colorFilter[0].value][sizeFilter[0].value] = { part_number: data.part_number, available: data.available, buyable: data.buyable, size: sizeFilter[0].value };
      }
    });
    return item;
  },

  tipo(producto) {
    for (let i = 0; i < producto.attributes.length; i += 1) {
      const attr = producto.attributes[i];
      if ((attr.identifier || attr.name) === 'attr-conf-tipoproducto') {
        return attr.value;
      }
    }
    return '';
  },
  tipoPlan(producto) {
    for (let i = 0; i < producto.attributes.length; i += 1) {
      const attr = producto.attributes[i];
      if ((attr.identifier || attr.name) === 'attr-conf-tipoplan') {
        return attr.value;
      }
    }
    return '';
  },

  recurrente(producto) {
    for (let i = 0; i < producto.attributes.length; i += 1) {
      const attr = producto.attributes[i];
      if ((attr.identifier || attr.name) === 'attr-conf-pagorecurrente') {
        return attr.value;
      }
    }
    return '';
  },

  convertAttributes(attributes) {
    return attributes.reduce((obj, attribute) => {
      obj[attribute.identifier] = attribute.value;
      return obj;
    }, {});
  },

  ordenar(producto) {
    producto.items = producto.items.filter(it => it.available && it.buyable).sort((it1, it2) => {
      if (it1.pricing[process.env.CURRENCY].offer_price < it2.pricing[process.env.CURRENCY].offer_price) {
        return -1;
      } else if (it1.pricing[process.env.CURRENCY].offer_price > it2.pricing[process.env.CURRENCY].offer_price) {
        return 1;
      }
      return it1.position - it2.position;
    });
  },

  arrayToObjectProductInfo(exp, props, color, size) {
    let attrs;
    let attrsTech;
    console.debug(props);
    if (props && props.length) {
      _.map(props[0].items, (data) => {
        console.debug(data);
        const itemColor = _.filter(data.attributes, { identifier: 'def-color' });
        const itemSize = _.filter(data.attributes, { identifier: 'def-capacidad' });
        if ((!itemColor[0] || !itemSize[0]) || (itemColor[0].value === color && itemSize[0].value === size)) {
          attrsTech = _.reduce(data.attributes, (a, b) => {
            if ((b.name).match(exp)) {
              a[b.name] = b.value;
            }
            return a;
          }, {});

          attrs = attrsTech;
        }
      });
    }

    return attrs;
  },

  arrayToObjectProducGenerictInfo(exp, props, color, size, screen) {
    const attrs = [];
    if (props) {
      _.map(props.items, (data) => {
        const itemColor = _.filter(data.attributes, { identifier: 'def-color' });
        const itemSize = _.filter(data.attributes, { identifier: 'def-capacidad' });
        const itemScreen = _.filter(data.attributes, { identifier: 'def-pantalla' });
        if ((!itemColor[0] || !itemSize[0]) || (itemColor[0].value === color && itemSize[0].value === size && itemScreen[0].value === screen)) {
          _.map(data.attributes, (b) => {
            const a = {
              identifier: null,
              value: null,
            };
            if ((b.identifier).match(exp)) {
              a.identifier = b.identifier;
              a.value = b.value;
              attrs.push(a);
            }
          });
        }
      });
    }

    return attrs;
  },
  arrayToObjectProducGenerictBasicInfo(exp, props, color, size, screen) {
    const attrs = [];
    if (props) {
      _.map(props.items, (data) => {
        const itemColor = _.filter(data.attributes, { identifier: 'def-color' });
        const itemSize = _.filter(data.attributes, { identifier: 'def-capacidad' });
        const itemScreen = _.filter(data.attributes, { identifier: 'def-pantalla' });
        if ((!itemColor[0] || !itemSize[0]) || (itemColor[0].value === color && itemSize[0].value === size && itemScreen[0].value === screen)) {
          _.map(data.attributes, (b) => {
            const a = {
              identifier: null,
              value: null,
            };
            if ((b.identifier).match(exp)) {
              a.identifier = b.identifier;
              a.value = b.value;
              attrs.push(a);
            }
          });
        }
      });
    }

    return attrs;
  },
};

export default productHelpers;
