import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { render } from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { QueryClientProvider } from 'react-query';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import loadable from '@loadable/component';

// Mobx
import { Provider } from 'mobx-react';
import 'babel-polyfill';

// import
import './sass/style.scss';

// Components
import Layout from './containers/Layout/DefaultLayout';
import LayoutBlank from './containers/Layout/BlankLayout';
import Catalog from './containers/Catalog/Catalog';
import Search from './containers/Catalog/Search';
import Product from './containers/Product/Product';
// import Cart from './containers/Cart/Cart';
// import Checkout from './containers/Checkout/Register/Register';
// import CheckoutAddress from './containers/Checkout/Address/Address';
// import CheckoutPayment from './containers/Checkout/Payments/MPCard';
// import CheckoutConfirm from './containers/Checkout/Confirm/Confirm';

// import DashboardAccount from './containers/Account/DashboardAccount/DashboardAccount';
// import Orders from './containers/Account/Orders/Orders';
// import OrdersDetails from './containers/Account/Orders/OrdersDetails';
// import Profile from './containers/Account/Profile/Profile';

// import Addresses from './containers/Account/Addresses/Addresses';
// import AddressEdit from './containers/Account/Addresses/AddressEdit';
// import PassRequestedPage from './containers/Account/ResetPassword/Pages/PassRequested';

import Terms from './containers/Views/Common/Terms';
// import Activation from './containers/Views/Common/Activation';
// import PurchaseLimit from './containers/Views/Notification/PurchaseLimit';
import Error404 from './containers/Views/Error404';
import Error403 from './containers/Views/Error403';
import Maintenance from './containers/Views/Maintenance';
// import Renewal from './containers/Views/Renewal';

// Stores
import orderStore from './store/Orders/Cart';
import paymentsStore from './store/Payments/MpCard';
import sessionStore from './store/Sessions/Sessions';
import accountStore from './store/Account/Account';
import catalogStore from './store/Catalog/Catalog';
import facets from './store/Facets/Facets';
import modals from './store/Modals/Modals';
import navigationStore from './store/Navigation/Navigation';
import { queryClient, useConfigMC } from './store/Query/api';

// import { useConfigMC } from '~/store/Query/api';
import { itemConfig } from './store/States/dataconfig';

import analytics from './helpers/analytics';

const ReactPixel = import(/* webpackChunkName: "ReactPixel", webpackPrefetch: true */ 'react-facebook-pixel');

const Renewal = loadable(() => import(/* webpackChunkName: "renewal", webpackPrefetch: true */ './containers/Views/Renewal'));
const Generic = loadable(() => import(/* webpackChunkName: "product-generic", webpackPrefetch: true */ './containers/Product/Generic/Generic'));

const loader = document.querySelector('.init-loader');
const hideLoader = () => loader.classList.add('init-loader--hide');

const DefaultLayout = ({ component: Component }) => (
  <Route render={matchProps => (
    <Layout>
      <Component {...matchProps} />
    </Layout>
    )}
  />
);

const BlankLayout = ({ component: Component }) => (
  <Route render={matchProps => (
    <LayoutBlank>
      <Component {...matchProps} />
    </LayoutBlank>
  )}
  />
);

const stores = {
  orderStore,
  paymentsStore,
  sessionStore,
  accountStore,
  catalogStore,
  facets,
  modals,
  navigationStore,
};

// For easier debugging
window.z = stores;
window.tppAnalytics = analytics;

// log environment
console.log(`.env is ${process.env.ENV_NAME}`);
const history = createBrowserHistory();

const pageview = (location) => {
  if (location.pathname !== '/' && location.pathname.indexOf('/product/') === -1) {
    if (location.pathname.indexOf('/checkout/confirm') !== -1) {
      setTimeout(() => ReactGA.pageview(location.pathname + location.search, ['tracker'], `Confirmación de Compra | Tienda Claro ${process.env.COUNTRY}`), 0);
    } else if (location.pathname.indexOf('/search/') !== -1) {
      const path = location.pathname.split('/');
      const keyword = `?searchTerm=${path.pop()}`;
      setTimeout(() => ReactGA.pageview(path.join('/') + keyword), 0);
    } else {
      setTimeout(() => ReactGA.pageview(location.pathname + location.search), 0);
    }
  }
  setTimeout(() => ReactPixel.then(rp => rp.default.pageView()), 0);
};

history.listen(pageview);
pageview(history.location);

const Root = ({ hideLoaderProp }) => {
  const { data } = useConfigMC();
  if (data && data.ok) {
    itemConfig.set(data.body);
  }
  useEffect(hideLoaderProp, []);
  return (
    <CookiesProvider>
      <Provider {...stores}>
        <Router history={history}>
          <Switch>
            <DefaultLayout exact path="/" component={Catalog} />
            <DefaultLayout exact path="/search/*" component={Search} />
            <DefaultLayout exact path="/renewals" component={Renewal} />
            <DefaultLayout exact path="/product/*" component={Product} />
            <DefaultLayout exact path="/productx/*" component={Generic} />
            {/* <DefaultLayout path="/cart" component={Cart} /> */}
            {/* <DefaultLayout exact path="/checkout" component={Checkout} /> */}
            {/* <DefaultLayout path="/checkout/address" component={CheckoutAddress} /> */}
            {/* <DefaultLayout path="/checkout/payment" component={CheckoutPayment} /> */}
            {/* <DefaultLayout path="/checkout/confirm" component={CheckoutConfirm} /> */}
            {/* <DefaultLayout path="/checkout/purchase-limit" component={PurchaseLimit} /> */}
            {/* <DefaultLayout exact path="/account" component={DashboardAccount} /> */}
            {/* <DefaultLayout exact path="/account/orders" component={Orders} /> */}
            {/* <DefaultLayout exact path="/account/orders/details/:id" component={OrdersDetails} /> */}
            {/* <DefaultLayout exact path="/account/profile" component={Profile} /> */}
            {/* <DefaultLayout exact path="/account/addresses" component={Addresses} /> */}
            {/* <DefaultLayout exact path="/account/addresses-edit" component={AddressEdit} /> */}
            {/* <DefaultLayout exact path="/update_password" component={PassRequestedPage} /> */}
            <DefaultLayout path="/terms" component={Terms} />
            {/* <DefaultLayout path="/activar" component={Activation} /> */}
            <BlankLayout path="/maintenance" component={Maintenance} />
            <BlankLayout path="/forbidden" component={Error403} />
            <BlankLayout path="*" component={Error404} />
          </Switch>
        </Router>
      </Provider>
    </CookiesProvider>
  );
};

// Enter MaintenanceMode if there is no response from catalog
const MaintenanceMode = () => (
  <Router history={history}>
    <BlankLayout path="/maintenance" component={Maintenance} />
  </Router>
);
const App = stores.catalogStore
  ? (
    <QueryClientProvider client={queryClient}>
      <Root hideLoaderProp={hideLoader} />
    </QueryClientProvider>
  ) : <MaintenanceMode />;
  //<Root hideLoaderProp={hideLoader} />
DefaultLayout.propTypes = {
  component: PropTypes.func,
};

DefaultLayout.defaultProps = {
  component: () => {},
};

const tagManagerArgs = {
  gtmId: process.env.TAG,
};

ReactGA.initialize(process.env.GA);
ReactPixel.then(rp => rp.default.init(process.env.PIXEL, {}, {
  autoConfig: true,
  debug: false,
}));

TagManager.initialize(tagManagerArgs);
TagManager.initialize({
  gtmId: process.env.TAG2,
});

render(App, document.getElementById('app'));
