import { useQuery, QueryClient } from 'react-query';
import request from '../../bin/httpRequest';

export const req = (options, sessionIdDisable) => request.genericHandler(options, sessionIdDisable).then((res) => {
  if (res.error) {
    return { ok: false, body: res.error.data };
  }
  return { ok: true, body: res.data.data };
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

const configQuery = config => () => req({
  method: 'GET',
  url: `${process.env.MONGO_API}${process.env.MERCHANT}/config/${config}`,
}, null);

export const useConfig = config => useQuery(config, configQuery(config));

export const getConfig = config => queryClient.fetchQuery(config, configQuery(config));

export const notifyAvailability = data => req({
  method: 'POST',
  url: `${process.env.MONGO_API}${process.env.MERCHANT}/notify/availability`,
  data,
}, null);

const configMCQuery = () => req({
  method: 'GET',
  url: `${process.env.API}${process.env.MERCHANT}/cart/config`,
}, null);

export const useConfigMC = () => useQuery('configMC', configMCQuery);

export const getConfigMC = () => queryClient.fetchQuery('configMC', configMCQuery);

export const queryFunction = async (action, fetchQuery) => {
  const res = await fetchQuery();
  const response = { action, success: false };
  if (res.ok) {
    response.data = { data: res.body };
    response.success = true;
  } else {
    response.error = { data: res.body };
  }
  return response;
};
