import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import $ from 'jquery';

import { addonsList } from './../../store/Addons/Addons';

// UI
import UI from './DefaultHeader.ui';

class Header extends Component {
  constructor(...props) {
    super(...props);
    this.state = {
      addonsList: [],
    };
    this.logout = this.logout.bind(this);
    this.showRegisterModal = this.showRegisterModal.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.props.orderStore.getCart();
    this.props.accountStore.dataPerson();
    this.addonsList();
    if (document.location.pathname === '/terms') {
      this.props.orderStore.getConfig();
    }
  }

  addonsList() {
    addonsList().then((res) => {
      this.setState({ addonsList: res.data[0].filter(x => x.enabled === true && x.show_home === false) });
    });
  }

  logout() {
    this.props.sessionStore.personLogout();
    const medalia_email = '';
    const medalia_user_login = false;
    const medalia_name = '';
    const medalia_order_id = null;
    const medalia_order_state = null;
    const medalia_order_items = null;
    localStorage.setItem('medallia_email', medalia_email);
    localStorage.setItem('medallia_user_login', medalia_user_login);
    localStorage.setItem('medallia_name', medalia_name);
    localStorage.setItem('medallia_order_id', medalia_order_id);
    localStorage.setItem('medallia_order_state', medalia_order_state);
    localStorage.setItem('medallia_order_items', medalia_order_items);
  }

  // NavTop
  handleCountry() {
    $('.nav--top-third-trigger').toggleClass('active');
    $('.nav--top-third-dropdown').slideToggle();
    $('.nav--user').removeClass('active');
    $('.nav--links-trigger').removeClass('active');
    $('.nav--menu').slideUp();
    $('.nav--menu-login').slideUp();
  }

  handleHover() {
    $('.nav--top-second-dropdown').slideDown();
  }

  handleLeave() {
    $('.nav--top-second-dropdown').slideUp();
  }

  handleScroll() {
    const y = window.scrollY;
    if (y >= 1) {
      // $('.nav--top').slideUp(100);
      $('.nav--top').slideUp(140);
      $('.ui-log-btn').removeClass('active');
    } else {
      // $('.nav--top').slideDown(100);
      $('.nav--top').slideDown(140);
      $('.ui-log-btn').addClass('active');
    }
    $('.nav--top-third-dropdown').slideUp();
    $('.nav--top-third-trigger').removeClass('active');
  }

  handleChange() {
    window.location = $('#nav-top-mobile-select').val();
  }

  // NavMain
  handleSearchToggle() {
    $('.nav--trigger-search').toggleClass('fa-search fa-times');
    $('.nav--search').slideToggle();
    $('.nav--menu-content.active').removeClass('active');
    $('.nav--trigger-menu.fa-times').toggleClass('fa-bars fa-times');
  }

  handleMenuToggle() {
    // toggle menu
    $('.nav--trigger-menu').toggleClass('fa-bars fa-times');
    $('.nav--menu-content').toggleClass('active');
    // close search
    // $('.nav--search').slideUp();
    // $('.nav--trigger-search.fa-times').toggleClass('fa-search fa-times');
    // close submenus
    $('.nav--menu-list').slideUp();
    $('.nav--menu-login').slideUp();
    // $('.mobile-icon').addClass('fa-plus').removeClass('fa-minus');
    // re-enable scroll
    // -- $("body.overhidden").removeClass("overhidden");
  }

  // -- Desktop
  handleMenuLinks() {
    $('.nav--links-trigger').remove('active');
    $('.nav--user').removeClass('active');
    $('.nav--menu-login').slideUp();
    $('.nav--menu').slideToggle();
    $('.nav--menu-list-tienda').hide();
    $('.nav--menu-list').show();
  }

  handleMenuLinksTienda() {
    $('.nav--links-trigger').remove('active');
    $('.nav--user').removeClass('active');
    $('.nav--menu-login').slideUp();
    $('.nav--menu').slideToggle();
    $('.nav--menu-list-tienda').show();
    $('.nav--menu-list').hide();
  }

  handleMenuLogin() {
    $('.nav--user').toggleClass('active');
    $('.nav--links-trigger').removeClass('active');
    $('.nav--menu').slideUp();
    $('.nav--menu-login').slideToggle();
  }

  handleClicks() {
    $('.nav--user').removeClass('active');
    $('.nav--links-trigger').removeClass('active');
    // $('.nav--menu').slideUp();
    $('.nav--menu-content').removeClass('active');
    // $('.nav--menu-login').slideUp();
    if ($('.nav--trigger-menu').hasClass('fa-times')) {
      $('.nav--trigger-menu').toggleClass('fa-bars fa-times');
    }
  }

  // NavMenuLinks
  handleSubmenuMobile() {
    // toggle submenu
    $('.nav--menu-list').slideToggle();
    $('.icon-list').toggleClass('fa-plus fa-minus');
    // switch from login
    $('.nav--menu-login').slideUp();
    // $('.icon-login').addClass('fa-plus').removeClass('fa-minus');
    // enable/disable scroll
    // -- $("body").toggleClass("overhidden");
  }

  handleSubmenuMobileTienda() {
    // toggle submenu
    $('.nav--menu-list-tienda').slideToggle();
    $('.icon-list-tienda').toggleClass('fa-plus fa-minus');
    // switch from login
    $('.nav--menu-login').slideUp();
    // $('.icon-login').addClass('fa-plus').removeClass('fa-minus');
    // enable/disable scroll
    // -- $("body").toggleClass("overhidden");
  }

  handleLoginMobile() {
    // // toggle login
    // $('.nav--menu-login').slideToggle();
    // $('.icon-login').toggleClass('fa-plus fa-minus');
    // // switch from list
    // $('.nav--menu-list').slideUp();
    // $('.icon-list').addClass('fa-plus').removeClass('fa-minus');
  }

  // NavLogin
  handleLogin = (e) => {
    e.preventDefault();
    $('.nav--user').toggleClass('active');
    $('.nav--menu-login').slideUp();
  };

  showLoginModal() {
    $('.modal').toggleClass('active').show();
    $('.modal--user').toggleClass('active');
  }

  showRegisterModal() {
    this.showLoginModal();
    setTimeout(() => { document.querySelector('.trigger-register').click(); }, 1);
  }

  render() {
    if (this.props.accountStore.person.data) {
      const username = this.props.accountStore.person.data.data.data;
      if (username && username.email_1) {
        let medalia_email = localStorage.getItem('medallia_email');
        const medalia_user_login = localStorage.getItem('medallia_user_login');
        let medalia_name = localStorage.getItem('medallia_name');
        if (medalia_user_login === 'true') {
          medalia_email = username.email_1;
          medalia_name = `${username.first_name} ${username.last_name}`;
          localStorage.setItem('medallia_email', medalia_email);
          localStorage.setItem('medallia_name', medalia_name);
        }
      }
    }

    return (
      <UI
        handleCountry={this.handleCountry}
        handleHover={this.handleHover}
        handleLeave={this.handleLeave}
        handleScroll={this.handleScroll}
        handleChange={this.handleChange}
        handleSearchToggle={this.handleSearchToggle}
        handleMenuToggle={this.handleMenuToggle}
        handleMenuLinks={this.handleMenuLinks}
        handleMenuLinksTienda={this.handleMenuLinksTienda}
        handleMenuLogin={this.handleMenuLogin}
        handleClicks={this.handleClicks}
        showLoginModal={this.showLoginModal}
        showRegisterModal={this.showRegisterModal}
        cartInfo={this.props.orderStore.getCartInfo.data}
        userInfo={this.props.accountStore.person.data}
        validateUser={this.props.sessionStore.person.userGuest}
        handleSubmenuMobile={this.handleSubmenuMobile}
        handleSubmenuMobileTienda={this.handleSubmenuMobileTienda}
        handleLoginMobile={this.handleLoginMobile}
        logout={this.logout}
        layoutData={this.props.layoutdata}
        // addOnsList={this.state.addonsList}
      />
    );
  }
}

Header.propTypes = {
  orderStore: PropTypes.shape({
    getCart: PropTypes.func,
    getCartInfo: PropTypes.shape({
      data: PropTypes.shape({}),
    }),
  }).isRequired,
  accountStore: PropTypes.shape({
    dataPerson: PropTypes.func,
    person: PropTypes.shape({
      data: PropTypes.shape({
        userGuest: PropTypes.bool,
      }),
    }),
  }).isRequired,
  sessionStore: PropTypes.shape({
    personLogout: PropTypes.func,
    person: PropTypes.shape({
      userGuest: PropTypes.bool,
    }),
  }).isRequired,
  layoutdata: PropTypes.shape({}).isRequired,
};

export default inject('orderStore', 'accountStore', 'sessionStore')(observer(Header));
