import React, { Component } from 'react';

// UI
import UI from './CheckoutHeader.ui';

class HeaderCheckout extends Component {
  constructor(...props) {
    super(...props);
    this.state = {};
  }

  render() {
    return (
      <UI />
    );
  }
}

export default HeaderCheckout;
