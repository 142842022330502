import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Slider from 'react-slick';
import { useHookstate } from '@hookstate/core';
import { itemConfig } from '~/store/States/dataconfig';
import { CrossPrev, CrossNext } from '../../helpers/svg';

import { cuotaFormat, currencyFormat, imgFormat } from '../../helpers/formatter';
import helpers from '../../helpers/product';
import useStores from '../../store/StoreHook';


const CrossBox = ({ data, url }) => {
  const itemGlobalState = useHookstate(itemConfig);
  const { orderStore } = useStores();
  const [aceptaCuotas, setAceptaCuotas] = useState(false);
  const [formatoCuotas, setFormatoCuotas] = useState('');

  const prodItems = { items: data.items };
  helpers.ordenar(prodItems);
  const item = prodItems.items.find(it => it.available && it.buyable);

  useEffect(() => {
    async function init() {
      if (!orderStore.getCartConfig || !orderStore.getCartConfig.success) {
        await orderStore.getConfig();
      }
      if (orderStore.getCartConfig.success) {
        const min = orderStore.getCartConfig.data.data.precioMinimoCuotas || 0;
        setAceptaCuotas(item.pricing[process.env.CURRENCY].offer_price >= (min * 100));
        setFormatoCuotas(orderStore.getCartConfig.data.data.formatoCuotas || '');
      }
    }
    if (item) {
      init();
    }
  }, []);

  if (item) {
    const hasPromo = item.pricing[process.env.CURRENCY].offer_price !== item.pricing[process.env.CURRENCY].list_price;
    const atributesMap = helpers.convertAttributes(item.attributes);
    const cuotas = atributesMap['attr-conf-pagocuotas'];
    const textoCuota = atributesMap['attr-texto-cuotas'];
    let label = 'Precio del equipo';
    let labelContado = '';
    let labelCuota = '';
    let paymentLabel = hasPromo ? 'OFERTA' : atributesMap['attr-texto-precio'];
    let priceclass = 'cross--box--price';
    if (cuotas === 'SI' && textoCuota && aceptaCuotas) {
      label = atributesMap['attr-titulo-cuotas'] || `${label} financiado`;
      const precioCuota = parseFloat(textoCuota);
      if (Number.isNaN(precioCuota)) {
        // eslint-disable-next-line react/no-danger
        labelCuota = <p className="price-cuota" dangerouslySetInnerHTML={{ __html: textoCuota }} />;
      } else {
        const cuota = currencyFormat(item.pricing[process.env.CURRENCY].offer_price / precioCuota).split('.');
        labelCuota = cuotaFormat(cuota, textoCuota, formatoCuotas);
      }
      if (hasPromo) {
        labelContado = <p className="price-before">Precio de contado</p>;
      } else {
        paymentLabel = 'Precio de contado';
      }
      priceclass = 'cross--box--price cuota';
    }

    const currencyText = currencyFormat(item.pricing[process.env.CURRENCY].offer_price).split('.');
    const image = item.images && item.images[0] ? `${process.env.CDN_PATH}${item.images[0]}` : '/media/layout/noimage.jpg';
    const fullname = item.locales && item.locales[process.env.LOCALE] ? item.locales[process.env.LOCALE].name.replace(/<br>/, '\n').replace(/<br\/>/, '\n').replace(/\\n/, '\n').split('\n')
      .map((str, idx) => <span key={idx}>{str}</span>) : '';
    return (
      <article className="cross--box">
        <Link to={`/product/${item.locales[process.env.LOCALE].seo.name}/${url}`}>
          <h3>{fullname}</h3>
          <picture>
            <img src={imgFormat('210x180', image)} alt="crossImage" />
          </picture>
          <section className={priceclass}>
            <b>{label}</b>
            {labelCuota}
            {labelContado}
            <p className="price-before">{paymentLabel}</p>
            <p className="price">
              <span className="symbol">{process.env.CURRENCY_SYMBOL}</span>
              <span>{(currencyText ? currencyText[0] : '')}.</span>
              <span className="symbol">{(currencyText ? currencyText[1] : '')}</span>
            </p>
          </section>
          <b><span>{itemGlobalState.value && itemGlobalState.value.description_product_button}</span></b>
        </Link>
      </article>
    );
  }
  return null;
};

const UI = ({ products, addOnsList, productType }) => {
  const crossSettings = {
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000,
    dots: false,
    speed: 500,
    // centerMode: true,
    slidesToScroll: 1,
    slidesToShow: 4,
    pauseOnHover: true,
    // initialSlide: 1,
    arrows: true,
    prevArrow: <CrossPrev />,
    nextArrow: <CrossNext />,
    // responsive: [{
    //   breakpoint: 760,
    //   settings: {
    //     slidesToShow: 2,
    //   },
    // }, {
    //   breakpoint: 1024,
    //   settings: {
    //     slidesToShow: 4,
    //   },
    // }, {
    //   breakpoint: 1800,
    //   settings: {
    //     slidesToShow: 5,
    //   },
    // }],
  };
  let cross = '';
  if (products && products.length) {
    cross = (products || []).map((obj) => {
      const index = obj.locales[process.env.LOCALE].name;
      if (addOnsList.some(x => x.part_number === obj.part_number)) return null;
      return (<CrossBox data={obj} key={index} url={obj.part_number} />);
    });
  }
  let title;
  if (productType === 'PREPAGO') {
    title = 'Otros usuarios también vieron';
  } else {
    title = 'Además puede interesarte';
  }
  return (
    <section className="cross">
      <div className="cross--module">
        <h2>{title}</h2>
        <Slider {...crossSettings}>
          {cross}
        </Slider>
      </div>
    </section>
  );
};

UI.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})),
};

UI.defaultProps = {
  products: [],
};

CrossBox.propTypes = {
  data: PropTypes.shape({}),
  url: PropTypes.string,
};

CrossBox.defaultProps = {
  data: {},
  url: '',
};


export default UI;
