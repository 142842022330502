import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import { useHookstate } from '@hookstate/core';
import { itemConfig } from '~/store/States/dataconfig';

// Helpers
import { cuotaFormat, currencyFormat, imgFormat } from '../../helpers/formatter';
import analytics from '../../helpers/analytics';
import useStores from '../../store/StoreHook';

// TODO: export function es6
import helpers from '../../helpers/product';

// TODO: export switch color to productjs
import FacetsUtils from '../../helpers/facets_utils';

const ProductBoxTitle = ({ title }) => {
  const fullname = title.locales[process.env.LOCALE].name.replace(/<br>/, '\n').replace(/<br\/>/, '\n').replace(/\\n/, '\n').split('\n')
    .map((str, index) => <h3 key={`h3-${title.part_number}--${index}`}>{str}</h3>);
  if (isMobile) {
    return (
      <div className="product--box--title" style={{ marginTop: '1em' }} key={`div-title-mobile-${title.part_number}`}>
        {fullname}
      </div>
    );
  } else {
    return (
      <div className="product--box--title" key={`div-title-${title.part_number}`}>
        {fullname}
      </div>
    );
  }
};

const ProductBoxImg = ({ image }) => {
  let img = 'Producto sin imagen';
  if (image && image.images && image.images.length) {
    img = <img src={imgFormat('210x180', `${process.env.CDN_PATH}${image.images[0]}`)} alt="products" />;
  }
  return (
    <picture>
      {img}
    </picture>
  );
};

const ProductBoxPrice = ({ price }) => {
  const { orderStore } = useStores();
  const [aceptaCuotas, setAceptaCuotas] = useState(false);
  const [formatoCuotas, setFormatoCuotas] = useState('');
  useEffect(() => {
    async function init() {
      if (!orderStore.getCartConfig || !orderStore.getCartConfig.success) {
        await orderStore.getConfig();
      }
      if (orderStore.getCartConfig.success) {
        const min = orderStore.getCartConfig.data.data.precioMinimoCuotas || 0;
        setAceptaCuotas(price.pricing[process.env.CURRENCY].offer_price >= (min * 100));
        setFormatoCuotas(orderStore.getCartConfig.data.data.formatoCuotas || '');
      }
    }
    init();
  }, [price.pricing[process.env.CURRENCY].offer_price]);

  let curencyOffer;
  let curencyList;
  let hasPromo = price.pricing[process.env.CURRENCY].offer_price !== price.pricing[process.env.CURRENCY].list_price;
  const getPrice = price && price.pricing && price.pricing[process.env.CURRENCY];
  if (getPrice) {
    curencyOffer = currencyFormat(price.pricing[process.env.CURRENCY].offer_price).split('.');
    curencyList = currencyFormat(price.pricing[process.env.CURRENCY].list_price).split('.');
  }
  let label = '';
  let labelContado = '';
  let labelCuota = '';
  let paymentLabel = '';
  let priceclass = 'product--box--price';
  const atributesMap = helpers.convertAttributes(price.attributes);
  const tipoProducto = atributesMap['attr-conf-tipoproducto'];
  const pagoRecurrente = atributesMap['attr-conf-pagorecurrente'];
  const precioMensual = atributesMap['attr-conf-precio-mensual'];
  const cuotas = atributesMap['attr-conf-pagocuotas'];
  const textoCuota = atributesMap['attr-texto-cuotas'];
  if (pagoRecurrente === 'SI') {
    paymentLabel = 'Mensual';
  } else {
    paymentLabel = hasPromo ? 'OFERTA' : atributesMap['attr-texto-precio'];
  }
  if (tipoProducto === 'ADDONS') {
    label = 'Precio de la licencia';
    if (precioMensual) {
      const subtotal = parseFloat(precioMensual).toFixed(2).toString().replace('.', '');
      curencyOffer = currencyFormat(subtotal).split('.');
    }
  } else if (tipoProducto === 'PLAN') {
    label = 'Precio del plan';
  } else {
    label = 'Precio del equipo';
    if (cuotas === 'SI' && textoCuota && aceptaCuotas) {
      label = atributesMap['attr-titulo-cuotas'] || `${label} financiado`;
      const precioCuota = parseFloat(textoCuota);
      if (Number.isNaN(precioCuota)) {
        // eslint-disable-next-line react/no-danger
        labelCuota = <p className="price-cuota" dangerouslySetInnerHTML={{ __html: textoCuota }} />;
      } else {
        const cuota = currencyFormat(price.pricing[process.env.CURRENCY].offer_price / precioCuota).split('.');
        labelCuota = cuotaFormat(cuota, textoCuota, formatoCuotas);
      }
      if (hasPromo) {
        labelContado = <p className="price-before">Precio de contado</p>;
      } else {
        paymentLabel = 'Precio de contado';
      }
      hasPromo = false;
      priceclass = 'product--box--price cuota';
    }
  }
  const onlyOffer = (
    <div className={priceclass}>
      <p>{label}</p>
      {labelCuota}
      {labelContado}
      <p className="price-before">{paymentLabel}</p>
      <p className="price">
        <span className="symbol">{process.env.CURRENCY_SYMBOL}</span>
        {(curencyOffer ? curencyOffer[0] : [])}.
        <span className="symbol">{(curencyOffer ? curencyOffer[1] : [])}</span>
      </p>
    </div>
  );

  const listVsOffer = (
    <div className="product--box--price">
      <p>{label}</p>
      <p className="price-before">{paymentLabel}</p>
      <p className="price is-promo">
        <span className="symbol">{process.env.CURRENCY_SYMBOL}</span>
        {(curencyOffer ? curencyOffer[0] : [])}.
        <span className="symbol">{(curencyOffer ? curencyOffer[1] : [])}</span>
      </p>
      <del className="price del">
        <del className="symbol">{process.env.CURRENCY_SYMBOL}</del>
        {(curencyList ? curencyList[0] : [])}.
        <del className="symbol">{(curencyList ? curencyList[1] : [])}</del>
      </del>
    </div>
  );

  return (
    <Fragment>
      {hasPromo ? listVsOffer : onlyOffer}
    </Fragment>
  );
};

const ProductBoxColors = ({ colors }) => {
  const itemInfo = helpers.colorProduct(colors);
  const colorList = _.map(itemInfo, (color, key) => {
    const index = `color--${key}`;
    const colorKey = FacetsUtils.getColorsFilter(key);
    const available = color.available;
    let output = '';
    if (available) {
      output = (
        <li key={index}>
          <p><span className={`color-${colorKey}`} /></p>
        </li>
      );
    } else {
      output = '';
      // output = (
      //   <li key={index} className="disabled">
      //     <span className={`color-${colorKey}`} />
      //   </li>
      // );
    }
    return output;
  });

  return (
    <Fragment>
      <div className="color">
        <p>{colorList.length} {colorList.length > 1 ? 'colores' : 'color'}</p>
        <ul>
          {colorList}
        </ul>
      </div>
    </Fragment>
  );
};

// const ProductBoxPospago = () => (
//   <Link to="/" className="product--box-pospago">
//     <b>¡Ahorra!</b>
//     <span>Este equipo en plan pospago desde</span>
//     <b>Q895.00</b>
//   </Link>
// );

const ProductBoxUI = ({ props }) => {
  const itemGlobalState = useHookstate(itemConfig);
  helpers.ordenar(props);
  const item = props.items[0];
  const tipoProducto = helpers.tipo(item);
  const endpoint = tipoProducto === 'OTRO' ? '/productx/' : '/product/';
  const link = `${endpoint + item.locales[process.env.LOCALE].seo.name}/${props.part_number}`;
  const productClick = () => { analytics.productClick(props); };
  // console.log(props);
  if (!tipoProducto) return null;
  const tipoProd = tipoProducto === 'PREPAGO' || tipoProducto === 'OTRO';
  const className = tipoProd ? 'product--box' : 'product--box--plan';

  let boxColors;
  if (tipoProd) {
    boxColors = <ProductBoxColors colors={props} />;
  }

  return (
    <article className={className}>
      <Link to={link} onClick={productClick}>
        <ProductBoxTitle title={item} />
        <ProductBoxImg image={item} />
        <ProductBoxPrice price={item} />
        {boxColors}
        <b>
          <span>{itemGlobalState.value && itemGlobalState.value.description_product_button}</span>
        </b>
      </Link>
    </article>
  );
};

ProductBoxTitle.propTypes = {
  title: PropTypes.shape({}),
};

ProductBoxUI.propTypes = {
  props: PropTypes.shape({
    available: PropTypes.bool,
    buyable: PropTypes.bool,
  }),
  part_number: PropTypes.string,
};

ProductBoxTitle.defaultProps = {
  title: {},
};

ProductBoxUI.defaultProps = {
  props: {},
  part_number: '',
};

ProductBoxPrice.propTypes = {
  price: PropTypes.shape({}),
};

ProductBoxPrice.defaultProps = {
  price: {},
};

ProductBoxImg.propTypes = {
  image: PropTypes.shape({}),
};

ProductBoxImg.defaultProps = {
  image: {},
};

ProductBoxColors.propTypes = {
  colors: PropTypes.shape({}),
};

ProductBoxColors.defaultProps = {
  colors: {},
};

export default ProductBoxUI;
