import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import ReactImageMagnify from 'react-image-magnify';
import ReactSlick from 'react-slick';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { useHookstate } from '@hookstate/core';
import { itemConfig } from '~/store/States/dataconfig';
// Helpers
import { cuotaFormat, currencyFormat } from '../../helpers/formatter';
import helpers from '../../helpers/product';
import { iconQuestion } from '../../helpers/svg';
import useStores from '../../store/StoreHook';

// components
import ModalNotification from '../../components/ui/ModalNotification';

// Helper
// TODO: export switch color to productjs
import FacetsUtils from '../../helpers/facets_utils';

/*
  Product Main
*/

const ModalSwitchProduct = ({ printProductInfo, closePopup, deletePreviousCart, warnMessage }) => {
  let message;
  const fullname = printProductInfo.replace(/<br>/, ' ').replace(/<br\/>/, ' ').replace(/\\n/, ' ');
  if (warnMessage) {
    message = (
      <div className="cart--modal-switch_item">
        <p>{warnMessage}</p>
        <nav>
          <button type="button" onClick={closePopup}><span>Cerrar</span></button>
        </nav>
      </div>
    );
  } else {
    message = (
      <div className="cart--modal-switch_item">
        <p>¿Deseas sustituir el producto que ya tienes en carrito por el <b>{fullname}</b>?</p>
        <nav>
          <button type="button" onClick={closePopup}><span>No</span></button>
          <button type="button" onClick={deletePreviousCart}><span>Si</span></button>
        </nav>
        <span>Perdona la molestia pero por ahora sólo puedes comprar un producto a la vez.</span>
      </div>);
  }
  return (
    <ModalNotification>
      <header>
        <i>{iconQuestion}</i>
        <h4>Confirmación</h4>
      </header>
      {message}
    </ModalNotification>
  );
};

ModalSwitchProduct.propTypes = {
  printProductInfo: PropTypes.string,
  closePopup: PropTypes.func,
  deletePreviousCart: PropTypes.func,
};

ModalSwitchProduct.defaultProps = {
  printProductInfo: '',
  closePopup: () => {},
  deletePreviousCart: () => {},
};

const ProductPrice = ({ pricing, tipoplan, pagorecurrente, precioRecurrente, attrMap }) => {
  const { orderStore } = useStores();
  const [aceptaCuotas, setAceptaCuotas] = useState(false);
  const [formatoCuotas, setFormatoCuotas] = useState('');
  useEffect(() => {
    async function init() {
      if (!orderStore.getCartConfig || !orderStore.getCartConfig.success) {
        await orderStore.getConfig();
      }
      if (orderStore.getCartConfig.success) {
        const min = orderStore.getCartConfig.data.data.precioMinimoCuotas || 0;
        setAceptaCuotas(pricing[process.env.CURRENCY].offer_price >= (min * 100));
        setFormatoCuotas(orderStore.getCartConfig.data.data.formatoCuotas || '');
      }
    }
    init();
  }, []);

  let currencyOffer;
  let currencyList;
  let hasPromo;
  let labelContado = '';
  let labelCuota = '';
  let priceclass = '';
  const getPrice = Object.keys(pricing).length;
  const cuotas = attrMap['attr-conf-pagocuotas'];
  const textoCuota = attrMap['attr-texto-cuotas'];

  if (getPrice) {
    currencyOffer = currencyFormat(pricing[process.env.CURRENCY].offer_price).split('.');
    currencyList = currencyFormat(pricing[process.env.CURRENCY].list_price).split('.');
    hasPromo = pricing[process.env.CURRENCY].offer_price !== pricing[process.env.CURRENCY].list_price;
  }
  if (currencyOffer[0] === '0' && currencyList[0] === '0' && currencyOffer[1] === '00' && currencyList[1] === '00' && precioRecurrente) {
    let precioPaquete = precioRecurrente;
    if (!precioPaquete.includes('.')) {
      precioPaquete = `${precioPaquete}00`;
    }
    currencyOffer = currencyFormat(precioPaquete).split('.');
  }
  let priceLabel = '';
  if (tipoplan === 'PLAN') {
    priceLabel = 'Precio del plan';
  } else if (tipoplan === 'PREPAGO') {
    priceLabel = 'Precio del equipo';
  } else {
    priceLabel = 'Precio del producto';
  }
  if (cuotas === 'SI' && textoCuota && aceptaCuotas) {
    priceLabel = attrMap['attr-titulo-cuotas'] || `${priceLabel} financiado`;
    const precioCuota = parseFloat(textoCuota);
    if (Number.isNaN(precioCuota)) {
      // eslint-disable-next-line react/no-danger
      labelCuota = <p className="price-cuota" dangerouslySetInnerHTML={{ __html: textoCuota }} />;
    } else {
      const cuota = currencyFormat(pricing[process.env.CURRENCY].offer_price / precioCuota).split('.');
      labelCuota = cuotaFormat(cuota, textoCuota, formatoCuotas);
    }
    labelContado = <p className="price-before">Precio de contado</p>;
    priceclass = 'cuota';
  }
  let periodo = '';
  if (pagorecurrente === 'SI') {
    periodo = 'Mensual ';
  }
  const onlyOffer = (
    <div className={priceclass}>
      <p className="block">{priceLabel}</p>
      {labelCuota}
      {labelContado}
      <p className="price is-promo">
        <span style={{ paddingRight: '0.5em' }}>{periodo}</span>
        <span>{process.env.CURRENCY_SYMBOL}{(currencyOffer ? currencyOffer[0] : '')}.</span>
        <span className="symbol">{(currencyOffer ? currencyOffer[1] : '')}</span>
      </p>
    </div>
  );

  const listVsOffer = (
    <div className={priceclass}>
      <p className="block">{priceLabel}</p>
      {labelCuota}
      {labelContado}
      <p className="price is-promo">
        <span style={{ paddingRight: '0.5em' }}>{periodo}</span>
        <span> {process.env.CURRENCY_SYMBOL}{(currencyOffer ? currencyOffer[0] : '')}.</span>
        <span className="symbol">{(currencyOffer ? currencyOffer[1] : '')}</span>
      </p>
      <del className="price is-promo del">
        <del> {process.env.CURRENCY_SYMBOL}{(currencyList ? currencyList[0] : '')}.</del>
        <del className="symbol">{(currencyList ? currencyList[1] : '')}</del>
      </del>
    </div>
  );

  return (
    <Fragment>
      {hasPromo && (!pagorecurrente || pagorecurrente === 'NO') ? listVsOffer : onlyOffer}
    </Fragment>
  );
};

export const productConfirm = ({ title }) => (
  <div className="modal">
    <div className="modal--container">
      <div className="modal--bg" onClick={this.closeModals} />
      <section className="modal--products">
        {title}
      </section>
    </div>
  </div>
);

productConfirm.propTypes = {
  title: PropTypes.string.isRequired,
};

const ProductSize = (props) => {
  const sizes = helpers.sizeProduct(props.sizes);

  const sizeList = _.map(sizes[props.colorInit], (data, key) => {
    const index = `size--${key}`;
    return (
      <label key={index}>
        <input
          type="radio"
          name="product-size"
          defaultChecked={props.sizeInit === data.size}
          onClick={() => {
            props.doZoom();
            props.sizeSelected({
              size: data.size,
              pricing: data.pricing,
            });
          }}
        />
        <span>{data.size}</span>
      </label>
    );
  });

  return (
    <section className="product--size">
      <p>Elige la capacidad:</p>
      <div>
        {sizeList}
      </div>
    </section>
  );
};

const ProductColor = (props) => {
  const colors = helpers.colorProduct(props.color, props.size);
  const colorList = _.map(colors, (color, key) => {
    const index = `color--${key}`;
    const colorKey = FacetsUtils.getColorsFilter(key);
    const item = (
      <label key={index}>
        <input
          type="radio"
          name="product-color"
          onClick={() => {
            props.doZoom();
            props.colorSelected({
              color: key,
              size: color.size,
              images: color.images,
              pricing: color.pricing,
              name: color.locales[process.env.LOCALE].name,
              part_number: color.part_number,
              attributes: color.attributes,
              item: color.item,
            });
          }}
          checked={key === props.colorInit}
          readOnly
        />
        <i>
          <span className={`color-${colorKey}`} />
        </i>
      </label>
    );
    return (color.available && color.isAvailable !== 'unavailable') ? item : '';
  });

  return (
    <section className="product--color">
      <p>Colores:</p>
      <div>
        {colorList}
      </div>
    </section>
  );
};

const ProductPurchase = (props) => {
  console.debug('ProductPurchase: ', props);
  const attrMap = helpers.convertAttributes(props.attributes);
  const tipoProducto = attrMap['attr-conf-tipoproducto'];
  // eslint-disable-next-line no-unused-vars
  const purchaseEnabled = '';
  if (tipoProducto === 'PLAN') {
    // purchaseEnabled = props.isAvailable === 'available' ? (
    //   <a onClick={() => { props.validateCart((props.item && props.item.part_number ? props.item.part_number : null), (props.item && props.item.locales && props.item.locales[process.env.LOCALE] ? props.item.locales[process.env.LOCALE].name : null), 1, tipoProducto, props.itemInfo); }}>
    //     <span>Añadir</span>
    //   </a>
    // ) : (<p>No disponible por el momento</p>);
  } else if (tipoProducto === 'ADDONS') {
    const tipoplan = attrMap['attr-conf-tipoplan'];
    // eslint-disable-next-line no-unused-vars
    let descripcion = 'No disponible por el momento';
    let disponible = props.isAvailable === 'available';
    if (disponible) {
      const plan = props.cartInfo && props.cartInfo.items && props.cartInfo.items.find(it => helpers.tipo(it.product) === 'PLAN');
      if (plan) {
        const planAttr = helpers.convertAttributes(plan.product.items[0].attributes);
        if ((planAttr[`attr-paquete-${tipoplan}`] || '').toLowerCase() === 'si') {
          descripcion = 'Ya incluido en tu plan';
          disponible = false;
        }
      } else {
        descripcion = 'Disponible al agregar un plan';
        disponible = false;
      }
    }
    // purchaseEnabled = disponible ? (
    //   <a onClick={() => { props.validateCart((props.item && props.item.part_number ? props.item.part_number : null), (props.item && props.item.locales && props.item.locales[process.env.LOCALE] ? props.item.locales[process.env.LOCALE].name : null), 1, tipoProducto, props.itemInfo); }}>
    //     <span>Añadir</span>
    //   </a>
    // ) : (<p>{descripcion}</p>);
  } else {
    const colors = helpers.itemByColorAndSize(props.itemInfo);
    // eslint-disable-next-line no-unused-vars
    const sizeProduct = _.each(colors[props.colorInit], data => data.size);
    // purchaseEnabled = props.isAvailable === 'available' ? (
    //   <a onClick={() => { props.validateCart((sizeProduct[props.sizeInit] ? sizeProduct[props.sizeInit].part_number : null), (props.item && props.item.locales && props.item.locales[process.env.LOCALE] ? props.item.locales[process.env.LOCALE].name : null), 1, tipoProducto, props.itemInfo); }}>
    //     <span>Añadir</span>
    //   </a>
    // ) : (<p>No disponible por el momento</p>);
  }
  const pagorecurrente = attrMap['attr-conf-pagorecurrente'];
  const precioRecurrente = attrMap['attr-conf-precio-mensual'];
  const classname = tipoProducto === 'PLAN' ? 'product--purchase margin-less' : 'product--purchase';
  return (
    <section className={classname}>
      <section>
        <ProductPrice pricing={props.pricing} tipoplan={tipoProducto} pagorecurrente={pagorecurrente} precioRecurrente={precioRecurrente} attrMap={attrMap} />
      </section>
      {/* {purchaseEnabled} */}
    </section>
  );
};

const ProductHeader = (props) => {
  let currencyOffer;
  let currencyList;
  let hasPromo;
  const getPrice = Object.keys(props.pricing).length;

  if (getPrice) {
    currencyOffer = currencyFormat(props.pricing[process.env.CURRENCY].offer_price);
    currencyList = currencyFormat(props.pricing[process.env.CURRENCY].list_price);
    hasPromo = props.pricing[process.env.CURRENCY].offer_price !== props.pricing[process.env.CURRENCY].list_price;
  }
  const promo = hasPromo && currencyOffer ? ` Precio oferta: ${process.env.CURRENCY_SYMBOL}${currencyOffer}` : '';
  const fullname = props.name.replace(/<br>/, '\n').replace(/<br\/>/, '\n').replace(/\\n/, '\n').split('\n')
    .map((str, idx) => <h2 key={`productheader-title-${idx}`}><strong>{str}</strong></h2>);
  const name = props.name.replace(/\\n/g, '');

  return (
    <header className="product--header">
      {fullname}
      {(props.marca.length > 0 ? <p>props.marca[0].value</p> : '')}
      <Helmet title={`${name} | Catálogo Claro ${process.env.COUNTRY}`}>
        <meta name="description" content={`${((props.marca).length ? `${props.marca[0].value} ` : '')}${name} ${props.sizeInit} Precio normal: ${process.env.CURRENCY_SYMBOL}${(currencyList || '')}${promo} !Compra ahora! ¡Envío Gratis!`} />
        <meta name="keywords" content={props.itemInfo.locales[process.env.LOCALE].seo.keywords} />
      </Helmet>
    </header>
  );
};

const ProductImage = (props) => {
  const alt = `${((props.marca).length ? `${props.marca[0].value} ` : '')}${props.name} ${props.sizeInit}`;
  const images360 = (props.images || []).map((image, i) => {
    if (i > 0) return false;
    const index = i;
    const url = `${process.env.CDN_PATH}${image.replace('.jpg', '_312x400.jpg')}`;
    return (<img style={{ paddingLeft: '2.25em', width: '312px' }} src={url} alt={alt} key={`img-${index}`} />);
  });
  const images = (props.images || []).sort((a, b) => {
    const valA = +a.substring(a.lastIndexOf('_') + 1, a.lastIndexOf('.jpg'));
    const valB = +b.substring(b.lastIndexOf('_') + 1, b.lastIndexOf('.jpg'));
    return valA - valB;
  });
  const imagesZoom = images.map((image, i) => {
    const index = i;
    const small = `${process.env.CDN_PATH}${image.replace('.jpg', '_312x400.jpg')}`;
    const large = `${process.env.CDN_PATH}${image.replace('.jpg', '_1000x1000.jpg')}`;
    return (
      <ReactImageMagnify
        {...
          {
            smallImage: {
              alt,
              isFluidWidth: true,
              src: small,
            },
            largeImage: {
              src: large,
              width: 1000,
              height: 1000,
            },
            enlargedImageContainerDimensions: {
              width: 400,
              height: 400,
            },
            lensStyle: { backgroundColor: 'rgba(0,0,0,.4)' },
            hoverDelayInMs: 0,
            hoverOffDelayInMs: 0,
            enlargedImagePortalId: 'ui--zoom-container',
          }
        }
        key={`zoom-${index}`}
      />
    );
  });
  const dots = images.map(image => (`${process.env.CDN_PATH}${image.replace('.jpg', '_90x90.jpg')}`));
  let imageIndex = 0;
  const imageMax = Math.floor((images.length - 1) / 4) * 4;
  const dotsPrev = () => {
    if (imageIndex > 0) {
      imageIndex -= 4;
      document.querySelectorAll('.slick-dots li').forEach((el) => {
        el.style.transform = `translateX(-${imageIndex}00%)`;
      });
    }
  };
  const dotsNext = () => {
    if (imageIndex < imageMax) {
      imageIndex += 4;
      document.querySelectorAll('.slick-dots li').forEach((el) => {
        el.style.transform = `translateX(-${imageIndex}00%)`;
      });
    }
  };
  const ArrowPrev = () => <a className="slick-arrow slick-prev fa fa-angle-left" onClick={dotsPrev} />;
  const ArrowNext = () => <a className="slick-arrow slick-next fa fa-angle-right" onClick={dotsNext} />;
  const hasArrows = props.images.length > 4;
  const carousel = {
    customPaging(i) {
      return <a><img src={`${dots[i]}`} alt={`${alt} ${i + 1}`} /></a>;
    },
    dots: true,
    infinite: true,
    speed: 500,
    arrows: hasArrows,
    fade: true,
    prevArrow: <ArrowPrev />,
    nextArrow: <ArrowNext />,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const isZoom = (
    <ReactSlick {...carousel}>
      {imagesZoom}
    </ReactSlick>
  );
  const tipoProducto = props.tipoProducto;
  if (tipoProducto[0].value === 'PREPAGO') {
    return (
      <div className="product--image">
        {/* type */}
        {/* gallery ? isZoom : is360 */}
        {isZoom}
      </div>
    );
  } else {
    return (
      <div className="product--image" style={{ minHeight: '0px', width: '40%' }}>
        {/* type */}
        { images360 }
      </div>
    );
  }
};

const UI = (props) => {
  const itemGlobalState = useHookstate(itemConfig);
  const propsProductInfo = (props && props.productInfo && props.productInfo.data ? props.productInfo.data : []);

  let colors = '';
  if (props.color) {
    colors = propsProductInfo.map((values, i) => {
      const index = `color--${i}`;
      return (
        <ProductColor
          key={index}
          color={values}
          colorInit={props.color}
          colorSelected={props.colorSelected}
          doZoom={props.doZoom}
          size={props.size}
        />
      );
    });
  }

  const sizes = propsProductInfo.map((values, i) => {
    const tipoProducto = _.filter(values.attributes, { identifier: 'attr-conf-tipoproducto' });
    console.debug(tipoProducto);
    if (tipoProducto[0].value === 'PREPAGO') {
      const index = `size--${i}`;
      return (
        <ProductSize
          key={index}
          sizes={values}
          sizeInit={props.size}
          colorInit={props.color}
          sizeSelected={props.sizeSelected}
          doZoom={props.doZoom}
        />
      );
    }
    return false;
  });

  const addToCart = propsProductInfo.map((values, i) => {
    const index = `addCart--${i}`;
    return (
      <ProductPurchase
        key={index}
        itemInfo={values}
        sizeInit={props.size}
        colorInit={props.color}
        addToCart={props.addToCart}
        validateCart={props.validateCart}
        cartInfo={props.cartInfo}
        pricing={props.pricing}
        attributes={props.attributes}
        item={props.item}
        isAvailable={props.isAvailable}
      />
    );
  });

  const productHeader = propsProductInfo.map((values, i) => {
    const index = `productHeader--${i}`;
    const marca = _.filter(values.attributes, { name: 'filt-marca' });
    const nameProduct = props.productName !== '' ? props.productName : values.locales[process.env.LOCALE].name;

    return (
      <ProductHeader
        key={index}
        itemInfo={values}
        marca={marca}
        name={nameProduct}
        pricing={props.pricing}
        sizeInit={props.size}
      />
    );
  });

  const productImage = propsProductInfo.map((values, i) => {
    const index = `productImage--${i}`;
    const marca = _.filter(values.attributes, { name: 'filt-marca' });
    const nameProduct = props.productName !== '' ? props.productName : values.locales[process.env.LOCALE].name;
    const tipoProducto = _.filter(values.attributes, { identifier: 'attr-conf-tipoproducto' });

    return (
      <ProductImage
        key={index}
        itemInfo={values}
        images={props.images}
        doZoom={props.doZoom}
        do360={props.do360}
        gallery={props.gallery}
        marca={marca}
        name={nameProduct}
        sizeInit={props.size}
        tipoProducto={tipoProducto}
      />
    );
  });

  const productDescription = propsProductInfo.map((values) => {
    const tipoProducto = _.filter(values.attributes, { identifier: 'attr-conf-tipoproducto' });
    console.debug(tipoProducto);
    console.debug(values);
    if (tipoProducto[0].value === 'PLAN') {
      return (
        <div className="product--description-line">
          <p className="block">{props.item.locales[process.env.LOCALE].long_description}</p>
        </div>
      );
    }
    return false;
  });

  const roaming = propsProductInfo.map((values) => {
    const tipoProducto = _.filter(values.attributes, { identifier: 'attr-conf-tipoproducto' });
    const roamingAttr = _.filter(values.attributes, { identifier: 'attr-chars-main-roaming' });
    console.debug(tipoProducto);
    console.debug(roamingAttr);
    console.debug(values.attributes);
    if (tipoProducto[0].value === 'PLAN' && roamingAttr[0]) {
      return (
        <div className="product--description-line">
          <p className="block">{roamingAttr[0].value}</p>
        </div>
      );
    }
    return false;
  });

  const redes = propsProductInfo.map((values) => {
    const tipoProducto = _.filter(values.attributes, { identifier: 'attr-conf-tipoproducto' });
    const musica = _.filter(values.attributes, { identifier: 'attr-paquete-musica' });
    const video = _.filter(values.attributes, { identifier: 'attr-paquete-video' });
    let musicaLabel = '';
    let videoLabel = '';

    if (tipoProducto[0].value === 'PLAN') {
      if (musica[0].value === 'SI') {
        musicaLabel = 'Claro Música incluído';
      }
      if (video[0].value === 'SI') {
        videoLabel = 'Claro Video incluído';
      }
      return (
        <div className="product--description-line">
          <p className="block">{musicaLabel}</p>
          <p className="block">{videoLabel}</p>
        </div>
      );
    }
    return false;
  });

  const includedSocial = propsProductInfo.map((values) => {
    const tipoProducto = _.filter(values.attributes, { identifier: 'attr-conf-tipoproducto' });
    const redesAttr = values.attributes.filter(red => red.identifier.startsWith('attr-redsocial'));
    let redesCount = 0;
    if (tipoProducto[0].value === 'PLAN' && redesAttr.length > 0) {
      const items2 = (redesAttr || []).map((v) => {
        if (v.value !== 'SI' && v.value !== 'si') return false;
        redesCount += 1;
        return <img key={redesCount} src={`${process.env.CDN_PATH}/espots/socialmedia/${v.identifier.replace(/-/g, '_')}.jpg`} alt={v.name} style={{ maxWidth: '40px', display: 'inline-flex', paddingRight: '0.5em' }} />;
      });
      const redesAttrInclude = values.attributes.filter(red => red.identifier.startsWith('attr-chars-main-redsocail-descripcion'));
      const showTitle = redesCount > 0 ? 'Redes incluídas' : '';
      return (
        <div>
          <p className="block"><h3 className="subtitle">{showTitle}</h3></p>
          <section>
            {items2}
          </section>
          <p style={{ color: 'gray', paddingTop: '1em' }}>{redesAttrInclude[0].value}</p>
        </div>
      );
    }
    return false;
  });

  const tipoProducto2 = _.filter(propsProductInfo[0].attributes, { identifier: 'attr-conf-tipoproducto' });

  const card = tipoProducto2[0].value === 'PREPAGO' ? (<p className="product--card">{props.descripcionPrepago}</p>) : '';
  const title = props.item ? props.item.locales[process.env.LOCALE].name : 'Claro';
  const shareURL = window.location.href;
  let sharing = '';
  if (tipoProducto2[0].value === 'PREPAGO') {
    sharing = (
      <div className="product--share">
        <FacebookShareButton url={shareURL} quote={`Claro | ${title}`}><a className="facebook">Compartir</a></FacebookShareButton>
        <TwitterShareButton url={shareURL} title={`Claro | ${title}`} via="claropanama"><a className="twitter">Tweet</a></TwitterShareButton>
      </div>
    );
  }
  let planIncluded = '';
  if (tipoProducto2[0].value === 'PLAN') {
    planIncluded = (
      <div className="PlanIncluded"><h2>Tu Plan Incluye</h2></div>
    );
  }
  let shortDescriptionP = '';
  if (tipoProducto2[0].value === 'ADDONS') {
    const shortDescription = _.filter(props.item.attributes, { identifier: 'attr-short-description' });
    shortDescriptionP = (<p className="block" style={{ color: 'gray' }} >{shortDescription[0].value}</p>);
  }
  return (
    <section className="product--main">
      {productImage}
      <div className="product--info">
        {productHeader}
        {addToCart}
        {shortDescriptionP}
        {colors}
        {sizes}
        {productDescription}
        {roaming}
        {redes}
        {card}
        <div className="product-message-item">
          {
            itemGlobalState.value && (
              <span
                dangerouslySetInnerHTML={{
                  __html: itemGlobalState.value.description_product_message,
                }}
              />
            )
          }
        </div>
        {sharing}
        {includedSocial}
        <div id="ui--zoom-container" />
      </div>
      {
        props.modals
        && props.modals.PPopup
        && (
        <ModalSwitchProduct
          printProductInfo={props.printProductInfo}
          closePopup={props.closePopup}
          deletePreviousCart={props.deletePreviousCart}
          warnMessage={props.warnMessage}
        />
        )
      }
      {planIncluded}
    </section>
  );
};

UI.propTypes = {
  productInfo: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  cartInfo: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  color: PropTypes.string,
  size: PropTypes.string,
  productName: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  pricing: PropTypes.shape({}),
  colorSelected: PropTypes.func,
  sizeSelected: PropTypes.func,
  addToCart: PropTypes.func,
  validateCart: PropTypes.func,
  doZoom: PropTypes.func,
  do360: PropTypes.func,
  gallery: PropTypes.string,
  modals: PropTypes.shape({
    PPopup: PropTypes.bool,
  }),
  printProductInfo: PropTypes.string,
  closePopup: PropTypes.func,
  deletePreviousCart: PropTypes.func,
  isAvailable: PropTypes.string.isRequired,
};

UI.defaultProps = {
  productInfo: {
    data: [],
  },
  cartInfo: {
    items: [],
  },
  color: '',
  images: [],
  size: '',
  pricing: {},
  productName: PropTypes.string,
  gallery: PropTypes.string,
  colorSelected: () => {},
  sizeSelected: () => {},
  addToCart: () => {},
  validateCart: () => {},
  doZoom: () => { },
  do360: () => { },
  modals: () => { },
  printProductInfo: '',
  closePopup: () => { },
  deletePreviousCart: () => { },
};

ProductImage.propTypes = {
  doZoom: PropTypes.func.isRequired,
  do360: PropTypes.func.isRequired,
  gallery: PropTypes.string.isRequired,
  itemInfo: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      images: PropTypes.arrayOf(PropTypes.string),
    })),
    locales: PropTypes.shape({
      [process.env.LOCALE]: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }),
  images: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  marca: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
  })),
  sizeInit: PropTypes.string,
};

ProductImage.defaultProps = {
  itemInfo: {
    items: [{
      images: [],
    }],
    locales: [{}],
  },
  images: [],
  name: '',
  marca: [],
  sizeInit: '',
};

ProductColor.propTypes = {
  color: PropTypes.shape({}),
  colorInit: PropTypes.string,
  colorSelected: PropTypes.func,
  doZoom: PropTypes.func.isRequired,
};

ProductColor.defaultProps = {
  color: {},
  colorInit: '',
  colorSelected: {},
};

ProductSize.propTypes = {
  sizes: PropTypes.shape({}),
  sizeInit: PropTypes.string,
  colorInit: PropTypes.string,
  sizeSelected: PropTypes.func,
  doZoom: PropTypes.func.isRequired,
};

ProductSize.defaultProps = {
  sizes: {},
  sizeInit: '',
  colorInit: '',
  sizeSelected: {},
};

ProductPurchase.propTypes = {
  itemInfo: PropTypes.shape({
    locales: PropTypes.shape({
      [process.env.LOCALE]: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }),
  // eslint-disable-next-line react/no-unused-prop-types
  sizeInit: PropTypes.string,
  colorInit: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  validateCart: PropTypes.func,
  pricing: PropTypes.shape({}),
  cartInfo: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  isAvailable: PropTypes.string.isRequired,
};

ProductPurchase.defaultProps = {
  itemInfo: {},
  sizeInit: '',
  colorInit: '',
  pricing: {},
  validateCart: () => {},
  cartInfo: {},
};

ProductHeader.propTypes = {
  name: PropTypes.string,
  marca: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
  })),
  pricing: PropTypes.shape({}),
  sizeInit: PropTypes.string,
};

ProductHeader.defaultProps = {
  name: '',
  marca: [],
  pricing: {},
  sizeInit: '',
};

ProductPrice.propTypes = {
  pricing: PropTypes.shape({}),
};

ProductPrice.defaultProps = {
  pricing: {},
};

export default UI;
