import React from 'react';
import PropTypes from 'prop-types';

// Helpers
import { emailChars } from '../../../helpers/char-restriction';

const UI = ({ internalData, handleChange, handleSubmit, modalType, accountStore }) => (
  <section className="modal--form">
    <header>
      <h2>Recuperar Contraseña</h2>
      <p>Ingresa un correo que puedas comprobar ahora</p>
    </header>
    <form name="resetPassword" onSubmit={handleSubmit}>
      <p>Correo electrónico:</p>
      <label htmlFor="email">
        <input
          id="email"
          name="email"
          type="text"
          placeholder="ejemplo@email.com"
          onKeyPress={emailChars}
          value={internalData.email}
          onChange={(e) => { handleChange(e, 'internalData.email'); }}
        />
      </label>
      <button><span>Siguiente</span></button>
      <div>
        <a onClick={() => { modalType(null); }}>Regresar</a>
      </div>
    </form>
    {accountStore && accountStore.resetPass && accountStore.resetPass.error ? <p className="ui--notification">Servicio no disponible.</p> : ''}
    {accountStore && accountStore.resetPass && accountStore.resetPass.data ? <p className="ui--notification">Ese usuario no existe o se ha intentado demasiadas veces a las permitidas.</p> : ''}
  </section>
);

UI.propTypes = {
  internalData: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalType: PropTypes.func.isRequired,
};

export default UI;
