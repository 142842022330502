import React from 'react';
import PropTypes from 'prop-types';

const LoginBtn = props => (
  <div className="ui--login-btn ui-log-btn active ie-fix">
    <a className="ui--btn-login" onClick={props.login}>Iniciar Sesión</a>
    <a className="ui--btn-register" onClick={props.register}>Regístrate</a>
  </div>
);

export default LoginBtn;

LoginBtn.propTypes = {
  login: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  // register: PropTypes.func.isRequired,
};

