import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import he from 'he';

// utilities
import Legal from './Legal';
// import FooterImage from './FooterImage';
import { logo, panama } from '../../helpers/svg';

// import NotificationUI from '../../containers/Account/Notification/Notification.ui';

// const FooterChat = () => (
//   <div className="footer--chat">
//     <a>
//       <span>{iconChat}</span>
//       <label>Iniciar Chat</label>
//     </a>
//   </div>
// );

const FooterBreadcrumbBox = ({ location }) => {
  // console.log('breadcrumbBox', location);
  const name = location.pathname.replace(/(\/)/g, '')
    .replace(/[-_]/g, ' ')
    .replace(/(product.*)/, 'Equipos')
    .replace(/(cart.*)/, 'Carrito')
    .replace('terms', 'Términos y condiciones')
    .replace('search', 'Búsqueda: ')
    .replace('renewals', 'Renovaciones')
    .replace(/(account)/, 'Mi Cuenta')
    .replace(/(.*orders.*)/, 'Mis Órdenes')
    .replace(/(.*address.*)/, 'Mis Direcciones')
    .replace(/(.*profile.*)/, 'Mi Perfil');
  return (
    <div className="footer--breadcrumb">
      <nav>
        <a href="https://www.claro.com.pa/personas/">Personas</a>
        <Link to="/">Catálogo</Link>
        { location.pathname === '/' ? null : <a style={{ textTransform: 'capitalize', cursor: 'default' }}>{name}</a> }
      </nav>
    </div>
  );
};
const FooterBreadcrumb = withRouter(FooterBreadcrumbBox);


const FooterLinks = ({ layoutData }) => {
  // console.log('layoutData:::', layoutData);
  const getData = layoutData && layoutData.SeccionesFooter && layoutData.SeccionesFooter.length > 0;
  const list = [];
  if (getData) {
    const data = layoutData.SeccionesFooter[0].Hijos;
    for (let i = 0; i < data.length; i += 1) {
      const item = data[i];
      const title = item.Descripcion;
      const ulKey = item.Unico;
      const links = [];
      const linkList = item.Hijos;
      for (let x = 0; x < linkList.length; x += 1) {
        const link = linkList[x];
        const label = link.Descripcion;
        const url = link.URL;
        const linkKey = link.Unico;
        const element = (<li key={linkKey}><a href={he.decode(url)}>{he.decode(label)}</a></li>);
        links.push(element);
      }
      const output = (
        <ul key={ulKey} className={`footer-${he.decode(title).trim().replace(/ /g, '-')}`}>
          <li><b>{he.decode(title)}</b></li>
          {links}
        </ul>
      );
      list.push(output);
    }
  }
  return (
    <div className="footer--links">
      <section>
        {list}
        <ul />
        <div className="footer--social">
          <p>Redes sociales</p>
          <a href="https://www.facebook.com/claropanama/" target="_blank" rel="noopener noreferrer nofollow"><span className="fa-facebook-square" /></a>
          <a href="https://twitter.com/claropanama" target="_blank" rel="noopener noreferrer nofollow"><span className="fa-twitter-square" /></a>
          <a href="https://www.instagram.com/claropanama/" target="_blank" rel="noopener noreferrer nofollow"><span className="fa-instagram" /></a>
        </div>
      </section>
    </div>
  );
};

const FooterEnd = ({ countryHandler, layoutData }) => {
  // console.log('layoutData------ ', layoutData);
  const getData = layoutData && layoutData.Menus && layoutData.Menus[1] && layoutData.Menus[1].Hijos;
  const legal = [];
  if (getData) {
    const data = layoutData.Menus[1].Hijos;
    for (let i = 0; i < data.length; i += 1) {
      const item = data[i];
      const title = item.Descripcion;
      const href = item.URL;
      const key = item.Unico;
      const output = (
        <a href={he.decode(href)} target="_blank" rel="noopener noreferrer nofollow" key={key}>{he.decode(title)}</a>
      );
      legal.push(output);
    }
  }
  return (
    <div className="footer--end">
      <section>
        <p><span>{logo}</span><span>Todos los derechos reservados, Claro {new Date().getFullYear()} ©</span></p>
        <div className="footer--country">
          <span>{panama}</span>
          <label>
            <select id="footer-country" onChange={countryHandler}>
              <option value="/">Panamá</option>
              <option value="https://www.claro.com.ar/personas/">Argentina</option>
              <option value="https://www.claro.com.br/">Brasil</option>
              <option value="https://www.clarochile.cl/personas/">Chile</option>
              <option value="http://www.claro.com.co/personas/">Colombia</option>
              <option value="https://www.claro.cr/personas/">Costa Rica</option>
              <option value="https://www.claro.com.ec/personas/">Ecuador</option>
              <option value="http://www.claro.com.gt/personas/">Guatemala</option>
              <option value="https://www.claro.com.hn/personas/">Honduras</option>
              <option value="http://www.claro.com.ni/personas/">Nicaragua</option>
              <option value="https://www.claro.com.sv/personas/">El Salvador</option>
              <option value="http://www.claro.com.py/personas/">Paraguay</option>
              <option value="http://www.claro.com.pe/personas/">Perú</option>
              <option value="https://www.claropr.com/personas/">Puerto Rico</option>
              <option value="https://www.claro.com.do/personas/">República Dominicana</option>
              <option value="https://www.claro.com.uy/personas/">Uruguay</option>
            </select>
          </label>
        </div>
        <nav>
          {legal}
        </nav>
      </section>
    </div>
  );
};

const Footer = props => (
  <footer className="footer" role="presentation" onClick={props.closeMenus}>
    {/* { props.routerPath.match(/\/cart/) ? null : <FooterImage /> } */}
    <Legal />
    <FooterBreadcrumb />
    <FooterLinks layoutData={props.layoutData} />
    <FooterEnd countryHandler={props.handleCountry} layoutData={props.layoutData} />
    {/* <FooterChat /> */}
    {/* <NotificationUI /> */}
  </footer>
);

Footer.propTypes = {
  closeMenus: PropTypes.func.isRequired,
  handleCountry: PropTypes.func.isRequired,
  layoutData: PropTypes.shape({}).isRequired,
};

FooterEnd.propTypes = {
  countryHandler: PropTypes.func.isRequired,
  layoutData: PropTypes.shape({}).isRequired,
};

FooterLinks.propTypes = {
  layoutData: PropTypes.shape({}).isRequired,
};

FooterBreadcrumbBox.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Footer;
