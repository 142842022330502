import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { observer, inject } from 'mobx-react';

import { addonsList } from './../../store/Addons/Addons';

// UI
import UI from './Facets.ui';


class Facets extends Component {
  constructor(...props) {
    super(...props);
    this.state = {
      listFacets: [],
      listFacetsUpdated: [],
      listFacetsToSearch: [],
      getCategoriesInfo: [],
      categoryId: '',
    };
  }

  componentDidMount() {
    // this.componentDidUpdate();
  }

  componentDidUpdate() {
    if (this.props.categoryId && this.state.categoryId !== this.props.categoryId) {
      this.state.categoryId = this.props.categoryId;
      this.facetsView();
    }
  }

  onFacetChange = (e, quantity, facetId, facetValue) => {
    const idCategory = (this.state.categoryId !== undefined && this.state.categoryId !== '') ? this.state.categoryId : `${process.env.CATEGORY_ID}`;
    const facetsToSearch = this.state.listFacetsToSearch;
    let existFacetIdInPetition = {};
    if (facetsToSearch.length) {
      // two o more facertId
      facetsToSearch.map((facetGroup, index) => {
        if (facetGroup.facetId === facetId) {
          existFacetIdInPetition = { exist: true, index };
        }
        return true;
      });
      if (Object.keys(existFacetIdInPetition).length === 0) {
        // concatenate new facertId to search
        facetsToSearch.push({
          facetValue,
          facetId,
          quantity,
        });
        document.getElementById(facetId).checked = true;
      } else {
        // if one facertId exist it will be removed
        facetsToSearch.splice(existFacetIdInPetition.index, 1);
        document.getElementById(facetId).checked = false;
      }
      this.setState({ listFacetsToSearch: facetsToSearch });
    } else {
      // new search facertId
      facetsToSearch.push({
        facetValue,
        facetId,
        quantity,
      });
      this.setState({ listFacetsToSearch: facetsToSearch });
      document.getElementById(facetId).checked = true;
    }
    const json = {
      facets: facetsToSearch,
    };

    if (json.facets.length) this.facetsUpdateResponse(idCategory, json);
    else this.resetFilters(this.props.categorySlug, this.props.categoryName, this.props.categoryId);
    window.scrollTo(0, 0);
  }

  getCategoryId() {
    if (this.state.categoryId !== undefined && this.state.categoryId !== '') {
      this.facetsView();
    } else {
      this.props.navigationStore.getCategories().then((res) => {
        let result;
        if (res.data !== undefined && res.data.data) {
          result = res.data.data.filter(category => category.external_referrer === 'prepago/celulares');
        }
        if (result !== undefined && result[0] !== undefined && (result[0].external_referer !== undefined && result[0].external_referer !== '')) {
          this.setState({ categoryId: result[0].external_referer });
        } else {
          this.setState({ categoryId: `${process.env.CATEGORY_ID}` });
        }
        this.facetsView();
      });
    }
  }

  facetsView() {
    this.props.facets.allFacets(this.state.categoryId).then((res) => {
      this.setState({ listFacets: res.data, listFacetsUpdated: res.data });
    });
  }

  resetFilters = (categorySlug, categoryName, categoryId) => {
    this.getCategoryId();
    this.props.resetCatalog(categorySlug, categoryName, categoryId);
    this.state.listFacetsToSearch = [];
    $('input[type=checkbox]').prop('checked', false);
  }

  // ERROR: getting undefined from res.data on Café color attr
  async facetsUpdateResponse(idCategory, json) {
    let addons;
    await addonsList().then((res) => {
      addons = res.data[0].filter(x => x.enabled === true && x.show_home === false);
    });
    this.props.facets.facetsUpdate(idCategory, json, addons).then((res) => {
      this.setState({ listFacetsUpdated: res.data });
      this.props.productsFilter(res.items);
      return res;
    });
  }

  render() {
    return (
      <UI
        listFacets={this.state.listFacets}
        listFacetsUpdated={this.state.listFacetsUpdated}
        slideList={this.slideList}
        resetFilters={this.resetFilters}
        onFacetChange={this.onFacetChange}
        categoryId={this.props.categoryId}
        categorySlug={this.props.categorySlug}
        categoryName={this.props.categoryName}
        orderStore={this.props.orderStore}
      />
    );
  }
}

Facets.propTypes = {
  facets: PropTypes.shape({
    allFacets: PropTypes.func,
    facetsUpdate: PropTypes.func,
  }),
  productsFilter: PropTypes.func,
  resetCatalog: PropTypes.func.isRequired,
  navigationStore: PropTypes.shape({
    getCategories: PropTypes.func,
  }).isRequired,
};

Facets.defaultProps = {
  productsFilter: () => {},
  facets: {},
};

export default inject('facets', 'navigationStore', 'orderStore')(observer(Facets));
