import React from 'react';
import { Helmet } from 'react-helmet';
import { logo } from '../../helpers/svg';

const Error403 = () => (
  <div className="page--error">
    <Helmet title={`Error 403 | Catálogo Claro ${process.env.COUNTRY}`} />
    <div>
      <span>{logo}</span>
    </div>
    <section>
      <div>
        <b>403.</b> <p>No autorizado</p>
      </div>
      <h2>No se realizó la acción</h2>
      <p>
        Es posible que tu sesión de usuario haya caducado, o no tengas permiso
        para ejecutar la acción solicitada.
      </p>
      <a href="/">Ir a la página principal</a>
    </section>
    <aside>
      <img src="/media/layout/error500.png" alt="Error 403" />
    </aside>
  </div>
);

export default Error403;
