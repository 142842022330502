import { observable, action } from 'mobx';
import Cookies from 'universal-cookie';

import request from './../../bin/httpRequest';
import analytics from '../../helpers/analytics';

const cookies = new Cookies();

export const guestSession = () => {
  const options = {
    method: 'POST',
    url: `${process.env.API}${process.env.MERCHANT}/guestsession`,
  };

  return request.genericHandler(options, null).then((res) => {
    let callback = { action: 'guestSession', success: false };
    if (!res.error) {
      callback = Object.assign({}, callback, { data: res.data, success: true });
    } else {
      callback = Object.assign({}, callback, { error: res.error });
      // TODO: eliminar cookie y asignar nueva.
    }
    return callback;
  });
};

class SessionStore {
  @observable userSession = observable.map();
  @observable userGuest = true;
  @observable userLogin = false;
  @observable userLoginInfo = false;
  @observable person = observable.map();


  @action guestSession() {
    const options = {
      method: 'POST',
      url: `${process.env.API}${process.env.MERCHANT}/guestsession`,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'guestSession', success: false };
      if (!res.error) {
        // user status;
        callback.userGuest = this.userGuest;
        callback.userLogin = this.userLogin;
        callback.userLoginInfo = this.userLoginInfo;

        callback = Object.assign({}, callback, { data: res.data, success: true });
        this.userSession = callback;
      } else {
        callback = Object.assign({}, callback, { error: res.error });
        this.userSession = callback;
      }
      return callback;
    });
  }

  @action personRegister(query) {
    const options = {
      method: 'POST',
      url: `${process.env.CUSTOM_ACCOUNTS}${process.env.MERCHANT}/person`,
      data: query,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'personRegister', success: false };
      if (!res.error) {
        // user status;
        callback.userGuest = !this.userGuest;
        callback.userLogin = !this.userLogin;
        callback.userLoginInfo = !this.userLoginInfo;

        callback = Object.assign({}, callback, { data: res.data, success: true });
        this.person = callback;
        this.userSession = callback;
        analytics.hashUserId(query.email);
      } else {
        callback.userGuest = this.userGuest;
        callback.userLogin = this.userLogin;
        callback.userLoginInfo = this.userLoginInfo;

        callback = Object.assign({}, callback, { error: res.error });
        this.userSession = callback;
      }

      return callback;
    });
  }

  @action personLogin(query) {
    const options = {
      method: 'POST',
      url: `${process.env.CUSTOM_ACCOUNTS}${process.env.MERCHANT}/person/login`,
      data: query,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'personLogin', success: false };
      if (!res.error) {
        // user status;
        callback.userGuest = !this.userGuest;
        callback.userLogin = !this.userLogin;
        callback.userLoginInfo = !this.userLoginInfo;

        callback = Object.assign({}, callback, { data: res.data, success: true });
        this.person = callback;
        this.userSession = callback;
        analytics.hashUserId(query.email);
        analytics.login();
      } else {
        callback.userGuest = this.userGuest;
        callback.userLogin = this.userLogin;
        callback.userLoginInfo = this.userLoginInfo;

        callback = Object.assign({}, callback, { error: res.error });
        this.userSession = callback;
      }
      return callback;
    });
  }

  @action personLogout() {
    const options = {
      method: 'delete',
      url: `${process.env.ACCOUNTS}${process.env.MERCHANT}/person/login`,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'personLogout', success: false };
      if (!res.error) {
        // user status;
        callback.userGuest = this.userGuest;
        callback.userLogin = this.userLogin;
        callback.userLoginInfo = this.userLoginInfo;

        callback = Object.assign({}, callback, { data: res.data, success: true });
        this.person = callback;
        this.userSession = callback;

        analytics.logout();
        cookies.remove('Session-Id', { path: '/' });
        // TODO: Temporary refresh cookie.
        window.location.pathname = '/';
      } else {
        callback.userGuest = !this.userGuest;
        callback.userLogin = !this.userLogin;
        callback.userLoginInfo = !this.userLoginInfo;

        callback = Object.assign({}, callback, { error: res.error });
        this.userSession = callback;
      }
      return callback;
    });
  }

  @action syncAddresses() {
    const options = {
      method: 'PUT',
      url: `${process.env.ACCOUNTS}${process.env.MERCHANT}/person/syncaddresses`,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'syncAddresses', success: false };
      if (!res.error) {
        // user status;
        callback.userGuest = !this.userGuest;
        callback.userLogin = !this.userLogin;
        callback.userLoginInfo = !this.userLoginInfo;

        callback = Object.assign({}, callback, { data: res.data, success: true });
        this.userSession = callback;
      } else {
        callback.userGuest = this.userGuest;
        callback.userLogin = this.userLogin;
        callback.userLoginInfo = this.userLoginInfo;

        callback = Object.assign({}, callback, { error: res.error });
        this.userSession = callback;
      }
      return callback;
    });
  }

  @action userInfo() {
    const options = {
      method: 'GET',
      url: `${process.env.API}${process.env.MERCHANT}/guestinfo`,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'userInfo', success: false };
      if (!res.error) {
        // user status;
        callback.userGuest = this.userGuest;
        callback.userLogin = this.userLogin;
        callback.userLoginInfo = this.userLoginInfo;

        callback = Object.assign({}, callback, { data: res.data, success: true });

        this.userSession = callback;
      } else {
        callback = Object.assign({}, callback, { error: res.error });
        this.userSession = callback;
      }
      return callback;
    });
  }
}

export default new SessionStore();

