import { action, observable } from 'mobx';
import _ from 'lodash';

import request from './../../bin/httpRequest';


const clearProductList = (args) => {
  _.remove(args.data, (n) => {
    const imagesValidations = _.filter(n.items, 'images');
    const img = (imagesValidations.length ? imagesValidations : null);

    if (!img || !n.available || !n.buyable) {
      return n;
    }

    return false;
  });
  return args;
};

export const productList = (value, limit) => {
  let paramLimit = '';
  if (limit) {
    paramLimit = `&limit=${limit}`;
  }
  const options = {
    method: 'GET',
    url: `${process.env.MONGO_API}${process.env.MERCHANT}/productsByCategory%2F${value}?lang=${process.env.LOCALE}&currency=${process.env.CURRENCY}${paramLimit}`,
  };

  return request.genericHandler(options, null).then((res) => {
    let callback = { action: 'catalog', success: false };
    if (!res.error) {
      const data = _.sortBy(res.data.data, val => val.position);
      callback = Object.assign({}, callback, { data, success: true });
    } else {
      callback = Object.assign({}, callback, { error: res.error });
    }

    return callback;
  });
};

class CatalogStore {
  @observable autoSuggestResult = observable.map();
  @observable catalogResponse = observable.map();
  @observable searchResult = observable.map();
  @observable inventoryStatus = observable.map();
  @observable categoryList = observable.map();

  @action catalog(value) {
    const options = {
      method: 'GET',
      url: `${process.env.MONGO_API}${process.env.MERCHANT}/productsByCategory%2F${value}?lang=${process.env.LOCALE}&currency=${process.env.CURRENCY}`,
    };

    return request.genericHandler(options, null).then((res) => {
      let callback = { action: 'catalog', success: false };
      if (!res.error) {
        // execute clear product list;
        clearProductList(res.data);
        callback = Object.assign({}, callback, { data: res.data.data, success: true });
      } else {
        callback = Object.assign({}, callback, { error: res.error });
      }
      this.catalogResponse = callback;
      return callback;
    });
  }

  @action getCategories() {
    console.log('categories');
    const options = {
      method: 'GET',
      url: `${process.env.MONGO_API}${process.env.MERCHANT}/categories/`,
    };
    return request.genericHandler(options).then((res) => {
      let callback = { action: 'getCategories', success: false };
      if (!res.error) {
        callback = Object.assign({}, callback, { data: res.data, success: true });
      } else {
        callback = Object.assign({}, callback, { error: res.error });
      }
      this.categoryList = callback;
      return callback;
    });
  }

  @action search(query) {
    const options = {
      method: 'POST',
      url: `${process.env.WCAAS}${process.env.MERCHANT}/productlookup/searchTerm`,
      data: query,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'search', success: false };
      if (!res.error) {
        callback = Object.assign({}, callback, { data: res.data, success: true });
        this.searchResult = callback;
      } else {
        callback = Object.assign({}, callback, { error: res.error });
        this.searchResult = callback;
      }
      return callback;
    });
  }

  @action autoSuggest(query) {
    const options = {
      method: 'POST',
      url: `${process.env.WCAAS}${process.env.MERCHANT}/productlookup/autoSuggest`,
      data: query,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'autoSuggest', success: false };
      if (!res.error) {
        callback = Object.assign({}, callback, { data: res.data, success: true });
        this.autoSuggestResult = callback;
      } else {
        callback = Object.assign({}, callback, { error: res.error });
        this.autoSuggestResult = callback;
      }
      return callback;
    });
  }

  @action
  async inventoryStatusService(partNumber) {
    const options = {
      method: 'GET',
      url: `${process.env.CUSTOM_INVENTORY}${process.env.MERCHANT}/status/${partNumber}`,
    };
    return request.genericHandler(options, null, 'inventoryStatus');
  }

  @action products(query) {
    const options = {
      method: 'POST',
      url: `${process.env.MONGO_API}${process.env.MERCHANT}/productsByAttribute`,
      data: query,
    };

    return request.genericHandler(options, null).then((res) => {
      let callback = { action: 'products', success: false };
      if (!res.error) {
        callback = Object.assign({}, callback, { data: res.data.data, success: true });
      } else {
        callback = Object.assign({}, callback, { error: res.error });
      }
      this.catalogResponse = callback;
      return callback;
    });
  }

  @action
  async datasheet(partNumber) {
    const options = {
      method: 'GET',
      url: `${process.env.MONGO_API}${process.env.MERCHANT}/products/${partNumber}/datasheet`,
    };

    return request.genericHandler(options, null);
  }
}

export default new CatalogStore();
