import React from 'react';
import PropTypes from 'prop-types';

// Helpers
import { textChars, emailChars, passwordChars } from '../../../helpers/char-restriction';

// Components
import Notification from '../../Views/Notification/Notification.ui';

const UI = ({ registerForm, handleChange, handleSubmit, modalType, error, customMaxHeight }) => (
  <section className="modal--form" style={customMaxHeight}>
    <header>
      <h2>Crea tu cuenta</h2>
      <p>Disfruta de los mejores servicios de telefonía, televisión y móvil</p>
    </header>
    <form name="register" className="modal--register" onSubmit={handleSubmit}>
      <p>Nombre</p>
      <label htmlFor="first_name">
        <input
          style={{ height: '2rem' }}
          id="first_name"
          name="first_name"
          type="text"
          maxLength={30}
          placeholder="Nombre(s)"
          onKeyPress={textChars}
          value={registerForm.first_name}
          onChange={(e) => { handleChange(e, 'internalData.firstName'); }}
        />
      </label>
      <p>Apellido</p>
      <label htmlFor="last_name">
        <input
          style={{ height: '2rem' }}
          id="last_name"
          name="last_name"
          type="text"
          maxLength={30}
          placeholder="Apellido(s)"
          onKeyPress={textChars}
          value={registerForm.last_name}
          onChange={(e) => { handleChange(e, 'internalData.lastName'); }}
        />
      </label>
      <p>Correo electrónico:</p>
      <label htmlFor="registerEmail">
        <input
          style={{ height: '2rem' }}
          id="registerEmail"
          name="registerEmail"
          type="text"
          placeholder="ejemplo@email.com"
          onKeyPress={emailChars}
          value={registerForm.email}
          onChange={(e) => { handleChange(e, 'internalData.email'); }}
        />
      </label>
      <p>Ingresa contraseña:</p>
      <label htmlFor="registerPass">
        <input
          style={{ height: '2rem' }}
          id="registerPass"
          name="registerPass"
          type="password"
          placeholder="Ingresa tu contraseña"
          minLength={8}
          maxLength={16}
          onKeyPress={passwordChars}
          value={registerForm.password}
          onChange={(e) => { handleChange(e, 'internalData.password'); }}
        />
      </label>
      <p>Confirma contraseña:</p>
      <label htmlFor="registerPassConfirm">
        <input
          style={{ height: '2rem' }}
          id="registerPassConfirm"
          name="registerPassConfirm"
          type="password"
          placeholder="Confirma tu contraseña"
          minLength={8}
          maxLength={16}
          onKeyPress={passwordChars}
          value={registerForm.passwordConfirm}
          onChange={(e) => { handleChange(e, 'internalData.passwordConfirm'); }}
        />
      </label>
      <div className="modal--register-passreq">
        {/* <h6>La contraseña debe contener lo siguiente:</h6> */}
        <ul>
          <li>La contraseña debe tener entre 8 y 16 caracteres.</li>
          <li>Debe tener al menos un número (0-9)</li>
          <li>Debe tener al menos una letra mayúscula (A-Z)</li>
          <li>Debe tener al menos uno de los siguientes símbolos: !, @, #, $, %, ^, &, *, +.</li>
        </ul>
      </div>
      <div className="modal--register-comply">
        <label>
          <input
            type="checkbox"
            id="registerCompliance"
            name="registerCompliance"
          />
          <span>Al continuar estarás aceptando los </span>
        </label>
        <a href="/terms" target="_blank" rel="noopener noreferrer">Términos y Condiciones</a>
      </div>
      <button type="submit">
        <span>Crear Cuenta</span>
      </button>
    </form>
    <aside>
      <p>Ya tengo una cuenta</p> <a onClick={() => { modalType(null); }}>Iniciar Sesión</a>
    </aside>
    {(error) ? <Notification title={error} /> : <div />}
  </section>
);

UI.propTypes = {
  registerForm: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalType: PropTypes.func.isRequired,
  error: PropTypes.string,
};

UI.defaultProps = {
  error: '',
};

export default UI;
