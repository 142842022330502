import React, { Component } from 'react';
import PropTypes from 'prop-types';

// utilities
import $ from 'jquery';

// UI
import UI from './DefaultFooter.ui';

class Footer extends Component {
  handleCountry() {
    window.location = $('#footer-country').val();
  }

  closeMenus() {
    if ($(window).width() > 760) {
      $('.nav--user').removeClass('active');
      $('.nav--links-trigger').removeClass('active');
      $('.nav--menu').slideUp();
      $('.nav--menu-login').slideUp();
    }
  }

  render() {
    return (
      <UI
        handleCountry={this.handleCountry}
        closeMenus={this.closeMenus}
        layoutData={this.props.layoutdata}
        routerPath={this.props.path}
      />
    );
  }
}

Footer.propTypes = {
  layoutdata: PropTypes.shape({}).isRequired,
};

export default Footer;

