import React, { Component } from 'react';
import PropTypes from 'prop-types';

// UI
import UI from './PassRecovered.ui';

class PassRecovered extends Component {
  constructor(...props) {
    super(...props);
    this.state = {};
  }
  render() {
    return (
      <UI
        modalEmail={this.props.modalEmail}
        modalType={this.props.modalType}
      />
    );
  }
}

PassRecovered.propTypes = {
  modalType: PropTypes.func,
  modalEmail: PropTypes.string,
};

PassRecovered.defaultProps = {
  modalType: () => {},
  modalEmail: '',
};

export default PassRecovered;
