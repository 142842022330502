import { action, observable } from 'mobx';

// Helpers
import request from '../../bin/httpRequest';
import { getConfigMC, queryFunction } from '../../store/Query/api';

// TODO: en v2 se elimina funcion consulrCart
/**
 * Consult the cart or order detail.
 * @param {string} SessionId - is required.
 */
export const consultCart = (SessionId) => {
  const options = {
    method: 'POST',
    url: `${process.env.API}${process.env.MERCHANT}/sessionvalidate`,
  };

  return request.genericHandler(options, SessionId).then((res) => {
    let callback = { action: 'guestSession', success: false };
    if (!res.error) {
      callback = Object.assign({}, callback, { data: res.data, success: true });
    } else {
      callback = Object.assign({}, callback, { error: res.error });
    }
    return callback;
  });
};

export const genericFunction = (options, sessionIdDisable, actionCallback) => request.genericHandler(options, sessionIdDisable).then((res) => {
  let callback = { action: actionCallback, success: false };
  if (!res.error) {
    callback = {
      ...callback,
      data: res.data,
      success: true,
    };
  } else {
    callback = {
      ...callback,
      error: res.error,
    };
  }
  return callback;
});

class OrderStore {
  // @observable addCart = observable.map();
  @observable getCartInfo = observable.map();
  @observable getCartConfig = observable.map();
  @observable shippingAddress = observable.map();
  @observable billinggAddress = observable.map();
  @observable dpiResponse = observable.map();
  @observable maxActivClientResponse = observable.map();
  @observable maxActivClientPostpagoResponse = observable.map();
  @observable validateNumberResponse = observable.map();
  @observable addressListResponse = observable.map();
  @observable warnedUser = false;
  @observable validateCartTrigger = null;

  @action
  async getCart(init) {
    const options = {
      method: 'GET',
      url: `${process.env.API}${process.env.MERCHANT}/cart`,
    };
    const response = await genericFunction(options, null, 'cart');
    this.getCartInfo = response;
    if (init === 'user_warned') {
      this.warnedUser = true;
    } else if (init === 'user_not_warned') {
      this.warnedUser = false;
    }

    return response;
  }

  @action
  async getConfig() {
    const response = await queryFunction('config', getConfigMC);
    this.getCartConfig = response;
    return response;
  }

  @action
  async getRecommendations() {
    const options = {
      method: 'GET',
      url: `${process.env.API}${process.env.MERCHANT}/cart/recommendations`,
    };
    return genericFunction(options, null, 'recommendations');
  }

  @action
  async getAddons() {
    const options = {
      method: 'GET',
      url: `${process.env.WCAAS}${process.env.MERCHANT}/addon`,
    };
    return genericFunction(options, null, 'addon');
  }

  @action
  async addToCart(query, init) {
    const options = {
      method: 'POST',
      url: `${process.env.API}${process.env.MERCHANT}/cart/item/`,
      data: query,
    };

    const response = await genericFunction(options, null, 'addToCart');
    this.getCartInfo = response;
    await this.getCart(init);
    return response;
  }

  @action
  async deleteToCart(partNumber) {
    const options = {
      method: 'DELETE',
      url: `${process.env.API}${process.env.MERCHANT}/cart/item/${partNumber}`,
    };

    const response = await genericFunction(options, null, 'deleteCart');
    this.getCartInfo = response;
    return response;
  }

  @action
  async validateCart(query) {
    const options = {
      method: 'POST',
      url: `${process.env.API}${process.env.MERCHANT}/cart/validate/`,
      data: query,
    };

    return genericFunction(options, null, 'validateCart');
  }

  @action
  async validateAddon(query) {
    const options = {
      method: 'POST',
      url: `${process.env.AMXGW_HOST}/${process.env.MERCHANT}/validateAddon`,
      data: query,
    };

    return genericFunction(options, null, 'validateCart');
  }

  @action
  async refreshOrder() {
    const options = {
      method: 'DELETE',
      url: `/api/custom/order/${process.env.MERCHANT}`,
    };

    const response = await genericFunction(options, null, 'deleteCart');
    this.getCartInfo = response;
    return response;
  }

  @action
  async addShippingAddress(query) {
    const options = {
      method: 'POST',
      url: `${process.env.API}${process.env.MERCHANT}/cart/shipping_address/`,
      data: query,
    };

    return genericFunction(options, null, 'shippingAddress');
  }

  @action
  async uploadDocument(query) {
    const options = {
      method: 'POST',
      url: `${process.env.API}${process.env.MERCHANT}/cart/upload/`,
      data: query,
    };

    return genericFunction(options, null, 'upload');
  }

  @action
  async addBillingAddress(query) {
    const options = {
      method: 'POST',
      url: `${process.env.API}${process.env.MERCHANT}/cart/billing_address/`,
      data: query,
    };

    return genericFunction(options, null, 'billingAddress');
  }

  @action
  async validateDpi(query) {
    const sessionIdDisable = true;
    const options = {
      method: 'POST',
      url: `${process.env.AMXGW_HOST}/${process.env.MERCHANT}/dui`,
      data: query,
    };

    const response = await genericFunction(options, sessionIdDisable, 'validateDpi');
    this.dpiResponse = response;

    return response;
  }

  @action
  async validateMaxActiveClient(query) {
    const sessionIdDisable = true;
    const options = {
      method: 'POST',
      url: `${process.env.AMXGW_HOST}/${process.env.MERCHANT}/mac`,
      data: query,
    };

    const response = await genericFunction(options, sessionIdDisable, 'validateMaxActiveClient');
    this.maxActivClientResponse = response;

    return response;
  }

  @action
  async validateMaxActivations(query) {
    const sessionIdDisable = true;
    const options = {
      method: 'POST',
      url: `${process.env.AMXGW_HOST}/${process.env.MERCHANT}/maxactivations`,
      data: {
        validateMaxActivations: query,
      },
    };

    const response = await genericFunction(options, sessionIdDisable, 'validateMaxActivations');
    this.maxActivClientPostpagoResponse = response;

    return response;
  }

  @action
  async validateNumber(query) {
    const sessionIdDisable = true;
    const options = {
      method: 'POST',
      url: `${process.env.AMXGW_HOST}/${process.env.MERCHANT}/validateNumber`,
      data: query,
    };

    const response = await genericFunction(options, sessionIdDisable, 'validateNumber');
    this.validateNumberResponse = response;
    return response;
  }

  @action
  async generarToken(query) {
    const sessionIdDisable = true;
    const options = {
      method: 'POST',
      url: `${process.env.AMXGW_HOST}/${process.env.MERCHANT}/generarToken`,
      data: query,
    };

    return genericFunction(options, sessionIdDisable, 'generarToken');
  }

  @action
  async validarToken(query) {
    const sessionIdDisable = true;
    const options = {
      method: 'POST',
      url: `${process.env.AMXGW_HOST}/${process.env.MERCHANT}/validarToken`,
      data: query,
    };

    return genericFunction(options, sessionIdDisable, 'validarToken');
  }

  @action
  async documentsUpload(query) {
    const sessionIdDisable = true;
    const options = {
      method: 'POST',
      // url: `${process.env.AMXGW_HOST}/${process.env.MERCHANT}/upload`,
      url: `${process.env.API}${process.env.MERCHANT}/cart/documents_upload/`,
      data: query,
    };

    return genericFunction(options, sessionIdDisable, 'documentsUpload');
  }

  @action
  async addressList(query) {
    const options = {
      method: 'GET',
      url: `${process.env.MONGO_API}${process.env.MERCHANT}/findStores`,
      data: query,
    };

    const response = await genericFunction(options, null, 'addressList');
    this.addressListResponse = response;

    return response;
  }

  @action
  async syncaddresses(query) {
    const options = {
      method: 'PUT',
      url: `${process.env.ACCOUNTS}${process.env.MERCHANT}/person/syncaddresses`,
      data: query,
    };

    const response = await genericFunction(options, null, 'syncaddresses');
    this.addressListResponse = response;

    return response;
  }

  @action
  async tracer(query) {
    const sessionIdDisable = true;
    const options = {
      method: 'POST',
      url: `${process.env.AMXGW_HOST}/${process.env.MERCHANT}/tracer`,
      data: query,
    };

    return genericFunction(options, sessionIdDisable, 'tracer');
  }

  @action
  async paymentLog(query) {
    const options = {
      method: 'POST',
      url: `${process.env.SERVICES}/payments/htech/log`,
      data: query,
    };

    return genericFunction(options, null, 'paymentLog');
  }
}

export default new OrderStore();
