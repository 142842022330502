import React from 'react';
import { useConfigMC } from '../../store/Query/api';

const Legal = () => {
  const { data } = useConfigMC();
  if (data && data.ok && data.body.informacionLegal) {
    const legalInfo = data.body.informacionLegal;
    return (
      <div className="footer--breadcrumb">
        <nav style={{ background: '#222', maxWidth: '100%', textAlign: 'center', color: 'white', paddingBottom: '0' }}>
          <p><strong>{legalInfo.razonSocial}</strong>&nbsp;{legalInfo.nit}&nbsp;{legalInfo.direccion}&nbsp;{legalInfo.telefono}&nbsp;{legalInfo.email}</p>&nbsp;
        </nav>
        {window.location.pathname.indexOf('/checkout/') === 0 && (
          <nav style={{ height: '1em' }}>&nbsp;</nav>
        )}
      </div>
    );
  }
  return null;
};

export default Legal;
