import React from 'react';
import PropTypes from 'prop-types';

const UI = ({ modalType, modalEmail }) => (
  <section className="modal--form">
    <header>
      <h2>Contraseña Restablecida</h2>
    </header>
    <p>Se ha enviado la contraseña al correo: { modalEmail }</p>
    <footer>
      <a onClick={() => { modalType(null); }}>Iniciar Sesion</a>
    </footer>
  </section>
);

UI.propTypes = {
  modalType: PropTypes.func,
  modalEmail: PropTypes.string,
};

UI.defaultProps = {
  modalType: () => {},
  modalEmail: '',
};

export default UI;
