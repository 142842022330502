import $ from 'jquery';
import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { inject } from 'mobx-react';

import analytics from '../../helpers/analytics';

import UI from './Catalog.ui';
import { productList } from '../../store/Catalog/Catalog';
import { espots } from '../../store/ESpots/ESpots';
import { addonsList } from '../../store/Addons/Addons';

class ProductList extends Component {
  static propTypes = {
    cookies: PropTypes.instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = ({
      productList: [],
      espotsResponse: {},
      addonsList: [],
      currentPage: 1,
      itemsPerPage: 20,
      pageNumbers: [],
      indexPage: {
        initPage: 0,
        endPage: 5,
      },
      renovaciones: '',
    });
    this.productsFilter = this.productsFilter.bind(this);
    this.resetCatalog = this.resetCatalog.bind(this);
    this.paginationClick = this.paginationClick.bind(this);
    this.handleOnChangePagination = this.handleOnChangePagination.bind(this);
    this.handleOnChangePaginationByPage = this.handleOnChangePaginationByPage.bind(this);
    this.toggleSelected = this.toggleSelected.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.toggleChildCategories = this.toggleChildCategories.bind(this);
    this.syncProductList = this.syncProductList.bind(this);
    this.openNav = this.openNav.bind(this);
    this.closeNav = this.closeNav.bind(this);
  }

  componentDidMount() {
    document.getElementById('initial').scrollIntoView(true);
    this.init();
  }

  getAllCategories() {
    return this.props.navigationStore.getCategories().then((res) => {
      res.data.data.sort((prev, current) => (prev.locales[process.env.LOCALE].position > current.locales[process.env.LOCALE].position ? 1 : -1));
      this.state.categories = res.data;
      this.getAllParentCategories(res.data);
      this.getThirdLevelCategories(res.data);
      return res.data;
    });
  }

  getMacroCategories = async () => {
    const res = await this.props.navigationStore.getMacroCategories();
    res.data.data.sort((prev, current) => (prev.locales[process.env.LOCALE].position > current.locales[process.env.LOCALE].position ? 1 : -1));
    // shipping = _.filter(addresses, { nickname: 'Envio' });
    this.state.macroCategories = res.data;
    this.state.showMacro = true;
    this.setState({
      productList: [],
      currentPage: 1,
    });
    this.state.productList = [];
    const list = [];
    this.state.macroCategories.data.forEach((element) => {
      list.push(this.productList(element.external_referrer, element.locales[process.env.LOCALE].name));
    });
    await Promise.all(list);
  }

  getAllParentCategories(data) {
    const parents = [];
    (data.data || []).map((v) => {
      if (v.locales[process.env.LOCALE].parent_code !== '') {
        if (v.locales[process.env.LOCALE].categories.length === 2) {
          parents.push(v.external_referrer);
        }
        return v.locales[process.env.LOCALE].parent;
      }
      return false;
    });
    this.state.parentCategories = parents;
  }

  getThirdLevelCategories(data) {
    const thirdLevel = [];
    (data.data || []).map((v) => {
      if (v.locales[process.env.LOCALE].parent_code !== '') {
        if (v.locales[process.env.LOCALE].categories.length === 3) {
          thirdLevel.push(v);
        }
        return v.locales[process.env.LOCALE].parent;
      }
      return false;
    });
    console.log(thirdLevel);
    this.state.thirdLevel = thirdLevel;
  }

  setPaginatorIndex(currentPage) {
    if (this.state.pageNumbers.length > 5) {
      if (((currentPage + 2) <= this.state.pageNumbers.length) && ((currentPage - 2) >= 1)) {
        this.state.indexPage.initPage = currentPage - 3;
        this.state.indexPage.endPage = currentPage + 2;
      } else if (currentPage === 2) {
        this.state.indexPage.initPage = 0;
        this.state.indexPage.endPage = 5;
      }
    }
  }

  init = async () => {
    const { state } = this.props.location;
    // const { history } = this.props;
    // this.productList();
    await Promise.all([
      this.addonsList(),
      this.espotsResponse(),
      this.getAllCategories(),
      this.getMacroCategories(),
      this.props.orderStore.getConfig().then((config) => {
        if (config.success && config.data.data && config.data.data.renovaciones && config.data.data.renovaciones.activo) {
          this.setState({ renovaciones: config.data.data.renovaciones.texto });
        }
      }),
    ]);
    this.startPagination();
    if (state && state.product) {
      if (state.product.isWrapMenuLi) {
        this.toggleShowMacro(state.product.keys);
        this.toggleSelected(state.product.keys);
      } else {
        this.state.showMacro = false;
      }
      this.syncProductList(state.product.syncProductList[0], state.product.syncProductList[1], state.product.syncProductList[2]);
      this.closeMenu();
      // history.replace('/', null);
    }
  }

  toggleShowMacro = (keys, submenu) => {
    console.log(submenu);
    console.log('toggleShowMacro', keys);
    this.state.showMacro = $(`#${keys}`).hasClass('selected');
    console.log(this.state.showMacro);
    if (this.state.showMacro) {
      this.setState({
        productList: [],
        currentPage: 1,
      });
      this.state.productList = [];
      console.log(this.state.productList);
      this.state.macroCategories.data.forEach((element) => {
        this.productList(element.external_referrer, element.locales[process.env.LOCALE].name);
      });
    }
  }

  toggleChildCategories = (parentCode) => {
    const categories = this.state.categories.data;
    this.state.childCategories = [];
    const items = categories.filter(category => category.locales[process.env.LOCALE].parent_code === parentCode).map((filteredCategories, i1) => {
      const items2 = categories.filter(category => category.locales[process.env.LOCALE].parent_code === filteredCategories.external_referrer).map((filteredCategories2, i2) => (<li key={`subchild-cat-${i2}`}><a href="#" onClick={(e) => { e.preventDefault(); this.closeMenu(); this.state.showMacro = false; this.syncProductList(filteredCategories2.external_referrer, filteredCategories2.locales[process.env.LOCALE].description, filteredCategories2.external_referer); }}>{filteredCategories2.locales[process.env.LOCALE].name}</a></li>));
      return (<ul key={`child-cat-${i1}`}><li><a href="#" onClick={(e) => { e.preventDefault(); this.closeMenu(); this.state.showMacro = false; this.syncProductList(filteredCategories.external_referrer, filteredCategories.locales[process.env.LOCALE].description, filteredCategories.external_referer); }}>{filteredCategories.locales[process.env.LOCALE].name}</a></li>{items2}</ul>);
    });
    this.setState({ childCategories: items });
  }

  toggleSelectedCategory(externalReferrer, description) {
    // console.log('o.o', externalReferrer, description);
    this.toggleShowMacro(false, true);
    this.syncProductList(externalReferrer, description);
  }

  startPagination() {
    this.setState({ itemsPerPage: (this.props.cookies.get('paginationId') || 20) });
    this.props.cookies.remove('paginationId');
  }

  espotsResponse() {
    return espots('Slider_Banners').then((res) => {
      this.setState({ espotsResponse: res });
    });
  }

  syncProductList = (externalReferrer, description, categoryId, hideMacro) => {
    console.debug(externalReferrer);
    console.debug(description);
    if (hideMacro) this.state.showMacro = false;
    if (!this.state.showMacro) {
      const cat = this.state.categories.data.find(category => category.external_referrer === externalReferrer);
      if (cat) {
        this.state.keywords = cat.locales[process.env.LOCALE].seo.keywords;
        this.state.name = cat.locales[process.env.LOCALE].name;
      }
      this.state.selectedMacro = description;
      productList(externalReferrer).then((res) => {
        this.totalNumberOfPages(res.data, this.state.itemsPerPage);
        this.setState({ productList: res.data, currentPage: 1, categoryId, description, categorySlug: externalReferrer });
        analytics.productImpressions(this.state);
      });
    } else {
      this.state.keywords = '';
      this.state.name = '';
      this.state.selectedMacro = '';
    }
  }
  productList = async (externalReferrer, name) => {
    // console.log(this.state.showMacro);
    if (this.state.showMacro) {
      const productListTemp = this.state.productList;
      // console.log(this.state.productList);
      await productList(externalReferrer, 4).then((res) => {
        // this.totalNumberOfPages(res.data, this.state.itemsPerPage);
        productListTemp.push([name, ...res.data]);
      });
      this.setState({
        productList: productListTemp,
        currentPage: 1,
        categoryId: null,
      });
      analytics.productImpressions(this.state);
    }
  }

  addonsList() {
    return addonsList().then((res) => {
      this.setState({ addonsList: res.data[0].filter(x => x.enabled === true && x.show_home === false), currentPage: 1 });
    });
  }

  resetCatalog(categorySlug, categoryName, categoryId) {
    this.state.showMacro = false;
    this.syncProductList(categorySlug, categoryName, categoryId);
    this.addonsList();
  }

  productsFilter(items) {
    this.totalNumberOfPages(items, this.state.itemsPerPage);
    this.setState({ productList: items });
  }

  paginationClick(event) {
    $('.pagination a').removeClass('active');
    $(event.currentTarget).addClass('active');
    this.setState({
      currentPage: Number(event.target.id),
    });
    window.scrollTo(0, 0);
  }

  handleOnChangePagination(event) {
    $('.pagination a').removeClass('active');
    if (event.target.value !== '-') {
      this.totalNumberOfPages(this.state.productList, event.target.value);
      this.setState({ itemsPerPage: event.target.value, currentPage: 1 });
      this.props.cookies.set('paginationId', event.target.value);
    }
    window.scrollTo(0, 0);
    setTimeout(() => $('#1.pagination--page').addClass('active'), 30);
  }

  handleOnChangePaginationByPage(event) {
    $('.pagination section a, #1').removeClass('active');
    if (event.target.id === 'pagination-first') {
      this.setState({ currentPage: 1 });
      setTimeout(() => $('.pagination section #1').addClass('active'), 30);
    } else if (event.target.className === 'pagination-prev') {
      if (this.state.currentPage !== 1) {
        this.setState({ currentPage: this.state.currentPage - 1 });
        const prevOfCurrent = `#${this.state.currentPage - 1}`;
        $(prevOfCurrent).addClass('active');
      }
    } else if (event.target.className === 'pagination-next') {
      if (this.state.currentPage !== this.state.pageNumbers.length) {
        this.setState({ currentPage: this.state.currentPage + 1 });
        const nextOfCurrent = `#${this.state.currentPage + 1}`;
        $(nextOfCurrent).addClass('active');
      }
    } else if (event.target.id === 'pagination-last') {
      this.setState({ currentPage: this.state.pageNumbers.length });
      const lastPage = `#${this.state.pageNumbers.length}`;
      setTimeout(() => $(lastPage).addClass('active'), 30);
    }
    window.scrollTo(0, 0);
  }

  totalNumberOfPages(productlist, itemsperpage) {
    const pageNumbers = [];
    if (productlist !== undefined && productlist.length) {
      for (let i = 1; i <= Math.ceil(productlist.length / itemsperpage); i += 1) pageNumbers.push(i);
    }
    this.setState({ pageNumbers });
  }

  toggleSelected(selected) {
    if (!$(`#${selected}`).hasClass('selected')) {
      $('.nav--category-box').removeClass('selected');
      $(`#${selected}`).addClass('selected');
    } else {
      $(`#${selected}`).removeClass('selected');
    }
  }

  openMenu() {
    $('.wrapMenu').show();
    $('.wrapMenu').addClass('flex-display');
    $('.nav').children(':not(.wrapMenu)').addClass('blur-content');
    $('.catalog--header').children(':not(.wrapMenu)').addClass('blur-content');
    $('.catalog--module').children(':not(.wrapMenu)').addClass('blur-content');
  }

  closeMenu() {
    $('.wrapMenu').hide();
    $('.wrapMenu').removeClass('flex-display');
    $('.nav').children(':not(.wrapMenu)').removeClass('blur-content');
    $('.catalog--header').children(':not(.wrapMenu)').removeClass('blur-content');
    $('.catalog--module').children(':not(.wrapMenu)').removeClass('blur-content');
  }

  openNav() {
    document.getElementById('mySidenav').style.display = 'block';
  }

  closeNav() {
    document.getElementById('mySidenav').style.display = 'none';
  }

  render() {
    const { currentPage, itemsPerPage } = this.state;
    const indexOfLastTodo = currentPage * itemsPerPage;
    const indexOfFirstTodo = indexOfLastTodo - itemsPerPage;
    let currentProductList = [];
    // const categories = Catalog.getCategories();
    if (this.state.productList !== undefined) currentProductList = this.state.productList.slice(indexOfFirstTodo, indexOfLastTodo);
    let range = currentPage;
    if (currentPage <= 2) {
      range = 2;
    } else if (currentPage === this.state.pageNumbers.length) {
      range = currentPage - 2;
    }
    this.setPaginatorIndex(range);
    return (
      <Fragment>
        {this.state.name && (
          <Helmet title={`${this.state.name} | Catálogo Claro ${process.env.COUNTRY}`}>
            <meta name="description" content={this.state.keywords} />
          </Helmet>
        )}
        <UI
          productList={currentProductList}
          productsFilter={this.productsFilter}
          resetCatalog={this.resetCatalog}
          eSpot={this.state.espotsResponse}
          paginationClick={this.paginationClick}
          pageNumbers={this.state.pageNumbers}
          handleOnChangePagination={this.handleOnChangePagination}
          indexPage={this.state.indexPage}
          handleOnChangePaginationByPage={this.handleOnChangePaginationByPage}
          itemsPerPageValue={itemsPerPage}
          addonsList={this.state.addonsList}
          categories={this.state.categories}
          parentCategories={this.state.parentCategories}
          toggleSelected={this.toggleSelected}
          openMenu={this.openMenu}
          closeMenu={this.closeMenu}
          toggleChildCategories={this.toggleChildCategories}
          macroCategories={this.state.macroCategories}
          showMacro={this.state.showMacro}
          productListFunction={this.productList}
          toggleShowMacro={this.toggleShowMacro}
          syncProductList={this.syncProductList}
          selectedMacro={this.state.selectedMacro}
          childCategories={this.state.childCategories}
          openNav={this.openNav}
          closeNav={this.closeNav}
          categoryId={this.state.categoryId}
          categorySlug={this.state.categorySlug}
          categoryName={this.state.description}
          thirdLevel={this.state.thirdLevel}
          renovaciones={this.state.renovaciones}
        />
      </Fragment>
    );
  }
}

export default inject('navigationStore', 'orderStore')(withCookies(ProductList));
