import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

// Stores
import { guestSession } from './../../store/Sessions/Sessions';
import { consultCart } from './../../store/Orders/Cart';

// Helpers
import request from '../../bin/httpRequest';
import locaLayout from './data.json';

// Components
import DefaultHeader from './../../components/Headers/DefaultHeader';
import DefaultFooter from './../../components/Footer/DefaultFooter';
import CheckoutHeader from './../../components/Headers/CheckoutHeader';
import CheckoutFooter from './../../components/Footer/CheckoutFooter';

// Modals
import Modal from './../Account/ModalsAccount';

class DefaultLayout extends Component {
  static propTypes = {
    cookies: PropTypes.instanceOf(Cookies).isRequired,
  };

  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(...props);
    this.state = {
      layout: {},
    };
  }

  componentDidMount() {
    this.createSessionToken();
    this.interval = setInterval(() => {
      this.validateSession();
    }, 600000);
    console.log('git version: ', process.env.VERSION);
    this.fetchLayout();
  }

  componentDidUpdate() {
    if (document.location.pathname !== '/terms' || !window.location.hash) {
      document.getElementById('app').scrollIntoView(true);
    }
    if (!this.props.cookies.get('Session-Id')) {
      this.createSessionToken();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetchLayout() {
    const options = {
      method: 'GET',
      url: process.env.LAYOUT,
    };
    request.genericHandler(options).then((res) => {
      // console.log('fetchLayout -> res:: ', res);
      let callback = { action: 'layout', success: false };
      if (!res.error) {
        // firefox request data null on production
        if (res.data.data !== null) {
          callback = Object.assign({}, callback, {
            data: res.data.data,
            success: true,
          });
        } else {
          callback = Object.assign({}, callback, {
            data: locaLayout,
            success: true,
          });
        }

        this.setState({ layout: callback.data });
      } else {
        callback = Object.assign({}, callback, {
          error: res.error,
          success: false,
        });
        console.log('error: ', res.error);
      }
      // console.log('fetchLayout -> callback:: ', callback);
      return callback;
    });
  }

  createSessionToken() {
    if (!this.props.cookies.get('Session-Id')) {
      guestSession().then((res) => {
        if (res && res.data && res.data.data) {
          // set cookie.
          this.props.cookies.set('Session-Id', res.data.data.session_id, {
            path: '/',
          });
        }
      });
    }
  }

  validateSession() {
    consultCart();
  }

  render() {
    return (
      <div id="layout-default">
        {this.context.router.route.location.pathname.match(/\/checkout/) ? (
          <CheckoutHeader />
        ) : (
          <DefaultHeader layoutdata={this.state.layout} />
        )}
        <div id="initial">{this.props.children}</div>
        {this.context.router.route.location.pathname.match(/\/checkout/) ? (
          <CheckoutFooter layoutdata={this.state.layout} />
        ) : (
          <DefaultFooter layoutdata={this.state.layout} path={this.context.router.route.location.pathname} />
        )}
        <Modal />
      </div>
    );
  }
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withCookies(DefaultLayout);
