import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import $ from 'jquery';
import { observer, inject } from 'mobx-react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';

import analytics from '../../helpers/analytics';

// Request
import catalog from './../../store/Catalog/Catalog';
import product from './../../store/Product/Product';

// UI
import UI from './Product.ui';
import productHelpers from '../../helpers/product';

/**
 * Product Detail
 */

class Product extends Component {
  static contextTypes = {
    router: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.state = ({
      productInfo: {},
      dataCatalog: [],
      initColor: '',
      initSize: '',
      images: [],
      name: '',
      pricing: {},
      isAvailable: '',
      attributes: [],
    });
    // Binds
    this.colorSelected = this.colorSelected.bind(this);
    this.sizeSelected = this.sizeSelected.bind(this);
    this.handleTabs = this.handleTabs.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.openNav = this.openNav.bind(this);
    this.closeNav = this.closeNav.bind(this);
  }

  componentDidMount() {
    this.productResponse(this.props.location.pathname);
    // this.catalogResponse();
    this.getAllCategories();
    document.getElementById('initial').scrollIntoView(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.productResponse(this.props.location.pathname);
      // this.catalogResponse();
      this.getAllCategories();
      setTimeout(() => { window.scrollTo(0, 0); }, 1000);
    }
  }

  componentWillUnmount() {
    // $('.zoomContainer').remove();
  }

  getInventoryStatus = async (partNumber) => {
    const inventoryStatus = await this.props.catalogStore.inventoryStatusService(partNumber);
    if (inventoryStatus && inventoryStatus.data && inventoryStatus.data.data) {
      if (inventoryStatus.data.data.status === 'available') {
        this.setState({ isAvailable: 'available' });
      } else this.setState({ isAvailable: 'unavailable' });
    }
  }

  async getAllCategories() {
    console.log('allcategories');
    this.props.navigationStore.getCategories().then((res) => {
      res.data.data.sort((prev, current) => (prev.locales[process.env.LOCALE].position > current.locales[process.env.LOCALE].position ? 1 : -1));
      this.setState({ categories: res.data });
      this.state.categories = res.data;
      this.getAllParentCategories(res.data);
      this.getThirdLevelCategories(res.data);
      return res.data;
    });
  }

  getAllParentCategories(data) {
    const parents = [];
    console.log(data);
    const items = (data.data || []).map((v, i) => {
      console.log(i);
      if (v.locales[process.env.LOCALE].parent_code !== '') {
        console.log('length', v.locales[process.env.LOCALE].categories.length);
        if (v.locales[process.env.LOCALE].categories.length === 2) {
          parents.push(v.external_referrer);
        }
        return v.locales[process.env.LOCALE].parent;
      }
      return false;
    });
    console.log(items);
    this.state.parentCategories = parents;
  }

  getThirdLevelCategories(data) {
    const thirdLevel = [];
    (data.data || []).map((v) => {
      if (v.locales[process.env.LOCALE].parent_code !== '') {
        if (v.locales[process.env.LOCALE].categories.length === 3) {
          thirdLevel.push(v);
        }
        return v.locales[process.env.LOCALE].parent;
      }
      return false;
    });
    console.debug(thirdLevel);
    this.state.thirdLevel = thirdLevel;
  }
  // Product Request
  productResponse(path) {
    product(path).then((res) => {
      const category = res.data;
      let slug;
      if (category && category.data && category.data.length && category.data[0].locales && category.data[0].locales[process.env.LOCALE] && category.data[0].locales[process.env.LOCALE].categories) {
        slug = category.data[0].locales[process.env.LOCALE].categories[0].slug.split('/');
        slug = slug[0];
        if (slug === 'postpago') slug = 'prepago';
      }

      const partNumbertoFilter = path.split('/');
      _.remove(res.data.data, (products) => {
        if (products.part_number && products.part_number === partNumbertoFilter[partNumbertoFilter.length - 1]) {
          return false;
        }
        return true;
      });

      if (slug) this.props.catalogStore.catalog(slug);

      this.setState({ productInfo: res.data, slug });

      if (res && res.data && res.data.data.length) {
        productHelpers.ordenar(res.data.data[0]);
        // if all items' product is available false redirect home
        let isAvailableCount = 0;
        const availableItems = [];
        let firsAvailableItem = 0;
        res.data.data[0].items.map((item, index) => {
          if (item.available === false) {
            isAvailableCount += 1;
          } else {
            availableItems.push(index);
          }
          return false;
        });

        if (isAvailableCount === res.data.data[0].items.length) this.context.router.history.push('/');

        firsAvailableItem = availableItems[0] !== undefined ? availableItems[0] : 0;

        const initColor = _.filter(res.data.data[0].items[firsAvailableItem].attributes, { name: 'def-color' });
        const initSize = _.filter(res.data.data[0].items[firsAvailableItem].attributes, { name: 'def-capacidad' });
        const images = res.data.data[0].items[firsAvailableItem].images || './../../../public/media/demo/claro.png';
        const pricing = res.data.data[0].items[firsAvailableItem].pricing;
        const { name } = res.data.data[0].items[firsAvailableItem].locales[process.env.LOCALE];
        const partNumber = res.data.data[0].items[firsAvailableItem].part_number;
        const attributes = res.data.data[0].items[firsAvailableItem].attributes;
        const convertedName = name.replace(/\\n/g, ' ');
        this.getInventoryStatus(partNumber);
        const initSizeValue = initSize[0] ? initSize[0].value : '';
        const initColorValue = initColor[0] ? initColor[0].value : '';
        const titleAnalytics = `${convertedName} ${initSizeValue}`;
        const tipoProducto = _.filter(res.data.data[0].items[firsAvailableItem].attributes, { identifier: 'attr-conf-tipoproducto' });

        this.state.productType = tipoProducto[0].value;
        console.log(this.state.productType);
        console.log(titleAnalytics);
        ReactGA.pageview('/products', ['tracker'], titleAnalytics);

        this.setState({ initColor: initColorValue, initSize: initSizeValue, images, pricing, name, attributes, item: res.data.data[0].items[firsAvailableItem] });
      }
      analytics.productDetail(this.state);
    });
  }

  catalogResponse() {
    catalog().then((data) => {
      if (data.length.length) {
        data.splice(6);
        this.setState({ dataCatalog: data });
      }
    });
  }

  colorSelected(obj) {
    this.setState({
      initColor: obj.color,
      initSize: obj.size,
      images: obj.images,
      pricing: obj.pricing,
      name: obj.name,
      attributes: obj.attributes,
      item: obj.item,
    });
    this.getInventoryStatus(obj.part_number);
  }

  sizeSelected(obj) {
    this.setState({
      initSize: obj.size,
      pricing: obj.pricing,
    });
    this.getInventoryStatus(obj.part_number);
  }

  handleTabs(e) {
    $('.product--tabs a').removeClass('active');
    $(e.currentTarget).addClass('active');
    $('.product--description, .product--datasheet').toggleClass('active');
  }

  handleSwitch(e) {
    $('.product--description-switch a').removeClass('active');
    $(e.currentTarget).addClass('active');

    $('.product--description-switch-content > section').hide();
    if ($(e.currentTarget).hasClass('first')) {
      $('.first-content').fadeIn();
    } else if ($(e.currentTarget).hasClass('second')) {
      $('.second-content').fadeIn();
    } else if ($(e.currentTarget).hasClass('third')) {
      $('.third-content').fadeIn();
    }
  }

  openMenu() {
    $('.wrapMenu').show();
    $('.wrapMenu').addClass('flex-display');
    $('.nav').children(':not(.wrapMenu)').addClass('blur-content');
    $('.catalog--header').children(':not(.wrapMenu)').addClass('blur-content');
    $('.catalog--module').children(':not(.wrapMenu)').addClass('blur-content');
  }

  closeMenu() {
    $('.wrapMenu').hide();
    $('.wrapMenu').removeClass('flex-display');
    $('.nav').children(':not(.wrapMenu)').removeClass('blur-content');
    $('.catalog--header').children(':not(.wrapMenu)').removeClass('blur-content');
    $('.catalog--module').children(':not(.wrapMenu)').removeClass('blur-content');
  }

  openNav() {
    console.log('opeNav');
    document.getElementById('mySidenav').style.display = 'block';
  }

  closeNav() {
    document.getElementById('mySidenav').style.display = 'none';
  }

  toggleChildCategories = (parentCode) => {
    const categories = this.state.categories.data;
    this.state.childCategories = [];
    const items = categories.filter(category => category.locales[process.env.LOCALE].parent_code === parentCode).map((filteredCategories) => {
      const items2 = categories.filter(category => category.locales[process.env.LOCALE].parent_code === filteredCategories.external_referrer).map(filteredCategories2 => (<li><Link to={{ pathname: '/', state: { product: { syncProductList: [filteredCategories2.external_referrer, filteredCategories2.locales[process.env.LOCALE].description, filteredCategories2.external_referer] } } }} >{filteredCategories2.locales[process.env.LOCALE].name}</Link></li>));
      return (<ul><li><Link to={{ pathname: '/', state: { product: { syncProductList: [filteredCategories.external_referrer, filteredCategories.locales[process.env.LOCALE].description, filteredCategories.external_referer] } } }} >{filteredCategories.locales[process.env.LOCALE].name}</Link></li>{items2}</ul>);
    });
    this.setState({ childCategories: items });
  }


  handleBlocks(e) {
    $(e.currentTarget).find('span').toggleClass('fa-plus fa-minus');
    $(e.currentTarget).next('article').slideToggle();
  }

  render() {
    return (
      <UI
        product={this.state.productInfo}
        productName={this.state.name}
        color={this.state.initColor}
        size={this.state.initSize}
        images={this.state.images}
        pricing={this.state.pricing}
        attributes={this.state.attributes}
        item={this.state.item}
        // select attr_def
        colorSelected={this.colorSelected}
        sizeSelected={this.sizeSelected}
        // product info effects
        handleTabs={this.handleTabs}
        handleBlocks={this.handleBlocks}
        handleSwitch={this.handleSwitch}
        pathname={this.props.location.pathname}
        isAvailable={this.state.isAvailable}
        openMenu={this.openMenu}
        closeMenu={this.closeMenu}
        categories={this.state.categories}
        parentCategories={this.state.parentCategories}
        productType={this.state.productType}
        toggleChildCategories={this.toggleChildCategories}
        childCategories={this.state.childCategories}
        openNav={this.openNav}
        closeNav={this.closeNav}
        thirdLevel={this.state.thirdLevel}
      />
    );
  }
}

Product.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  catalogStore: PropTypes.shape({
    catalog: PropTypes.func,
    inventoryStatusService: PropTypes.func,
  }).isRequired,
};

export default inject('catalogStore', 'navigationStore')(observer(Product));
