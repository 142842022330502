import React, { useEffect, useState } from 'react';

const ProductFaq = (props) => {
  const [productFaq, setProductFaq] = useState([]);

  useEffect(() => {
    if (props.attributes && props.attributes.length) {
      const box = props.attributes.filter(attr => /attr-box-/.test(attr.identifier)).reduce((obj, attribute) => {
        const info = attribute.identifier.match(/attr-box-(.)-(.+)/);
        const descriptions = (obj[info[1]] && obj[info[1]].desc) || {};
        let newKey = info[2];
        let newValue = attribute.value;
        if (info[2].startsWith('desc')) {
          descriptions[newKey] = newValue;
          newKey = 'desc';
          newValue = descriptions;
        }
        obj[info[1]] = { ...obj[info[1]], [newKey]: newValue };
        return obj;
      }, {});
      setProductFaq(Object.keys(box).sort().map((key) => {
        const desc = box[key].desc;
        if (desc instanceof Object) {
          box[key].desc = Object.keys(desc).sort().map((k, i) => desc[k].split('\n')
            .map((trm, j) => <p key={`${i}_${j}`}>{trm}</p>));
        }
        return box[key];
      }));
    }
  }, [props.attributes]);

  const list = productFaq.map((obj, idx) => (
    <section key={`faq--${idx}`} className="product--faq-block" style={{ display: 'inline-block' }}>
      <a onClick={props.handleBlocks}>
        <h4>{obj.name}</h4>
        <span className="fa-plus" />
      </a>
      <article>
        {obj.desc}
      </article>
    </section>
  ));

  return (
    <section className="product--faq">
      <div className="module">
        {list}
      </div>
    </section>
  );
};

export default ProductFaq;
