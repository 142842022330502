import React from 'react';
import PropTypes from 'prop-types';

// Helpers
import { passwordChars } from '../../../helpers/char-restriction';

const UI = ({ internalData, handleChange, handleSubmit }) => (
  <section className="modal--form">
    <header>
      <h2>Recuperar Contraseña</h2>
    </header>
    <form name="passRequested" onSubmit={handleSubmit}>
      <div className="modal--advice-requested">
        <span>Por temas de seguridad es importante restablecer la contraseña en esta misma ventana del navegador.</span>
        <b>Por favor copie y pegue en su totalidad el código de activación recibido en su correo, ya que de lo contrario el proceso de recuperación de contraseña no será exitoso.</b>
      </div>
      <label htmlFor="code">
        <input
          id="code"
          name="code"
          type="text"
          placeholder="Código de validación"
          value={internalData.code}
          onChange={(e) => { handleChange(e, 'internalData.code'); }}
        />
      </label>
      <label htmlFor="password">
        <input
          id="password"
          name="password"
          type="password"
          placeholder="Contraseña nueva"
          minLength={8}
          maxLength={16}
          onKeyPress={passwordChars}
          value={internalData.password}
          onChange={(e) => { handleChange(e, 'internalData.password'); }}
        />
      </label>
      <label htmlFor="confirmPassword">
        <input
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          placeholder="Verificar contraseña"
          minLength={8}
          maxLength={16}
          onKeyPress={passwordChars}
          value={internalData.confirmPassword}
          onChange={(e) => { handleChange(e, 'internalData.confirmPassword'); }}
        />
      </label>
      <aside>
        {/* <h6>La contraseña debe contener lo siguiente:</h6> */}
        <ul>
          <li>La contraseña debe tener entre 8 y 16 caracteres.</li>
          <li>Debe tener al menos un número (0-9)</li>
          <li>Debe tener al menos una letra mayúscula (A-Z)</li>
          <li>Debe tener al menos uno de los siguientes símbolos: !, @, #, $, %, &, *.</li>
        </ul>
      </aside>
      <footer>
        <button>Siguiente</button>
      </footer>
    </form>
  </section>
);

UI.propTypes = {
  internalData: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default UI;
