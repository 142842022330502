import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { observer, inject } from 'mobx-react';

const Terms = (props) => {
  const config = props.orderStore.getCartConfig;
  let entrega = '3-5 días hábiles';
  if (config.success) {
    const data = config.data.data;
    if (data.diasEntrega && data.diasEntrega.activo) {
      const valor = data.diasEntrega.valor;
      let plural = '';
      if (valor === 1) {
        entrega = '1 día'
      } else {
        entrega = `${valor} días`;
        plural = 'es';
      }
      if (!data.diasEntrega.incluyeFinDeSemana) {
        entrega += ' hábil' + plural;
      }
    }
  }

  const restricciones = useRef(null);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const val = hash.substring(1);
      const yOffset = -85;
      let element;
      switch (val) {
        case 'restricciones':
          element = restricciones.current;
          break;
        default:
          element = document.getElementById(val)
          break;
      }
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({
          top: y,
          behavior: 'smooth'
        });
      }
    }
  }, [config]);

  return (
    <main>
      <Helmet title={`Términos y Condiciones | Catálogo Claro ${process.env.COUNTRY}`} />
      <section className="static--terms">
        <h1>TÉRMINOS Y CONDICIONES DE USO DEL SITIO TIENDA CLARO</h1>
        <p>Los presentes términos y condiciones generales (en adelante los “Términos y Condiciones”) serán los aplicables a las compras de los productos y servicios ofrecidos por CLARO PANAMÁ, S.A. (EN ADELANTE “CLARO”) a través del sitio
          https://tiendaenlinea.claro.com.pa/terms.</p>
        <p>Cualquier persona que desee acceder y/o usar el sitio o los servicios, podrá hacerlo aceptando y sujetándose a los Términos y Condiciones Generales, los cuales tienen un carácter obligatorio y vinculante. Al usar el sitio o los Servicios, el usuario acepta los términos y condiciones, cumpliendo con los mismos, por lo que le pedimos dedique unos minutos para revisarlos cuidadosamente. Si no está de acuerdo o no acepta los mismos, deberá abstener se utilizar el sitio y/o los servicios. Al usar el sitio tiendaenlinea.claro.com.pa se entenderá como el total consentimiento del usuario a estos términos y condiciones.</p>
        <p>El Usuario debe leer, entender y aceptar todas las condiciones establecidas en los Términos y Condiciones Generales y en las Políticas de Privacidad, así como en los demás documentos incorporados a los mismos por referencia, previos a su registro como Usuario de tiendaenlinea.claro.com.pa.</p>
        <h3>Capacidad</h3>
        <p>Los productos y servicios que ofrece el sitio tiendaenlinea.claro.com.pa, está reservado y dirigido únicamente para personas que tengan capacidad legal para contratar. Queda bajo responsabilidad de los padres o tutores, supervisar la conducta de los menores de edad que ingresen al sitio. En caso de inscribir a una persona jurídica como usuario, se deberá contar con la capacidad jurídica para contratar y obligarla en los términos de estos Términos y Condiciones.</p>
        <h3>Registro de Usuario y Contraseña</h3>
        <p>CLARO requiere que el usuario se registre para acceder al sitio web y haga uso de tiendaenlinea.claro.com.pa por lo que es obligatorio completar el formulario de registro en todos sus campos con datos válidos para poder utilizar los servicios que brinda tiendaenlinea.claro.com.pa, así como el o los domicilios que registre para que se envíen los productos o servicios que contrate. El Usuario deberá completarlo con su información personal de manera exacta, precisa y bajo protesta de decir verdad ('Datos Personales') y asume el compromiso de actualizar los Datos Personales conforme resulte necesario.</p>
        <p>Proporcionar cualquier información falsa o inexacta, constituye el incumpliendo de estos TÉRMINOS Y CONDICIONES. CLARO podrá utilizar diversos medios para identificar a sus Usuarios al momento de entrega de los equipos. Los Usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de los Datos Personales ingresados.</p>
        <p>El Usuario proporcionará un correo electrónico para identificar su cuenta, y generará una “Contraseña” para acceder a dicha cuenta, tales elementos fungirán como mecanismo de seguridad para la autenticación del Usuario. La Contraseña deberá integrarse con las especificaciones y/o algoritmos que el sistema de la página tiendaenlinea.claro.com.pa le indique, la Contraseña será necesaria para que el Usuario pueda acceder a su cuenta. El Usuario se obliga a mantener la confidencialidad de su Contraseña.</p>
        <p>La “Contraseña” podrá ser modificada posteriormente por el Usuario de conformidad con lo previsto en los presentes términos y condiciones. El Usuario reconoce el carácter personal e intransferible de la “Contraseña” la cual quedará bajo su custodia, control y cuidado, por lo que será de la exclusiva responsabilidad del Usuario cualquier daño o perjuicio que pudiese sufrir como consecuencia del uso indebido de la misma.</p>
        <p>La Cuenta es personal, única e intransferible, y está prohibido que un mismo Usuario inscriba o posea más de una Cuenta. En caso que tiendaenlinea.claro.com.pa detecte distintas Cuentas que contengan datos coincidentes o relacionados, podrá dar de baja la Cuenta subsiguiente respectiva, previa notificación que haga al Usuario, al domicilio que éste haya registrado en el Portal o a través de correo electrónico a la dirección electrónica que el Usuario haya proporcionado a tiendaenlinea.claro.com.pa.</p>
        <p>El Usuario será responsable por todas las operaciones efectuadas en su Cuenta, pues el acceso a la misma está restringido y el uso de su “Contraseña” es exclusivo del Usuario. El Usuario se compromete a notificar a tiendaenlinea.claro.com.pa en forma inmediata y por medio idóneo siendo éste, el correo electrónico enviado a la cuenta de correo registrada o marcando al número 800-9100 cualquier uso no autorizado de su Cuenta, así como el ingreso por terceros no autorizados a la misma. Se aclara que está prohibida la venta, cesión o transferencia de la Cuenta (incluyendo la reputación y calificaciones) bajo ningún título. Claro podrá solicitar cualquier información adicional para confirmar la identidad del Usuario.</p>
        <p>Tiendaenlinea.claro.com.pa podrá cancelar o restringir la cuenta de los Usuarios, si detecta algún uso indebido del sitio de los servicios que se ofrecen en el mismo, previa notificación que haga al Usuario, al domicilio que éste haya registrado en el Portal o a través de correo electrónico a la dirección electrónica que el Usuario haya proporcionado a tiendaenlinea.claro.com.pa.</p>
        <p>El Usuario autoriza expresamente a tiendaenlinea.claro.com.pa para que pueda confirmar los Datos Personales suministrados acudiendo a entidades públicas, compañías especializadas o centrales de riesgo, de igual forma tiendaenlinea.claro.com.pa, podrá consultar el historial crediticio del Usuario, con las empresas de información crediticia que legalmente operan en Panamá, para lo cual el Usuario autoriza de forma expresa a tiendaenlinea.claro.com.pa para compartir esos datos, incluyendo datos financieros y/o patrimoniales, con las mencionadas entidades. La información que tiendaenlinea.claro.com.pa obtenga de estas entidades será tratada en forma confidencial.</p>
        <h3>Privacidad de la Información</h3>
        <p>Para utilizar el Contenido y los Servicios ofrecidos por tiendaenlinea.claro.com.pa, los Usuarios deberán facilitar determinados datos de carácter personal. Su información personal se procesa y almacena en servidores o medios magnéticos que mantienen altos estándares de seguridad y protección tanto física como tecnológica. Para mayor información sobre la privacidad de los Datos Personales y casos en los que será revelada la información personal, el Usuario podrá consultar el Aviso de privacidad así como las Políticas de Privacidad, detalladas en este documento.</p>
        <h3>Publicidad de Ofertas Comerciales</h3>
        <p>En el sitio tiendaenlinea.claro.com.pa se publicarán los, productos y servicios ofrecidos, el precio que el Usuario deberá pagar por ellos y los plazos para el pago. Asimismo, se indicará si se encuentra disponible alguna promoción y/o descuento, la vigencia de la misma y el número de productos disponibles para la promoción y/o descuento, así como los medios de pago habilitados para liquidar dichos productos y servicios.</p>
        <p>En caso de que el Usuario opte por la compra del producto, el número de pedido que se asigna al realizar la transacción en el sitio de Internet tiendaenlinea.claro.com.pa servirá para dar seguimiento a la operación correspondiente. Si tiendaenlinea.claro.com.pa tuviera algún problema con el pedido del Usuario, por ejemplo que no se localice para la entrega de su pedido, se le informará vía correo electrónico o vía telefónica, al correo electrónico y teléfonos que haya proporcionado el Usuario al momento de  su Registro, procurando en la medida de lo posible resolver el inconveniente.</p>
        <p>En casos de preventas, el envío se realizará al momento de contar con el producto correspondiente, debiendo informarse a El Usuario el plazo máximo de entrega del mismo.</p>
        <h3 id="restricciones" ref={restricciones}>Restricciones de Compra</h3>
        <p>El usuario sólo puede comprar un celular a la vez y adquirir hasta 2 equipos celulares en un plazo de 30 días. Para productos de Claro Hogar y Accesorios no hay un límite de compra.</p>
        <h3>Imágenes</h3>
        <p>Las imágenes de los productos publicados en el sitio tiendaenlinea.claro.com.pa son proporcionadas por los proveedores o fabricantes de los mismos. La resolución del monitor en el cual el Usuario vea los artículos que se publican en el sitio web tiendaenlinea.claro.com.pa hace imposible que tiendaenlinea.claro.com.pa pueda garantizar que el color que el Usuario aprecia en su monitor, coincida exactamente con el color del producto físico.</p>
        <h3>Aprobación del pedido</h3>
        <p>Claro podrá confirmar telefónicamente con el Usuario, los pedidos que éste último haya generado en el sitio web tiendaenlinea.claro.com.pa. En caso de que el Usuario, no reconozca los pedidos, tiendaenlinea.claro.com.pa, procederá a la cancelación de los mismos. Una vez confirmado el pedido por el Usuario, tiendaenlinea.claro.com.pa, enviará un correo electrónico a la cuenta del Usuario, en el que se detallarán los productos adquiridos, el precio total a pagar, y la forma de pago seleccionada por el Usuario, esto con la finalidad de que el Usuario realice el pago de los productos respectivos.</p>
        <h3 id="metodos">Forma de pago</h3>
        <p>tiendaenlinea.claro.com.pa habilitará diversos medios de pago a través de los cuales el Usuario podrá pagar el precio de los productos y/o servicios que adquiera a través del sitio web tiendaenlinea.claro.com.pa obligándose el Usuario a pagar el precio de los productos y/o servicios de que se trate, de acuerdo a la forma que elija al momento de concretar la compra del mismo. Si la forma  elegida por el   para adquirir un producto es por medio de tarjeta de crédito/debito, tiendaenlinea.claro.com.pa no guardará ningún dato de la misma, como nombre, apellido, fecha de vencimiento, CVV, número de tarjeta. El cliente solo podrá hacer uso de esta forma de pago si su tarjeta es VISA o MASTERCARD. En el supuesto de que el Usuario elija como forma de pago, una tarjeta de crédito, el acreedor del Usuario, será la institución bancaria que le otorgue el crédito respectivo. Se tiene un límite de transacción de 2 equipos prepago por cuenta o un máximo de $3000 mensual.</p>
        <h3>Envío</h3>
        <p>Tiendaenlinea.claro.com.pa enviará los productos que el Usuario haya adquirido, a través de una empresa de mensajería (courier) autorizada por Claro. El costo del envío se indicará en el sitio web tiendaenlinea.claro.com.pa junto con el precio del producto correspondiente.</p>
        <p>Con la finalidad de brindar seguridad al Usuario en la entrega de los productos que adquiera a través del sitio tiendaenlinea.claro.com.pa, éste acepta que al recibir el producto deberá presentar una copia de su identificación oficial (CIPI, pasaporte vigente) a la empresa de mensajería que corresponda, para que ésta pueda hacer la entrega del producto correspondiente. El usuario comprador podrá especificar en la página tiendaenlinea.claro.com.pa si él mismo recibirá el paquete o si autoriza a un tercero a recibirlo por él y siendo el caso, El Usuario deberá indicar el nombre completo y número de CIP o Pasaporte de la persona que recibirá el producto, debiendo ésta presentar su identificación y firmar una constancia de recibimiento del paquete en el momento de entrega. En el supuesto de que no se pueda localizar al Usuario o al tercero designado para entregarle su producto en 3 ocasiones, la empresa de mensajería devolverá el producto a tiendaenlinea.claro.com.pa y se procederá como se indica en el siguiente párrafo.</p>
        <p>tiendaenlinea.claro.com.pa informará al Usuario a través de correo electrónico o llamada telefónica que, luego de tres intentos de entrega, la misma no fue posible, por lo que notificará vía correo electrónico la fecha a partir de la cual el usuario pueda recoger su pedido en la bodega central (Agencia de Los Pueblos Mall) en horarios de atención al público. No se realizarán entregas en días domingo.</p>
        <p>Nuestras órdenes de compra son procesadas en menos de 72 horas. Los pedidos recibidos después de las 18:00 hrs. serán procesados al siguiente día hábil. Si existiese retraso de entrega en el paquete, se le estará notificando al cliente por medio del tracking en el portal tienda.claro.com.pa, correo electrónico, o llamada a los números registrados. Generalmente el cliente recibirá su paquete en {entrega}, dependiendo de la ubicación a enviar, dentro del territorio nacional.</p>
        <h3>Autorización para recibir mercancía</h3>
        <p>En caso de que el Usuario autorice en tiendaenlinea.claro.com.pa a un tercero para la recepción de los productos que el Usuario adquiera a través de tiendaenlinea.claro.com.pa, se entenderá que el tercero acepta la recepción del producto encomendado por el Usuario, en el entendido que al entregar el producto como El Usuario ha autorizado, el Usuario desvincula a CLARO de responsabilidad alguna al respecto. Al momento de la recepción, la persona receptora debe entregar la copia del documento de identificación personal de la persona que realizó la compra a través de tiendaenlinea.claro.com.pa, de lo contrario no será entregado el producto.  </p>
        <h3>Devolución y Garantía</h3>
        <p>La garantía y servicio en aparatos celulares adquiridos en el Sitio web tiendaenlinea.claro.com.pa se aplicará de forma directa con Claro conforme a sus políticas, procedimientos y restricciones. Para esto el Usuario deberá acudir al Centro de Servicio Claro más cercano para la evaluación de su equipo.</p>
        <p>A continuación las políticas aplicables para la compra de teléfonos en modalidad prepago:</p>
        <h3>Garantías:</h3>
        <p>La garantía consiste en la reparación o cambio de equipo cuando presente desperfectos de fábrica. El tiempo de garantía que Claro ofrece al cliente es hasta 12 meses el cual inicia a partir de la fecha de facturación y aplica únicamente para desperfectos de fábrica.</p>
        <p>Existe una garantía de cambio directo dentro de los primeros 7 días de haber recibido su equipo. Para más información leer el anexo de garantía al final del documento (ANEXO DE GARANTÍA)</p>
        <h3 id="reembolsos">Devoluciones:</h3>
        <ul className="static--conditions">
          <li>•	La devolución por inconformidad del equipo debe de realizarse dentro de un plazo no mayor a cinco días hábiles contados a partir que se realiza la compra, y se debe de presentar el equipo en buen estado con caja y accesorios completos.</li>
          <li>•	La devolución por inconformidad solo se podrá realizar por un equipo de precio igual o mayor, en este último caso el cliente deberá de pagar la diferencia.</li>
          <li>•	Solo se realizará la devolución monetaria si no se ha activado SIM y no se ha ingresado la tarjeta raspable de saldo.</li>
          <li>•	El tiempo de la devolución monetaria del consumo realizado (tarjeta débito/crédito) dependerá de la entidad bancaria correspondiente.</li>
          <li>•	Solo se podrán realizar devoluciones en Agencia Claro en horarios de atención al público.</li>
        </ul>
        <h4><strong>Importante:</strong></h4>
        <p>Para todo trámite el Usuario debe presentar su identificación personal (DPI, pasaporte vigente), factura de adquisición del producto e impresión de comprobante de entrega de equipo electrónico, el cual se envía por correo electrónico al momento de envío de la orden.</p>
        <h3>Impuestos</h3>
        <p>Tal como lo establece la legislación fiscal vigente, CLARO emitirá la factura que ampare la compra de los productos y/o servicios, comprobante que le será entregado a El Usuario de manera física al recibir su artículo y/o a través de su correo electrónico registrado en tiendaenlinea.claro.com.pa.</p>
        <h3>Sanciones y suspensión de operaciones</h3>
        <p>No está permitida acción alguna o uso de dispositivo, software, u otro medio tendiente a interferir tanto en las actividades y operación de tiendaenlinea.claro.com.pa como en las ofertas, descripciones, cuentas o bases de datos de tiendaclaro.com.pa. Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derecho de propiedad industrial o de derechos de autor y/o a las prohibiciones estipuladas en este contrato, podrá encuadrarse en tipo penal.</p>
        <p>Sin perjuicio de otras medidas, tiendaenlinea.claro.com.pa podrá advertir, suspender en forma temporal o inhabilitar definitivamente la Cuenta de un Usuario si: (a) se quebrantara alguna ley, o cualquiera de las estipulaciones de estos Términos y Condiciones Generales y demás políticas de tiendaenlinea.claro.com.pa (b) incumpliera sus obligaciones como Usuario; (c) se incurriera a criterio de tiendaenlinea.claro.com.pa en conductas o actos dolosos o fraudulentos; (d) no pudiera verificarse la identidad del Usuario o cualquier información proporcionada por él fuere errónea; (e) tienda.claro.com.pa entendiera que las publicaciones u otras acciones pueden ser causa de responsabilidad para el Usuario que las publicó, para tiendaenlinea.claro.com.pa o para los Usuarios.</p>
        <p>De darse el caso, tienda.claro.com.pa notificará al Usuario, al domicilio que éste haya registrado en el Portal o a través de correo electrónico a la dirección electrónica que el Usuario haya proporcionado a tienda.claro.com.pa, previo a iniciar las acciones que estime pertinentes.</p>
        <h3>Alcance de los servicios tiendaenlinea.claro.com.pa</h3>
        <p>Este acuerdo no crea ningún contrato de sociedad, de mandato, de franquicia, o relación laboral entre Claro y el Usuario.</p>
        <h3>Fallas en el sistema</h3>
        <p>tiendaenlinea.claro.com.pa no se responsabiliza por cualquier daño, perjuicio o pérdida al Usuario causados por fallas en el sistema, en el servidor o en Internet. tiendaenlinea.claro.com.pa tampoco será responsable por cualquier virus que pudiera infectar el equipo del Usuario como consecuencia del acceso, uso o examen de su sitio web o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audio contenidos en el mismo. Los Usuarios NO podrán imputarle responsabilidad alguna ni exigir pago por lucro cesante, en virtud de perjuicios resultantes de dificultades técnicas o fallas en los sistemas o en Internet. tiendaenlinea.claro.com.pa no garantiza el acceso y uso continuado o ininterrumpido de su sitio. El sistema puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia ajena a tienda.claro.com.pa; en tales casos se procurará restablecerlo con la mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad. Claro no será responsable por ningún error u omisión de contenidos en su sitio web.</p>
        <h3>LIMITACIÓN DE RESPONSABILIDAD</h3>
        <p>POR MEDIO DE ESTA ADVERTENCIA, “CLARO”), SUS SUBSIDIARIAS Y/O AFILIADAS, INFORMAN A LOS USUARIOS DE LAS PÁGINAS DE CLARO, (EN ADELANTE LOS "USUARIOS"), QUE A TRAVÉS DE ÉSTAS PONE A SU DISPOSICIÓN DISPOSITIVOS TÉCNICOS DE ENLACE (TALES COMO, ENTRE OTROS, LINKS, BANNERS, BOTONES), DIRECTORIOS Y HERRAMIENTAS DE BÚSQUEDA QUE LES PERMITEN ACCEDER A PÁGINAS WEB PERTENECIENTES A TERCEROS (EN ADELANTE LOS "SITIOS ENLAZADOS"). LA INSTALACIÓN DE ESTOS ENLACES EN LAS PÁGINAS DE CLARO, SUS SUBSIDIARIAS Y/O AFILIADAS SE LIMITA A FACILITAR A LOS USUARIOS LA BÚSQUEDA DE, Y ACCESO, A LA INFORMACIÓN DISPONIBLE DE LOS SITIOS ENLAZADOS EN INTERNET, Y NO PRESUPONE QUE EXISTA NINGUNA CLASE DE VÍNCULO O ASOCIACIÓN ENTRE CLARO, SUS SUBSIDIARIAS Y/O AFILIADAS, Y LOS OPERADORES DE LOS SITIOS ENLAZADOS.</p>
        <p>CLARO, SUS SUBSIDIARIAS Y/O AFILIADAS NO CONTROLAN, APRUEBAN NI HACEN PROPIOS LOS SERVICIOS, INFORMACIÓN, DATOS, ARCHIVOS, PRODUCTOS Y CUALQUIER CLASE DE MATERIAL EXISTENTE EN LOS SITIOS ENLAZADOS. EL USUARIO, POR LO TANTO, DEBE EXTREMAR LA PRUDENCIA EN LA VALORACIÓN Y UTILIZACIÓN DE LOS SERVICIOS, INFORMACIÓN, DATOS, ARCHIVOS, PRODUCTOS Y CUALQUIER CLASE DE MATERIAL EXISTENTE EN LOS SITIOS ENLAZADOS.</p>
        <p>CLARO, SUS SUBSIDIARIAS Y/O AFILIADAS, NO GARANTIZAN NI ASUMEN RESPONSABILIDAD ALGUNA POR LOS DAÑOS Y PERJUICIOS DE TODA CLASE QUE PUEDAN CAUSARSE POR:</p>
        <ul className="static--conditions">
          <li>•	EL FUNCIONAMIENTO, DISPONIBILIDAD, ACCESIBILIDAD O CONTINUIDAD DE LOS SITIOS ENLAZADOS.</li>
          <li>•	EL MANTENIMIENTO DE LOS SERVICIOS, INFORMACIÓN, DATOS, ARCHIVOS, PRODUCTOS Y CUALQUIER CLASE DE MATERIAL EXISTENTE EN LOS SITIOS ENLAZADOS.</li>
          <li>•	LAS OBLIGACIONES Y OFERTAS EXISTENTES EN LOS SITIOS ENLAZADOS.</li>
        </ul>
        <h3>UTILIDAD</h3>
        <p>MEDIANTE LA UTILIZACIÓN DE ESTA PÁGINA, EL USUARIO RECONOCE Y ACEPTA QUE CLARO, SUS SUBSIDIARIAS Y/O AFILIADAS, NO GARANTIZAN QUE LOS SITIOS ENLAZADOS O LA INFORMACIÓN PROPORCIONADA POR TERCEROS, SEAN ÚTILES PARA LA REALIZACIÓN DE NINGUNA ACTIVIDAD EN PARTICULAR.</p>
        <p>MEDIANTE EL USO DE ESTA PÁGINA, EL USUARIO RECONOCE Y ACEPTA QUE CLARO, SUS SUBSIDIARIAS Y/O AFILIADAS QUEDAN EXCLUIDAS DE CUALQUIER RESPONSABILIDAD POR LOS DAÑOS Y PERJUICIOS QUE PUDIERAN HABER SIDO CAUSADOS POR LA VERACIDAD DE LA INFORMACIÓN O CALIDAD DE SERVICIOS CONTENIDOS U OFRECIDOS POR TERCEROS O QUE SE ENCUENTRE EN LOS SITIOS ENLAZADOS, O LOS QUE SURJAN CON RELACIÓN A ESTE SITIO. TANTO EL ACCESO A ESTA PÁGINA COMO EL USO QUE PUEDA HACERSE DE LA INFORMACIÓN CONTENIDA EN EL MISMO SON EXCLUSIVA RESPONSABILIDAD DEL USUARIO.</p>
        <h3>SEGURIDAD</h3>
        <p>MEDIANTE EL USO DE ESTA PÁGINA, EL USUARIO RECONOCE Y ACEPTA QUE CLARO, SUS SUBSIDIARIAS Y/O AFILIADAS, NO GARANTIZAN LA SEGURIDAD DE LOS SITIOS ENLAZADOS, Y EN PARTICULAR, QUE LOS USUARIOS PUEDAN EFECTIVAMENTE ACCEDER A LAS DISTINTAS PÁGINAS WEB QUE REPRESENTAN LOS SITIOS ENLAZADOS, NI QUE A TRAVÉS DE ÉSTOS PUEDAN TRANSMITIR, DIFUNDIR, ALMACENAR O PONER A DISPOSICIÓN DE TERCEROS SU CONTENIDO.</p>
        <p>MEDIANTE EL USO DE ESTA PÁGINA, EL USUARIO RECONOCE Y ACEPTA QUE CLARO, SUS SUBSIDIARIAS Y/O AFILIADAS, QUEDAN EXCLUIDAS DE CUALQUIER RESPONSABILIDAD POR LOS DAÑOS Y PERJUICIOS DE TODA NATURALEZA QUE PUDIERAN SER CAUSADOS POR LA FALTA DE SEGURIDAD DE LOS SITIOS ENLAZADOS.</p>
        <h3>CALIDAD</h3>
        <p>MEDIANTE EL USO DE ESTA PÁGINA, EL USUARIO RECONOCE Y ACEPTA QUE CLARO , SUS SUBSIDIARIAS Y/O AFILIADAS, NO CONTROLAN Y NO GARANTIZAN LA AUSENCIA DE VIRUS EN EL CONTENIDO DE LOS SITIOS ENLAZADOS, NI LA AUSENCIA DE OTROS ELEMENTOS QUE PUDIERAN PRODUCIR ALTERACIONES EN SUS SISTEMAS INFORMÁTICOS O EN LOS DOCUMENTOS ELECTRÓNICOS Y ARCHIVOS ALMACENADOS EN SUS SISTEMAS INFORMÁTICOS.</p>
        <h3>PROPIEDAD INTELECTUAL</h3>
        <p>MEDIANTE EL USO DE ESTA PÁGINA, EL USUARIO RECONOCE Y ACEPTA QUE CLARO , SUS SUBSIDIARIAS Y/O AFILIADAS, QUEDAN EXCLUIDAS DE CUALQUIER RESPONSABILIDAD QUE PUDIERA SER CAUSADA POR EL USO NO AUTORIZADO DE LAS MARCAS U OTROS DERECHOS DE PROPIEDAD INTELECTUAL DE TERCEROS O CONTENIDOS EN LOS SITIOS ENLAZADOS. DE IGUAL MANERA, LAS EVENTUALES REFERENCIAS QUE SE HAGAN EN ESTA PÁGINA A CUALQUIER PRODUCTO, SERVICIO, PROCESO, SITIO ENLAZADO, HIPERTEXTO O CUALQUIER OTRA INFORMACIÓN EN LA QUE SE UTILICEN MARCAS, SIGNOS DISTINTIVOS Y/O DOMINIOS, EL NOMBRE COMERCIAL O EL NOMBRE DEL FABRICANTE, SUMINISTRADOR, ETC., QUE SEAN TITULARIDAD DE TERCEROS, EN NINGÚN MOMENTO CONSTITUIRÁN, NI IMPLICARÁN RESPALDO O RECOMENDACIÓN ALGUNA POR PARTE DE CLARO A, SUS SUBSIDIARIAS Y/O AFILIADAS Y EN NINGÚN CASO CLARO , SUS SUBSIDIARIAS Y/O AFILIADAS SE ASIGNAN PROPIEDAD NI RESPONSABILIDAD SOBRE LOS MISMOS.</p>
        <h3>ELEMENTOS DE LAS PÁGINAS</h3>
        <p>ES PROBABLE QUE PARA PROPORCIONAR LA INFORMACIÓN CONTENIDA EN LA PÁGINA WEB TAL COMO DIBUJOS, DISEÑOS, SONIDO, VIDEOS, TEXTOS, FOTOGRAFÍAS, ETC., CLARO, SUS SUBSIDIARIAS Y/O AFILIADAS, HUBIEREN CONTRATADO A TERCEROS PARA REALIZAR LOS ESTUDIOS E INVESTIGACIONES CORRESPONDIENTES, ASÍ COMO LOS DIBUJOS, DISEÑOS, SONIDOS, VIDEOS, TEXTOS, O FOTOGRAFÍAS, QUE SE MUESTREN EN LA PÁGINA. CLARO, SUS SUBSIDIARIAS Y/O AFILIADAS, ADVIERTEN QUE AL NO SER DE SU TITULARIDAD, NI DESARROLLO TODA LA INFORMACIÓN CONTENIDA EN LA PÁGINA WEB, ALGUNOS DE LOS TEXTOS, GRÁFICOS, VÍNCULOS Y/O EL CONTENIDO DE ALGUNOS ARTÍCULOS INCLUIDOS EN LA MISMA, PODRÍAN NO SER VERACES O NO ESTAR ACTUALIZADOS, POR LO QUE CLARO, SUS SUBSIDIARIAS Y/O AFILIADAS, NO SE HACEN RESPONSABLES.</p>
        <h3>LEY APLICABLE Y JURISDICCIÓN</h3>
        <p>EL USUARIO AL HACER USO DE LAS PÁGINAS EN INTERNET DE CLARO , SUS SUBSIDIARIAS Y/O AFILIADAS, ACEPTA DE MANERA EXPRESA, QUE TODAS LAS CONTROVERSIAS QUE SURJAN POR EL USO DE ESTA PAGINA Y LOS SERVICIOS QUE AQUÍ SE OFRECEN Y PROMOCIONAN SE RESOLVERÁN AMIGABLEMENTE ENTRE LAS PARTES, Y EN CASO DE NO EXISTIR ARREGLO, LAS CONTROVERSIAS SE DILUCIDARAN ANTE LA JURISDICCION DE LOS TRIBUNALES DE LA CIUDAD DE PANAMÁ, EN LA VIA SUMARIA RENUNCIANDO LAS PARTES A CUALQUIER OTRA JURISDICCIÓN QUE LES COMPETA POR CUALQUIER OTRA RAZON.</p>
        <h3>TIENDA CLARO – POLÍTICAS DE PRIVACIDAD</h3>
        <p>CLARO PANAMÁ, SOCIEDAD ANÓNIMA (EN ADELANTE “CLARO”) se compromete a proteger la privacidad del usuario en las páginas de CLARO y a facilitar la tecnología de desarrollo necesaria para BRINDAR INFORMACIÓN A LOS USUARIOS DE LAS PÁGINAS DE CLARO, (EN ADELANTE LOS “USUARIOS”) para que éstos puedan disfrutar de una conexión segura y eficaz. La presente Declaración de Privacidad se aplica a los sitios Web de CLARO Y SUS SUBSIDIARIAS Y/O AFILIADAS y contempla la recopilación y el uso de datos y servicios en dichos sitios. Al obtener acceso a los sitios Web de CLARO Y SUS SUBSIDIARIAS Y/O AFILIADAS, el usuario manifiesta su consentimiento con el tratamiento de los datos a que se refiere esta declaración. Lea las presentes Políticas de Privacidad de CLARO Y SUS SUBSIDIARIAS Y/O AFILIADAS en su totalidad, pues mediante éstas se hace constar que su información personal será mantenida en confidencialidad y que Usted, el Usuario, está de acuerdo con el manejo de la misma.</p>
        <h3>1.- Recopilación de Información Personal.</h3>
        <p>En algunos sitios Web de CLARO Y SUS SUBSIDIARIAS Y/O AFILIADAS se recopila información personal que identifica al usuario que la proporciona, tal como dirección de correo electrónico, nombre del usuario, número celular, domicilio particular o de trabajo, NUMERO DE IDENTIFICACION TRIBUTARIA –NIT-, edad, sexo, intereses, preferencias y toda aquella información que CLARO Y SUS SUBSIDIARIAS Y/O AFILIADAS consideren necesaria para estar en posibilidad de ofrecer un mejor servicio. CLARO Y SUS SUBSIDIARIAS Y/O AFILIADAS pueden recopilar de forma automática información sobre el hardware y el software de su equipo. Entre los diversos datos que pueden ser recopilados, se encuentran la dirección IP, tipo de explorador, nombres de dominio, tiempo de acceso y direcciones de sitios Web visitados. CLARO Y SUS SUBSIDIARIAS Y/O AFILIADAS utilizarán dicha información para administrar el servicio que ofrece a sus usuarios, para mantener la calidad de dicho servicio y obtener estadísticas genéricas relativas al uso de los sitios Web de CLARO Y SUS SUBSIDIARIAS Y/O AFILIADAS. </p>
        <h3>2.- Uso de Información Personal.</h3>
        <p>La información personal recopilada por CLARO será administrada y utilizada para prestar a los usuarios los servicios que ofrece CLARO y que aquellos soliciten. Asimismo, los sitios de CLARO harán uso de los datos personales del usuario para informar a éste sobre los productos y servicios disponibles. CLARO no podrá enajenar ni arrendar a terceros SUS BASES DE DATOS de usuarios. Sin embargo, en determinadas ocasiones CLARO Y SUS SUBSIDIARIAS Y/O AFILIADAS podrán contactar a los usuarios en nombre de ciertos sitios Web relacionados con CLARO Y SUS SUBSIDIARIAS Y/O AFILIADAS para informarles sobre el lanzamiento de ofertas que pudieran ser de su interés en horas y días permitidos. En dicho supuesto, la información personal del usuario por ningún motivo será proporcionada a terceros. Asimismo, CLARO vigila y da seguimiento a los sitios y páginas Web visitados por sus usuarios para determinar cuáles son los sitios y servicios de CLARO más populares. De esta manera, puede enviarse a los usuarios de CLARO información y publicidad en forma personalizada, en función de sus intereses específicos. CLARO podrán revelar información personal de sus usuarios única y exclusivamente en los siguientes casos: (a) En cumplimiento de alguna orden emitida por autoridad judicial competente, (b) Cuando ello sea indispensable para proteger y defender los derechos de propiedad intelectual de CLARO, del sitio o de los usuarios de CLARO y (c) Actuando bajo circunstancias excepcionales para proteger la seguridad personal de los usuarios de CLARO</p>
        <h3>3.- Seguridad de Información Personal.</h3>
        <p>CLARO está comprometido a proteger la Información Personal que sus usuarios comparten, por lo que utiliza procedimientos físicos, electrónicos y de gestión estándar para evitar el acceso, uso o divulgación no autorizada. Cuando se transfiere Información Personal a través de Internet, CLARO utiliza encriptación SSL. Si bien existe un esfuerzo para proteger la Información Personal, no se puede asegurar la seguridad de la información que los usuarios de CLARO proporcionan a través de una conexión de Internet no segura. Por este motivo, el Usuario debe tomar todas las medidas necesarias para proteger la Información Personal cuando está en Internet, quedando esta protección bajo su entera responsabilidad. Asimismo, el Usuario debe estar consciente de los ataques de “Phishing”, a través de los cuales se intenta robar Información Personal de los consumidores. Los “phishers” utilizan correos electrónicos para llevar al consumidor a sitios web falsos, diseñados para engañar al destinatario y que éste proporcione Información Personal Sensible, tal como números de tarjeta de crédito, nombre de usuario de una cuenta, contraseñas y números de identificación personal. CLARO nunca le enviará un correo electrónico, pidiéndole que proporcione o confirme Información Personal Sensible. Si recibe una comunicación como las mencionadas, por favor reenvíela a clientesclaro@claro.com.pa y bórrela inmediatamente de su ordenador.</p>
        <h3>4.- Uso de los cookies.</h3>
        <p>CLARO pueden utilizar cookies para facilitar la personalización de la experiencia en línea del usuario. Un cookie es un archivo de texto situado por un servidor de páginas Web en el disco duro. Los cookies no se pueden utilizar para ejecutar programas o transmitir virus al equipo del usuario; únicamente se asignan al usuario y sólo puede leerlos un servidor Web que pertenezca al dominio que lo emitió. Una de las principales funciones de los cookies es el ahorro de tiempo. Los cookies se encargan de informar al servidor Web que el usuario ha vuelto a una página determinada. El usuario podrá aceptar o rechazar los cookies. La mayor parte de los exploradores Web aceptan automáticamente los cookies. No obstante lo anterior, el usuario puede modificar el explorador para rechazarlos. Si este es el caso, probablemente el usuario no pueda hacer uso de todas las características interactivas de los servicios que CLARO Y SUS SUBSIDIARIAS Y/O AFILIADAS pueden ofrecer.</p>
        <h3>5.- Privacidad de Menores.</h3>
        <p>El Usuario debe tener al menos 18 años de edad para acceder y hacer uso a los sitios y páginas Web de CLARO Y SUS SUBSIDIARIAS Y/O AFILIADAS, las cuales no recabarán ni comercializarán intencionalmente información personal en línea de personas menores de 13 años de edad. Si llega a ser del conocimiento de CLARO Y SUS SUBSIDIARIAS Y/O AFILIADAS que un menor de 13 años les ha proporcionado información personal, se tomarán las medidas necesarias para eliminar dicha información y dar por terminada la cuenta del menor de forma automática.</p>
        <h3>6.- Configuraciones de Privacidad.</h3>
        <p>Si el usuario ya no desea: (1) recibir ofertas de comercialización y/o promoción de los productos y servicios de CLARO, o de aquellos que puedan ser de su interés; o (2) que CLARO comparta su información personal con terceros con fines de comercialización como se establece en esta Política de Privacidad, puede llamar al 800-9100 e indicar el medio de contacto que quiere eliminar. Tome en cuenta que no puede eliminar la suscripción de cierta correspondencia relacionada con nuestros servicios, incluyendo mensajes relativos a las operaciones de su cuenta. Independientemente de las elecciones que realice para comunicaciones de promoción y actualizaciones referentes a contenido, podemos enviarle mensajes administrativos, anuncios del servicio, términos y condiciones de su cuenta o comunicaciones similares.</p>
        <h3>7.- Modificaciones a la Política de Privacidad.</h3>
        <p>CLARO Y SUS SUBSIDIARIAS Y/O AFILIADAS se reservan el derecho de modificar en cualquier momento las presentes políticas, Las modificaciones que en su caso se efectúen se harán del conocimiento de los usuarios en las páginas y sitios Web de CLARO Y SUS SUBSIDIARIAS Y/O AFILIADAS.</p>
        <aside>
          <h3>Tienda en Linea Claro – Politicas para Garantía de Fabrica</h3>
          <p>CLARO PANAMÁ, SOCIEDAD ANÓNIMA AGRADECE SU CONFIANZA Y PREFERENCIA AL ADQUIRIR SUS EQUIPOS POR MEDIO DE CLARO Y  ESPERA QUE ESTÉ SATISFECHO DE SU COMPRA.</p>
          <p>Los Términos y Condiciones aplicables a la Garantía del Fabricante para terminales móviles y tablets, son las siguientes: </p>
          <ul className="static--conditions">
            <li>
              <p>En el caso que el equipo adquirido presente fallas o desperfectos de fábrica, dentro de los primeros quince (15) días calendario posterior a la venta, se puede reclamar el reemplazo del mismo en Garantía, en las Agencias CLARO, siempre que se cumplan las condiciones siguientes:</p>
            </li>
            <li>
              <ul className="static--conditions">
                <li>a.	Que el equipo que presente fallas dentro de los primeros quince (15) días calendario a partir de la fecha de compra, no haya sido objeto de manipulación negligente o mal uso contrario a las instrucciones del Fabricante para el uso y mantenimiento del mismo;</li>
                <li>b.	Tener un defecto de fábrica. El equipo no debe presentar indicios de mal uso como golpes, arañazos, rayones en marcas, rozaduras, humedad, etc. Esté deberá ser devuelto en su caja original, en buen estado, y con los accesorios que le fueron entregados al momento de la contratación intactos;</li>
                <li>c.	Presentar copia de la factura de compra (o comprobante), expedida al usuario final, en la fecha de venta. El IMEI del terminal debe ser el mismo que figura en la factura o comprobante que se le entregó;</li>
                <li>d.	Que la etiqueta ESN / IMEI del equipo estén claras, no dañadas ni alteradas, así como la etiqueta en las cajas de embalaje (ESN, IMEI), ya que éstas respaldan que el número de serie de la caja, sea el mismo número de serie de los equipos.</li>
              </ul>
            </li>
          </ul>
          <p>En caso de haber transcurrido los quince (15) días calendario, o si el equipo no cumple con las condiciones para reclamo de cambio por fallas o desperfectos de fábrica dentro de los primeros 15 días antes mencionados, el equipo aplica a revisión y/o reparación dentro de la garantía limitada del producto otorgada por el Fabricante, cuyos términos son los siguientes:</p>
          <h3>Términos Garantía Limitada del Equipo:</h3>
          <p>El Fabricante brindará el soporte técnico y/o el servicio de garantía indicada en este documento a partir del día dieciseis (16) o incluso desde el día de la compra. Con esta garantía, el Fabricante garantiza el producto contra posibles defectos relacionados con el material y mano de obra, durante el período de trescientos sesenta y cinco (365) días contados a partir de la fecha de la compra.</p>
          <p>Si durante este período de garantía el producto tuviera defectos de fábrica, el Fabricante reparará o reemplazará a su entera discreción el equipo o sus piezas defectuosas, en las condiciones que se especifican a continuación y sin ningún cargo por mano de obra o piezas:</p>
          <ul className="static--conditions">
            <li>•	1. La garantía aplicará cuando las fallas que presente el terminal móvil o Tablet tengan su origen en defectos de fábrica, es decir por causas no imputables al cliente.</li>
            <li>•	2. El terminal móvil o Tablet a cambiar, deberá estar en perfecto estado físico, como mínimo el cliente deberá entregar, batería, teléfono y cover.</li>
            <li>•	3. Exista disponibilidad de stock de marca y modelo igual al del cliente de lo contrario se dará otro equivalente de la misma marca o de características similares.</li>
          </ul>
          <p>Los accesorios solo tienen tres (3) meses de garantía, esto incluye cargador, batería y manos libres (si los incluyera).</p>
          <h3>Exclusión de Garantía:</h3>
          <p>Quedan expresamente excluidos de garantía de fábrica, los terminales o Tablet y accesorios que presenten alguna falla o avería relacionada con alguna de las siguientes circunstancias:</p>
          <ul>
            <li>•	i. Cuando el terminal o accesorio presente defectos o daños derivados del uso anormal de éste, o uso contrario al indicado en el manual de operación (Guía del Usuario) que le acompaña. Se recomienda la lectura del manual de usuario.</li>
            <li>•	ii. Cuando el fabricante o taller de CLARO determine que, el terminal o accesorio presenta defectos o daños derivados de pruebas, operación, mantenimiento, instalación o ajustes inapropiados o derivados de cualquier alteración o modificación realizada por el usuario o por centros de servicio técnico ajenos al de CLARO.</li>
            <li>•	iii. Cuando el fabricante o taller de CLARO determine que, el terminal o accesorio presenta defectos o daños derivados de accidente o negligencia, golpes (hendidura), expuestos a descargas eléctricas, calor o evidencias de residuos líquidos o humedad.</li>
            <li>•	iv. Cuando el fabricante o taller de CLARO determine y compruebe que, las condiciones ambientales donde ha sido utilizado el terminal, no cumplen con las exigencias respecto de temperatura, humedad, polvo, vibraciones, entre otras de similares características avaladas por el Fabricante.</li>
            <li>•	v. Cuando el terminal presente alguna alteración en el número de serie o cuando el mismo sea ilegible.</li>
            <li>•	vi. Cuando los cables de la unidad de control hayan sido cortados o tengan rotos algunos de sus conectores o terminales.</li>
            <li>•	vii. Cuando el terminal haya sido utilizado con accesorios no originales.</li>
            <li>•	viii. Cuando las baterías sean cargadas por cualquier otro cargador que no sea el original.</li>
            <li>•	ix. Cuando cualquiera de los sellos de las baterías y/o terminales se encuentre roto o modificado o presente señales de activación por humedad.</li>
            <li>•	x. Cuando las baterías hayan sido usadas o instaladas en un terminal distinto al del terminal al que corresponde.</li>
            <li>•	xi. Cuando el fabricante o taller de CLARO determine que, el terminal presenta alteraciones en el software y que estas hayan sido realizadas por el usuario o por terceros no autorizados.</li>
          </ul>
          <p>Al realizar la compra de este equipo en CLARO, EL CLIENTE acepta conforme que los Términos y Condiciones se aplicarán desde el momento en que compra este equipo en CLARO y acepta las condiciones que el Fabricante ofrece como Garantía Limitada.</p>
        </aside>
      </section>
    </main>
  );
};

export default inject('orderStore')(observer(Terms));
