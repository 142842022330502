import React from 'react';
import PropTypes from 'prop-types';

// Components
import Notification from '../../Views/Notification/Notification.ui';

// Helpers
import { numberChars } from '../../../helpers/char-restriction';

const UI = ({ numberForm, handleChange, handleSubmit, error, switchModal }) => (
  <section className="modal--content-validate-number">
    <header>
      <h2>¡CÁMBIATE A POSTPAGO!</h2>
      <p>Ingresa el número prepago que deseas cambiar a postpago.</p>
    </header>
    <form name="validateNumber" onSubmit={handleSubmit}>
      <label htmlFor="s_phone_1">
        <input
          id="s_phone_1"
          name="s_phone_1"
          type="text"
          placeholder="Ingresa el número"
          onKeyPress={numberChars}
          value={numberForm.s_phone_1}
          onChange={(e) => { handleChange(e, 'internalData.s_phone_1'); }}
        />
      </label>
      <nav>
        <a onClick={switchModal} id="regresar">Regresar</a>
        <button type="submit"><span>Continuar</span></button>
      </nav>
    </form>
    {(error) ? <Notification title={error} /> : <div />}
  </section>
);


UI.propTypes = {
  numberForm: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  switchModal: PropTypes.func.isRequired,
};

UI.defaultProps = {
  error: '',
};

export default UI;
