import React from 'react';
import { logoIcon } from '../../helpers/svg';

const Loading = () => (
  <div id="loader" className="ui--loading active">
    <section>
      <i>{logoIcon}</i>
      <p>Procesando</p>
    </section>
  </div>
);

export default Loading;
