import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { escapeRegExp } from 'lodash';

// Helper
import FacetsUtils from '../../helpers/facets_utils';

// Jquery Effect
const slideList = (e) => {
  $(e.currentTarget).toggleClass('collapsed').next('div').slideToggle();
};

const mobileTrigger = () => {
  $('.catalog--filters-trigger').toggleClass('active');
  $('.catalog--filters-list').slideToggle();
};

// onFacetChange
const OrderFacets = ({ classname, facets, facetsUpdated, onFacetChange }) => {
  const nameLabel = (facets.identifier || facets.name).replace(new RegExp(/-/g), '').replace('filt', '').replace('chars', '').replace(/\b\w/g, l => l.toUpperCase());
  // console.log('facetsUpdated: ', facetsUpdated);
  let attributes = (facets.atrributes || []).map((obj) => {
    // Check if the new filters match with old filters and disable those that are not available
    let isFilterAviable = false;
    if (facetsUpdated && facetsUpdated.atrributes) {
      (facetsUpdated.atrributes || []).forEach((att) => {
        if (obj.facetId === att.facetId) {
          isFilterAviable = true;
        }
      });
    }

    const qty = obj.quantity;
    const filterId = obj.facetId;
    const facetVal = obj.facetValue;
    const label = obj.label
      .replace('3g4g', 'LTE 4G')
      .replace('prueba-agua', 'A prueba de agua')
      .replace('bluetooth', 'Bluetooth')
      .replace('camara-hd', 'Cámara HD')
      .replace('larga-duracion', 'Batería larga duración')
      .replace('mp3', 'MP3')
      .replace('nfc', 'NFC')
      .replace('radio', 'Radio');

    if (nameLabel === 'Color') {
      const colorClassName = `color-${FacetsUtils.getColorsFilter(obj.label)}`;
      const colorKey = `${FacetsUtils.getColorsFilter(obj.label)}`;
      if (isFilterAviable) {
        return (
          <label key={colorKey}>
            <input id={filterId} type="checkbox" name="filter-radio" onChange={(e) => { onFacetChange(e, qty, filterId, facetVal); }} />
            <i><span className={colorClassName} /></i>
          </label>
        );
      } else {
        return (
          <label key={colorKey} className="disabled">
            <input id={filterId} type="checkbox" name="filter-radio" disabled />
            <i><span className={colorClassName} /></i>
          </label>
        );
      }
    } else if (nameLabel === 'Precio') {
      const priceKey = parseFloat(label.replace(new RegExp(`${escapeRegExp(process.env.CURRENCY_SYMBOL)}(\\.)?`), '').replace(/(\s-\s.*)/, ''));
      if (isFilterAviable) {
        return (
          <label key={`${filterId}--${priceKey}`}>
            <input id={filterId} type="checkbox" name="filter-radio" onChange={(e) => { onFacetChange(e, qty, filterId, facetVal); }} />
            <h6>{ label }</h6>
          </label>
        );
      } else {
        return (
          <label key={`${filterId}--${priceKey}`} className="disabled">
            <input id={filterId} type="checkbox" name="filter-radio" disabled />
            <h6>{ label }</h6>
          </label>
        );
      }
    } else if (isFilterAviable) {
      return (
        <label key={label}>
          <input id={filterId} type="checkbox" name="filter-radio" onChange={(e) => { onFacetChange(e, qty, filterId, facetVal); }} />
          <h6>{label}</h6>
        </label>
      );
    } else {
      return (
        <label key={label} className="disabled">
          <input id={filterId} type="checkbox" name="filter-radio" disabled />
          <h6>{label}</h6>
        </label>
      );
    }
  });
  attributes = nameLabel === 'Precio' ? attributes.sort((a, b) => +a.key.split('--')[1] - +b.key.split('--')[1]) : attributes.sort((a, b) => a.key.localeCompare(b.key));

  const output = (
    <div key={`filtro-${(facets.identifier || facets.name)}`} className={classname}>
      {attributes}
    </div>
  );
  return output;
};

const promo = {
  'filt-promo': 1,
  'filt-promo-detalle': 2,
};

const UI = (props) => {
  let showFacets = [];
  if (props.listFacets.length) {
    const config = props.orderStore.getCartConfig.data.data.filtros_promociones;
    showFacets = props.listFacets.filter(filter => !promo[filter.identifier] || config[filter.identifier]).map((filter, index) => {
      let nameLabel;
      let classColor = '';
      let keys;
      let facetsUpdate = null;
      const filterId = filter.identifier || filter.name;
      if (promo[filterId]) {
        nameLabel = filter.name;
        keys = `${promo[filterId]}.${nameLabel}`;
      } else {
        nameLabel = filterId
          .replace(new RegExp(/-/g), '')
          .replace('filt', '')
          .replace('chars', '')
          .replace('operativo', ' Operativo')
          .replace(/\b\w/g, l => l.toUpperCase());
        classColor = nameLabel === 'Color' ? 'colors' : '';
        keys = `8.${nameLabel}`;
        if (nameLabel === 'Marca') keys = `3.${nameLabel}`;
        if (nameLabel === 'Precio') keys = `4.${nameLabel}`;
        if (nameLabel === 'Color') keys = `5.${nameLabel}`;
        if (nameLabel === 'Sistema Operativo') keys = `6.${nameLabel}`;
        if (nameLabel === 'Caracteristicas') keys = `7.${nameLabel}`;
      }

      if (props.listFacetsUpdated.length) {
        facetsUpdate = props.listFacetsUpdated.find(fullUpdate => (fullUpdate.identifier || fullUpdate.name) === filterId);
      }

      return (
        <section key={keys}>
          <h5 className="catalog--filters-title" onClick={e => slideList(e)} role="presentation">{ (nameLabel === 'Caracteristicas') ? 'Características' : nameLabel }</h5>
          <OrderFacets key={`order-facet-${index}`} classname={classColor} facets={filter} onFacetChange={props.onFacetChange} facetsUpdated={facetsUpdate} />
        </section>
      );
    });
  }
  showFacets = showFacets.sort((a, b) => a.key.localeCompare(b.key));
  return (
    <section className="catalog--filters">
      <a className="catalog--filters-trigger" onClick={() => mobileTrigger()}><span /></a>
      <form className="catalog--filters-list">
        <aside>
          <h5>Filtrar por:</h5>
          <a onClick={() => props.resetFilters(props.categorySlug, props.categoryName, props.categoryId)}>Limpiar Filtros</a>
        </aside>
        { showFacets }
      </form>
    </section>
  );
};

UI.propTypes = {
  listFacets: PropTypes.arrayOf(PropTypes.shape({})),
  listFacetsUpdated: PropTypes.arrayOf(PropTypes.shape({})),
  resetFilters: PropTypes.func,
  onFacetChange: PropTypes.func,
};

UI.defaultProps = {
  listFacets: [],
  listFacetsUpdated: [],
  resetFilters: () => {},
  onFacetChange: () => {},
};

OrderFacets.propTypes = {
  facets: PropTypes.shape({}),
  facetsUpdated: PropTypes.shape({}),
  classname: PropTypes.string,
  onFacetChange: PropTypes.func,
};

OrderFacets.defaultProps = {
  facets: {},
  facetsUpdated: {},
  classname: '',
  onFacetChange: () => {},
};

export default UI;
