import React from 'react';
import PropTypes from 'prop-types';

// Components
import Notification from '../../Views/Notification/Notification.ui';

// Helpers
// import { emailChars } from '../../../helpers/char-restriction';

const UI = ({ tokenForm, handleChange, handleSubmit, error, switchModal }) => (
  <section className="modal--content-validate-number">
    <header>
      <h2>¡REVISA TUS MENSAJES!</h2>
      <p>Para validar el cambio a postpago, debes ingresar el <span>TOKEN</span> que hemos enviado a tu número prepago.</p>
    </header>
    <form name="validateToken" onSubmit={handleSubmit}>
      <label htmlFor="token">
        <input
          id="token"
          name="token"
          type="text"
          placeholder="Ingresa tu token"
          // onKeyPress={emailChars}
          value={tokenForm.token}
          onChange={(e) => { handleChange(e, 'internalData.token'); }}
        />
        <b>Una vez validado debes esperar 24 horas para el cambio</b>
      </label>
      <nav>
        <a onClick={switchModal} id="regresar">Regresar</a>
        <button type="submit"><span>Continuar</span></button>
      </nav>
    </form>
    {(error) ? <Notification title={error} /> : <div />}
  </section>
);


UI.propTypes = {
  tokenForm: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  switchModal: PropTypes.func.isRequired,
};

UI.defaultProps = {
  error: '',
};

export default UI;
