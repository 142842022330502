import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import analytics from '../../helpers/analytics';
import productHelpers from '../../helpers/product';

// UI
import UI from './ProductMain.ui';

class ProductMain extends Component {
  static contextTypes = {
    router: PropTypes.object,
  }

  constructor(...props) {
    super(...props);
    this.state = {
      cartInfo: {},
      gallery: 'zoom',
      printProductInfo: null,
      modalCartInfo: null,
      printProductQty: 1,
      partNumber: null,
      productName: null,
      qty: null,
      tipoProducto: null,
    };

    this.isEmpty = true;
    this.do360 = this.do360.bind(this);
    this.doZoom = this.doZoom.bind(this);
  }

  componentDidMount() {
    document.getElementById('initial').scrollIntoView(true);
    this.getCart();
  }

  getAllCategories() {
    this.props.navigationStore.getCategories().then((res) => {
      res.data.data.sort((prev, current) => (prev.locales[process.env.LOCALE].position > current.locales[process.env.LOCALE].position ? 1 : -1));
      this.setState({ categories: res.data });
      this.getAllParentCategories(res.data);
      return res.data;
    });
  }

  getAllParentCategories(data) {
    const parents = [];
    console.log(data);
    const items = (data.data || []).map((v, i) => {
      console.log(i);
      console.log(v.locales[process.env.LOCALE]);
      if (v.locales[process.env.LOCALE].parent_code !== '') {
        if (!parents.includes(v.locales[process.env.LOCALE].parent_code)) {
          parents.push(v.locales[process.env.LOCALE].parent_code);
        }
        return v.locales[process.env.LOCALE].parent;
      }
      return false;
    });
    console.log(items);
    this.setState({ parentCategories: parents });
    this.state.parentCategories = parents;
  }

  async getCart() {
    const [res, config, addons] = await Promise.all([
      this.props.orderStore.getCart(),
      this.props.orderStore.getConfig(),
      this.props.orderStore.getAddons(),
    ]);

    let descripcionPrepago = '';
    if (config.success) {
      let entrega = '';
      let precio = '';
      const { diasEntrega, textoPrepago } = config.data.data;
      if (diasEntrega && diasEntrega.activo) {
        const valor = diasEntrega.valor;
        let plural = '';
        if (valor === 1) {
          entrega = '1 día';
        } else {
          entrega = `${valor} días`;
          plural = 'es';
        }
        if (!diasEntrega.incluyeFinDeSemana) {
          entrega += ` hábil${plural}`;
        }
      }
      if (addons.success) {
        const card = addons.data.data.addons.find(addon => addon.enabled && addon.auto_cart);
        if (card) {
          precio = (card.price * card.quantity).toFixed(2);
        }
      }
      if (textoPrepago) {
        descripcionPrepago = textoPrepago
          .replace(/{SIMBOLO_MONEDA}/g, process.env.CURRENCY_SYMBOL)
          .replace(/{PRECIO_TARJETA}/g, precio)
          .replace(/{DIAS_ENTREGA}/g, entrega);
      }
    }

    if (res.success) this.isEmpty = false;
    this.setState({ cartInfo: res && res.data && res.data.data, descripcionPrepago });
  }

  do360() {
    this.setState({ gallery: '360' });
  }

  doZoom() {
    this.setState({ gallery: 'zoom' });
  }

  closePopup = () => {
    this.props.modals.closePopup();
    this.setState({
      PPopup: this.props.modals.PPopup,
    });
  };

  deletePreviousCart = () => {
    this.closePopup();
    const { oldItem } = this.state;
    this.props.orderStore.deleteToCart(oldItem.part_number).then((res) => {
      if (res && res.success && res.data && res.data.data) {
        analytics.removeFromCart(oldItem.product, oldItem.part_number);
        this.addToCart(this.state.printProductInfo, this.state.printProductQty, false);
      }
    });
  };

  validateCart = async (partNumber, productName, qty, tipoProducto, itemInfo) => {
    /* this.setState({
      partNumber,
      productName,
      qty,
      tipoProducto,
    });
    if (tipoProducto === 'PLAN') {
      this.props.orderStore.validateCartTrigger = this.validateCartTriggerPlan;
      $('.modal').fadeIn();
      $('.modal--tipo-compra').addClass('active');
    } else { */
    this.validateCartTrigger(partNumber, productName, qty, tipoProducto, itemInfo);
    // }
  }

  validateCartTriggerPlan = async () => {
    const partNumber = this.state.partNumber;
    const productName = this.state.productName;
    const qty = this.state.qty;
    const tipoProducto = this.state.tipoProducto;
    this.validateCartTrigger(partNumber, productName, qty, tipoProducto);
  }

  validateCartTrigger = async (partNumber, productName, qty, tipoProducto, itemInfo) => {
    const orderInfo = await this.props.orderStore.getCart();
    if (orderInfo && !orderInfo.success) {
      this.addToCart(partNumber, qty);
    } else if (orderInfo.data && orderInfo.data.data) {
      let total = 0;
      let oldItem;
      let mail;

      if (orderInfo.data && orderInfo.data.data && orderInfo.data.data.items.length) {
        if (tipoProducto === 'ADDONS') {
          const itemAttr = productHelpers.convertAttributes(itemInfo.attributes);
          const tipoplan = itemAttr['attr-conf-tipoplan'];
          if ((itemAttr['attr-conf-validacion-correo'] || '').toLowerCase() === 'si') {
            const dataPerson = await this.props.accountStore.dataPerson();
            if (dataPerson && dataPerson.success) {
              const addonResponse = await this.props.orderStore.validateAddon({
                validateAddon: {
                  mail: dataPerson.data.data.logon_id,
                  sku: partNumber,
                },
              });
              if (addonResponse && addonResponse.success) {
                mail = dataPerson.data.data.logon_id;
              } else {
                this.state.warnMessage = 'No se puede asociar.';
                total = -1;
              }
            } else {
              this.state.warnMessage = 'Inicia sesión para agregar este producto.';
              total = -1;
            }
          }
          const itemsAddons = orderInfo.data.data.items.filter(ci => productHelpers.tipo(ci.product.items[0]) === 'ADDONS');
          for (let j = 0; j < itemsAddons.length; j += 1) {
            const itemAddon = itemsAddons[j];
            const addonAttr = productHelpers.convertAttributes(itemAddon.product.items[0].attributes);
            if ((addonAttr['attr-conf-tipoplan'] || '') === tipoplan) {
              total = 1;
              oldItem = itemAddon;
              break;
            }
          }
        } else {
          oldItem = orderInfo.data.data.items.find((it) => {
            const itemAttr = productHelpers.convertAttributes(it.product.items[0].attributes);
            return itemAttr['attr-conf-tipoproducto'] === tipoProducto && (itemAttr['attr-conf-tipoplan'] || '').toUpperCase() !== 'SIM';
          });
          if (oldItem) {
            total = 1;
          }
        }
      }

      this.setState({
        productName,
        printProductInfo: partNumber,
        printProductQty: qty,
        oldItem,
      });

      if (total) {
        this.props.modals.openPopup('cart');
        this.setState({
          PPopup: this.props.modals.PPopup,
        });
      } else {
        this.addToCart(partNumber, qty, false, mail);
      }
    }
  }

  addToCart = async (partNumber, qty, modaStatus, mail) => {
    const orderInfo = await this.props.orderStore.getCart();
    let init = 'user_warned';

    if (orderInfo && !orderInfo.success) {
      init = 'user_not_warned';
    }

    if (!partNumber) return console.log('Selecciona una capacidad válida'); // alert
    const response = await this.props.orderStore.addToCart(
      {
        part_number: partNumber,
        quantity: qty,
        mail,
      },
      init,
    );

    if (response && response.success) {
      analytics.addToCart(this.props, partNumber);
      if (!modaStatus) {
        this.closePopup();
        this.context.router.history.push('/cart');
      }
    }

    return response;
  }

  render() {
    console.log(this.props.allProps);
    console.log(this.props.allProps.productName);
    if (this.props.allProps && this.props.allProps.productName !== '') {
      return (
        <UI
          productInfo={this.props.allProps.product}
          cartInfo={this.state.cartInfo}
          color={this.props.allProps.color}
          size={this.props.allProps.size}
          images={this.props.allProps.images}
          productName={this.props.allProps.productName}
          pricing={this.props.allProps.pricing}
          attributes={this.props.allProps.attributes}
          item={this.props.item}
          colorSelected={this.props.allProps.colorSelected}
          sizeSelected={this.props.allProps.sizeSelected}
          addToCart={this.addToCart}
          validateCart={this.validateCart}
          do360={this.do360}
          doZoom={this.doZoom}
          gallery={this.state.gallery}
          modals={this.props.modals}
          printProductInfo={this.state.productName}
          closePopup={this.closePopup}
          deletePreviousCart={this.deletePreviousCart}
          isAvailable={this.props.allProps.isAvailable}
          warnMessage={this.state.warnMessage}
          descripcionPrepago={this.state.descripcionPrepago}
        />
      );
    } else {
      return null;
    }
  }
}

ProductMain.propTypes = {
  orderStore: PropTypes.shape({
    addToCart: PropTypes.func,
    getCart: PropTypes.func,
    getCartInfo: PropTypes.shape({}),
    warnedUserAction: PropTypes.func,
    deleteToCart: PropTypes.func,
  }).isRequired,

  allProps: PropTypes.shape({
    product: PropTypes.shape({}),
    color: PropTypes.string,
    size: PropTypes.string,
    productName: PropTypes.string,
    colorSelected: PropTypes.func,
    sizeSelected: PropTypes.func,
    images: PropTypes.arrayOf(PropTypes.string),
    pricing: PropTypes.shape({}),
    isAvailable: PropTypes.string,
  }),
  modals: PropTypes.shape({
    openPopup: PropTypes.func,
    closePopup: PropTypes.func,
  }),
};

ProductMain.defaultProps = {
  modals: {},
  allProps: {
    color: '',
    size: '',
    images: [],
    productName: '',
    colorSelected: () => {},
    sizeSelected: () => {},
    isAvailable: 'available',
  },
};

export default inject('orderStore', 'accountStore', 'modals')(observer(ProductMain));
