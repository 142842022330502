import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import $ from 'jquery';

// Helpers
import validate from '../../../helpers/validate_form';

// UI
import UI from './ResetPassword.ui';

class ResetPassword extends Component {
  constructor(...props) {
    super(...props);
    this.internalData = {
      email: '',
    };
    this.state = {
      presented: false,
    };

    // Binds
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    validate();
  }

  handleChange(e, data) {
    const keys = data.split('.');
    const resetPasswordForm = this;
    resetPasswordForm[keys[0]][keys[1]] = e.target.value;
    this.setState(resetPasswordForm);
  }

  handleSubmit(e) {
    e.preventDefault();
    const isIE = (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1);
    if (isIE) this.props.accountStore.resetPassword(this.internalData);
    else if (!isIE && $("form[name='resetPassword']").valid()) {
      this.setState({ presented: true });
      this.props.accountStore.resetPassword(this.internalData);
    }
  }

  render() {
    if (this.state.presented && this.props.accountStore.resetPass && this.props.accountStore.resetPass.success) {
      if (this.props.accountStore.resetPass.data.data === 'SUCCESS') {
        this.setState({ presented: false });
        this.props.modalEmail(this.internalData.email);
        this.props.modalType('onPassRecovered');
      }
    }

    return (
      <UI
        internalData={this.internalData}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        modalType={this.props.modalType}
        accountStore={this.props.accountStore}
      />
    );
  }
}

ResetPassword.propTypes = {
  accountStore: PropTypes.shape({
    resetPassword: PropTypes.func,
    resetPass: PropTypes.shape({
      success: PropTypes.bool,
    }),
  }).isRequired,
  modalType: PropTypes.func,
  modalEmail: PropTypes.func,
};

ResetPassword.defaultProps = {
  modalType: () => {},
  modalEmail: () => {},
};

export default inject('accountStore')(observer(ResetPassword));
